import { MenuItem, SelectChangeEvent } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next' // NOTE: next-i18next は SSR を前提とした実装が必要なためこのプロダクトとは相性が悪い. そのため react-i18next を使用している.

import {
  StyledLanguageIcon,
  StyledFormControl,
  StyledSelect,
} from '@/components/organisms/common/Language/style'
import { StackRow } from '@/components/uiParts/Stack/Row'

// ISO 639-1 の並び順を意識している(順番に関する要望を避ける目的)
const options = [
  { value: 'en', label: 'English' },
  { value: 'ja', label: '日本語' },
]

// NOTE: cimode は挙動チェックのためのメニューアイテム. 本番環境では表示しない.
// > HINT: For easy testing—setting lng to 'cimode' will cause the t function to always return the key.
// > cf. https://www.i18next.com/overview/api#changelanguage
// > cf. https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
if (process.env.NODE_ENV !== 'production') {
  options.push({ value: 'cimode', label: 'cimode' })
}

export const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation()

  // Select の uncontrolled な状態を避けるため 'en' を設定しているが, 後続の title 属性に影響が及んでいるので 'cimode' でもいいかもしれない.
  const [language, setLanguage] = useState<string>('en')

  // HACK: Hydration Error 回避のため. 原因はおそらく language の初期値を 'en' としていることでレンダリング差が生じているため.
  const [title, setTitle] = useState<string>()

  useEffect(() => {
    setLanguage(i18n.language)
    setTitle(t('language'))
  }, [i18n.language])

  // NOTE: 実装が複雑になりそうだったので next 標準の i18n は使用せず react-i18next のみとしている.
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    i18n.changeLanguage(event.target.value as string)
  }

  return (
    <StackRow title={title}>
      <StyledLanguageIcon />
      <StyledFormControl variant="standard">
        <StyledSelect value={language} onChange={handleChange}>
          {options.map((menu) => (
            <MenuItem key={menu.value} value={menu.value}>
              {menu.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </StackRow>
  )
}
