import { Box } from '@mui/material'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import { UserItemPopper } from '@/components/organisms/common/Header/UserItemPopper'
import { LanguageSwitcher } from '@/components/organisms/common/Language'
import { NotificationWidget } from '@/components/organisms/common/Notification'
import { AppBar } from '@/components/organisms/user/header/AppBar'
import { AppBarProps } from '@/components/organisms/user/header/AppBar/hooks'
import {
  StyledHeaderContainer,
  StyledHeaderRoot,
} from '@/components/organisms/user/header/style'
import { StackRow } from '@/components/uiParts/Stack/Row'
import { USER_ROOT_URL } from '@/constants/constants'
import PortrichIcon from '@/icons/portrich_logo_white.svg'
import { WithResourceType, usePermission } from '@/permissions'

export const Header = () => {
  const router = useRouter()
  const { filter } = usePermission()
  const { t } = useTranslation()

  const appBarItems: WithResourceType<AppBarProps>[] = [
    {
      label: t('dashboard'),
      path: '/user/dashboard',
      resourceType: 'DASHBOARD',
    },
    {
      label: t('quotes'),
      path: '/user/quotes/ocean',
      resourceType: 'QUOTE_REQUEST',
    },
    { label: t('booking'), path: '/user/booking', resourceType: 'BOOKING' },
    { label: t('shipments'), path: '/user/shipment', resourceType: 'SHIPMENT' },
    { label: t('invoice'), path: '/user/billing', resourceType: 'BILLING' },
    {
      label: t('network'),
      path: '#',
      items: [
        { label: t('client'), path: '/user/clients' },
        { label: t('guest_company'), path: '/user/guests' },
      ],
      resourceType: 'NETWORK',
    },
  ]

  return (
    <StyledHeaderRoot
      id="portrich-global-header"
      className="wovn-embedded-widget"
    >
      <StyledHeaderContainer>
        <StackRow spacing={4}>
          <Link
            href={USER_ROOT_URL}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <PortrichIcon style={{ height: 18 }} />
          </Link>
          <AppBar appBarItems={filter(appBarItems)} />
        </StackRow>
        {/* {AppConfig().appEnv !== 'production' && (
        <Box sx={{ fontSize: 14, fontWeight: 'bold' }}>
          Develop or Test environment
        </Box>
      )} */}
        <StackRow>
          <Box className="wovn-embedded-widget-anchor" />
          <LanguageSwitcher />
          <NotificationWidget
            onClick={(room) => router.push(`/user/shipment/${room.shipment}`)}
          />
          <UserItemPopper />
        </StackRow>
      </StyledHeaderContainer>
    </StyledHeaderRoot>
  )
}

export const UnauthenticatedHeader = () => (
  <StyledHeaderRoot
    id="portrich-global-header"
    className="wovn-embedded-widget"
  >
    <StyledHeaderContainer>
      <PortrichIcon style={{ height: 18 }} />
      {/* {AppConfig().appEnv !== 'production' && (
        <Box sx={{ fontSize: 14, fontWeight: 'bold' }}>
          Develop or Test environment
        </Box>
      )} */}
    </StyledHeaderContainer>
    <LanguageSwitcher />
  </StyledHeaderRoot>
)
