/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */
import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  AccountingDetailType,
  AccountingPartner,
  AccountingPartnerRequest,
  AccountingReport,
  AccountingReportByDocumentRequest,
  AccountingReportDetail,
  AccountingReportDetailRequest,
  AccountingReportRequest,
  AccountingReportsListParams,
  AirCraftType,
  AirCraftTypeRequest,
  AirFlightNumber,
  AirFlightNumberRequest,
  AirForwarder,
  AirForwarderRequest,
  AirTransportCargo,
  AirTransportCargoRequest,
  AirTransportQuote,
  AirTransportQuoteCreate,
  AirTransportQuoteCreateRequest,
  AirTransportQuoteRequest,
  AirTransportRequest,
  AirTransportRequestRequest,
  AirTransportSchedule,
  AirTransportScheduleRequest,
  ArrivalNotices,
  ArrivalNoticesRequest,
  AxisFeeStatus,
  AxisFeeStatusRequest,
  Billing,
  BillingDetail,
  BillingDetailRequest,
  BillingRequest,
  BillingUnitType,
  BookingNote,
  BookingNoteRequest,
  BookingRequestPostOnlyRequest,
  BookingRequestWithoutQuoteRequest,
  BusinessPartnersAirForwardersContactsListParams,
  BusinessPartnersAirForwardersListParams,
  BusinessPartnersAirForwardersPaginatedListParams,
  BusinessPartnersCustomsBrokersContactsListParams,
  BusinessPartnersCustomsBrokersListParams,
  BusinessPartnersCustomsBrokersPaginatedListParams,
  BusinessPartnersFreightForwardersContactsListParams,
  BusinessPartnersFreightForwardersListParams,
  BusinessPartnersFreightForwardersPaginatedListParams,
  BusinessPartnersShippingCompaniesAirListParams,
  BusinessPartnersShippingCompaniesAirPaginatedListParams,
  BusinessPartnersShippingCompaniesContactsListParams,
  BusinessPartnersShippingCompaniesListParams,
  BusinessPartnersShippingCompaniesMarineListParams,
  BusinessPartnersShippingCompaniesMarinePaginatedListParams,
  BusinessPartnersTruckCarriersContactsListParams,
  BusinessPartnersTruckCarriersListParams,
  BusinessPartnersTruckCarriersPaginatedListParams,
  BusinessPartnersWarehousesListParams,
  BusinessPartnersWarehousingCompaniesContactsListParams,
  BusinessPartnersWarehousingCompaniesListParams,
  BusinessPartnersWarehousingCompaniesPaginatedListParams,
  BusinessPartnersWarehousingCompaniesWarehousesListParams,
  ByArrivalNotice,
  ByArrivalNoticeRequest,
  ByPaymentInvoice,
  ByPaymentInvoiceRequest,
  Cargo,
  CargoRequest,
  Chat,
  ChatListParams,
  ChatRequest,
  ChatRoom,
  ChatRoomRequest,
  ChatRoomsListParams,
  ChatRoomsUnreadListParams,
  ClosingDateType,
  ClosingDateTypeRequest,
  Collaborator,
  CollaboratorRequest,
  CommodityDetail,
  CommodityDetailRequest,
  CompanyContacts,
  CompanyContactsRequest,
  Contact,
  ContactRequest,
  ContactsListParams,
  Container,
  ContainerRequest,
  ContainerTypes,
  ContainerTypesRequest,
  Countries,
  CountriesRequest,
  CurrencyUnits,
  CurrencyUnitsRequest,
  CustomClearanceDetail,
  CustomClearanceDetailRequest,
  CustomsBrokers,
  CustomsBrokersRequest,
  CustomsClearanceGoodsListParams,
  CustomsClearancePriceManagement,
  CustomsClearancePriceManagementRequest,
  CustomsClearanceRequest,
  CustomsClearanceRequestRequest,
  CustomsGoodsInformation,
  CustomsGoodsInformationRequest,
  DocumentsForTrade,
  DocumentsForTradeArrivalNoticesLimitedListParams,
  DocumentsForTradeArrivalNoticesListParams,
  DocumentsForTradeArrivalNoticesReportsListParams,
  DocumentsForTradeBookingNoteLimitedListParams,
  DocumentsForTradeBookingNoteListParams,
  DocumentsForTradeCustomsClearanceRequestLimitedListParams,
  DocumentsForTradeCustomsClearanceRequestListParams,
  DocumentsForTradeDocumentsListParams,
  DocumentsForTradeDocumentsPermissionsUpdateParams,
  DocumentsForTradePortrichBillOfLadingsLimitedListParams,
  DocumentsForTradePortrichBillOfLadingsListParams,
  DocumentsForTradeRequest,
  DocumentsForTradeShippingInstructionsLimitedListParams,
  DocumentsForTradeShippingInstructionsListParams,
  Forwarder,
  ForwarderInvitation,
  ForwarderInvitationRequest,
  ForwarderTasks,
  ForwarderTasksRequest,
  ForwardingOperation,
  ForwardingOperationRequest,
  FreightMethod,
  FromPriceManagement,
  FromPriceManagementRequest,
  GensetChassisAvailability,
  GensetChassisAvailabilityRequest,
  GuestGroup,
  GuestGroupRequest,
  GuestInvitation,
  GuestInvitationRequest,
  InitialTasks,
  InsuranceCovered,
  InsuranceCoveredRequest,
  LandTransportProfitMargin,
  LandTransportProfitMarginRequest,
  LetterOfAuthorizationStatus,
  LetterOfAuthorizationStatusRequest,
  MapData,
  MarineForwarders,
  MarineForwardersRequest,
  MarineTradePriceManagement,
  MarineTradePriceManagementRequest,
  MarineTransportFixedSchedules,
  MarineTransportFixedSchedulesRequest,
  MarineTransportQuoteCreate,
  MarineTransportQuoteCreateRequest,
  MasterdataPortsListParams,
  MasterdataPriceTypesListParams,
  Message,
  MessageRequest,
  MultiPartUploadPresignedUrl,
  MultiPartUploadPresignedUrlRequest,
  MultipartUploadRequest,
  NestedSelectedQuote,
  NestedSelectedQuoteRequest,
  PackingType,
  PackingTypeRequest,
  PaginatedAirForwarderList,
  PaginatedAirTransportQuoteList,
  PaginatedAirTransportRequestList,
  PaginatedAirTransportScheduleList,
  PaginatedCustomsBrokersList,
  PaginatedCustomsClearancePriceManagementList,
  PaginatedLandTransportProfitMarginList,
  PaginatedMarineForwardersList,
  PaginatedMarineTradePriceManagementList,
  PaginatedMarineTransportFixedSchedulesList,
  PaginatedPaymentInvoicesList,
  PaginatedPurchasePriceForAirTransportList,
  PaginatedPurchasePricesForCustomsFeesList,
  PaginatedPurchasePricesForLandFreightsList,
  PaginatedPurchasePricesForSeaFreightsList,
  PaginatedQuoteList,
  PaginatedQuoteRequestForMarineTransportList,
  PaginatedQuoteRequestList,
  PaginatedQuoteRequestsForTrucksList,
  PaginatedSellingPriceForAirTransportList,
  PaginatedSellingPricesForCustomsFeesList,
  PaginatedSellingPricesForLandFreightsList,
  PaginatedSellingPricesForSeaFreightsList,
  PaginatedShipmentsList,
  PaginatedShippingCompaniesList,
  PaginatedTruckCarriersList,
  PaginatedWarehousingCompaniesList,
  PartnerCompany,
  PartnerCompanyRequest,
  PasswordResetRequest,
  PasswordUpdateRequest,
  PatchedAccountingPartnerRequest,
  PatchedAccountingReportDetailRequest,
  PatchedAccountingReportRequest,
  PatchedAirCraftTypeRequest,
  PatchedAirFlightNumberRequest,
  PatchedAirForwarderRequest,
  PatchedAirTransportCargoRequest,
  PatchedAirTransportQuoteRequest,
  PatchedAirTransportRequestRequest,
  PatchedAirTransportScheduleRequest,
  PatchedArrivalNoticesRequest,
  PatchedAxisFeeStatusRequest,
  PatchedBillingDetailRequest,
  PatchedBillingRequest,
  PatchedBookingNoteRequest,
  PatchedCargoRequest,
  PatchedChatRequest,
  PatchedChatRoomRequest,
  PatchedClosingDateTypeRequest,
  PatchedCommodityDetailRequest,
  PatchedCompanyContactsRequest,
  PatchedContactRequest,
  PatchedContainerRequest,
  PatchedContainerTypesRequest,
  PatchedCountriesRequest,
  PatchedCurrencyUnitsRequest,
  PatchedCustomClearanceDetailRequest,
  PatchedCustomsBrokersRequest,
  PatchedCustomsClearancePriceManagementRequest,
  PatchedCustomsClearanceRequestRequest,
  PatchedCustomsGoodsInformationRequest,
  PatchedDocumentsForTradeRequest,
  PatchedForwarderTasksRequest,
  PatchedForwardingOperationRequest,
  PatchedFromPriceManagementRequest,
  PatchedGensetChassisAvailabilityRequest,
  PatchedGuestGroupRequest,
  PatchedInsuranceCoveredRequest,
  PatchedLandTransportProfitMarginRequest,
  PatchedLetterOfAuthorizationStatusRequest,
  PatchedMarineForwardersRequest,
  PatchedMarineTradePriceManagementRequest,
  PatchedMarineTransportFixedSchedulesRequest,
  PatchedPackingTypeRequest,
  PatchedPartnerCompanyRequest,
  PatchedPasswordUpdateRequest,
  PatchedPaymentInvoicePriceRequest,
  PatchedPaymentInvoicesRequest,
  PatchedPaymentWayRequest,
  PatchedPortrichBillOfLadingsRequest,
  PatchedPortsRequest,
  PatchedPurchasePriceForAirTransportRequest,
  PatchedPurchasePricesForCustomsFeesRequest,
  PatchedPurchasePricesForLandFreightsRequest,
  PatchedPurchasePricesForSeaFreightsRequest,
  PatchedQuoteRequest,
  PatchedQuoteRequestForMarineTransportRequest,
  PatchedQuoteRequestRequest,
  PatchedQuoteRequestsForTrucksRequest,
  PatchedScheduleCommunicationOptionRequest,
  PatchedSellingPriceForAirTransportRequest,
  PatchedSellingPricesForCustomsFeesRequest,
  PatchedSellingPricesForLandFreightsRequest,
  PatchedSellingPricesForSeaFreightsRequest,
  PatchedShipmentDetailRequest,
  PatchedShipmentExchangeRateRequest,
  PatchedShipmentLandTransportRequest,
  PatchedShipmentMemoRequest,
  PatchedShipmentTasksRequest,
  PatchedShipmentsRequest,
  PatchedShippingCompaniesRequest,
  PatchedShippingInstructionsRequest,
  PatchedSummaryByCompanySkeltonRequest,
  PatchedSummaryByShipmentRequest,
  PatchedTransportRequestRequest,
  PatchedTransportationOptionRequest,
  PatchedTruckCarriersRequest,
  PatchedTruckSizeRequest,
  PatchedUserCompaniesRequest,
  PatchedUserCompaniesWorkplaceRequest,
  PatchedUsersRequest,
  PatchedVesselsRequest,
  PatchedWarehouseRequest,
  PatchedWarehousingCompaniesRequest,
  PaymentInvoicePrice,
  PaymentInvoicePriceRequest,
  PaymentInvoices,
  PaymentInvoicesListParams,
  PaymentInvoicesReportsListParams,
  PaymentInvoicesRequest,
  PaymentInvoicesSummariesByShipmentListParams,
  PaymentInvoicesSummariesByShipmentSummariesByCompanyListParams,
  PaymentWay,
  PaymentWayRequest,
  PortrichBillOfLadings,
  PortrichBillOfLadingsRequest,
  PortrichTokenObtainPair,
  PortrichTokenObtainPairRequest,
  Ports,
  PortsRequest,
  PreRegistrationCreate,
  PreRegistrationCreateRequest,
  PresignedUrl,
  PresignedUrlRequest,
  PriceGroup,
  PriceType,
  PurchaseManagementsAirTransportListParams,
  PurchaseManagementsCustomsFeesListParams,
  PurchaseManagementsLandFreightsListParams,
  PurchaseManagementsSeaFreightsListParams,
  PurchasePriceForAirTransport,
  PurchasePriceForAirTransportRequest,
  PurchasePricesForCustomsFees,
  PurchasePricesForCustomsFeesRequest,
  PurchasePricesForLandFreights,
  PurchasePricesForLandFreightsRequest,
  PurchasePricesForSeaFreights,
  PurchasePricesForSeaFreightsRequest,
  Quote,
  QuoteCreate,
  QuoteCreateRequest,
  QuoteRequest,
  QuoteRequestForMarineTransport,
  QuoteRequestForMarineTransportRequest,
  QuoteRequestRequest,
  QuoteRequestsAirTransportListParams,
  QuoteRequestsForTrucks,
  QuoteRequestsForTrucksRequest,
  QuoteRequestsListParams,
  QuoteRequestsMarineTransportAvailableCustomPurchasesListParams,
  QuoteRequestsMarineTransportListParams,
  QuoteRequestsSeaFreightUserCompaniesListParams,
  QuoteRequestsTrucksListParams,
  QuotesAirTransportListParams,
  QuotesListParams,
  QuotesMarineTransportAvailableSchedulesListParams,
  QuotesSimplifiedListParams,
  QuotesSimplifiedRetrieveParams,
  ReadOnlyCargo,
  ReadOnlyContainerType,
  ReadOnlyCountry,
  ReadOnlyPort,
  ScheduleCommunicationOption,
  ScheduleCommunicationOptionRequest,
  ScheduleSearch,
  SchemaRetrieve200Four,
  SchemaRetrieve200One,
  SchemaRetrieve200Three,
  SchemaRetrieve200Two,
  SchemaRetrieveParams,
  SelectedQuote,
  SelectedQuoteCreateRequest,
  SellingManagementsAirTransportListParams,
  SellingManagementsCustomsFeesListParams,
  SellingManagementsLandFreightsListParams,
  SellingManagementsLandTransportAsPriceManagementListParams,
  SellingManagementsSeaFreightsListParams,
  SellingPriceForAirTransport,
  SellingPriceForAirTransportRequest,
  SellingPricesForCustomsFees,
  SellingPricesForCustomsFeesRequest,
  SellingPricesForCustomsFeesWithPurchaseRequest,
  SellingPricesForLandFreights,
  SellingPricesForLandFreightsRequest,
  SellingPricesForLandFreightsWithPurchase,
  SellingPricesForLandFreightsWithPurchaseRequest,
  SellingPricesForSeaFreights,
  SellingPricesForSeaFreightsRequest,
  ServiceTypeLabelValue,
  ShipmentBookmarks,
  ShipmentDetail,
  ShipmentDetailRequest,
  ShipmentExchangeRate,
  ShipmentExchangeRateRequest,
  ShipmentLandTransport,
  ShipmentLandTransportCreateRequest,
  ShipmentLandTransportRequest,
  ShipmentMemo,
  ShipmentMemoRequest,
  ShipmentSubTask,
  ShipmentSubTaskRequest,
  ShipmentTasks,
  ShipmentTasksRequest,
  ShipmentTransportSchedules,
  Shipments,
  ShipmentsListParams,
  ShipmentsMemoListParams,
  ShipmentsQuoteRelatedListParams,
  ShipmentsRequest,
  ShipmentsTasksByCategoriesListParams,
  ShipmentsTasksListParams,
  ShipmentsTasksSetCreateParams,
  ShipmentsTransportSchedulesListParams,
  ShippingCompanies,
  ShippingCompaniesRequest,
  ShippingInstructions,
  ShippingInstructionsRequest,
  ShippingSchedulesAirTransportListParams,
  ShippingSchedulesMarineTransportListParams,
  ShippingSchedulesSearchRetrieveParams,
  SimplifiedQuote,
  SummaryByCompanySkelton,
  SummaryByCompanySkeltonRequest,
  SummaryByShipment,
  SummaryByShipmentRequest,
  Supplier,
  TasksByCategories,
  TasksForwardersListParams,
  TasksTemplatesListParams,
  Team,
  TempNestedSelectedQuote,
  TempNestedSelectedQuoteRequest,
  TemplateTasks,
  TokenRefresh,
  TokenRefreshRequest,
  TradePriceManagementsCustomsClearanceListParams,
  TradePriceManagementsMarineListParams,
  TransactionRequestsSelectableQuotesLandFreightsListParams,
  TransportRequest,
  TransportRequestRequest,
  TransportationOption,
  TransportationOptionRequest,
  TruckCarriers,
  TruckCarriersRequest,
  TruckSize,
  TruckSizeRequest,
  UnreadChatRoom,
  UserCompanies,
  UserCompaniesRequest,
  UserCompaniesWorkplace,
  UserCompaniesWorkplaceRequest,
  UserInvitation,
  UserInvitationRequest,
  UserRegistration,
  UserRegistrationRequest,
  Users,
  UsersCompaniesContactsInfoListParams,
  UsersCompaniesListParams,
  UsersListParams,
  UsersPartnerCompaniesListParams,
  UsersRequest,
  Vessels,
  VesselsRequest,
  Warehouse,
  WarehouseRequest,
  WarehousingCompanies,
  WarehousingCompaniesRequest,
  Workplace
} from './models'
import { swrFetcher } from '../../src/api/config/axios/index';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const accountingDetailTypesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingDetailType[]>(
      {url: `/api/v1/accounting/detail-types/`, method: 'get'
    },
      options);
    }
  

export const getAccountingDetailTypesListKey = () => [`/api/v1/accounting/detail-types/`] as const;

    
export type AccountingDetailTypesListQueryResult = NonNullable<Awaited<ReturnType<typeof accountingDetailTypesList>>>
export type AccountingDetailTypesListQueryError = unknown

export const useAccountingDetailTypesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof accountingDetailTypesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getAccountingDetailTypesListKey() : null);
  const swrFn = () => accountingDetailTypesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const accountingPartnersList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingPartner[]>(
      {url: `/api/v1/accounting/partners/`, method: 'get'
    },
      options);
    }
  

export const getAccountingPartnersListKey = () => [`/api/v1/accounting/partners/`] as const;

    
export type AccountingPartnersListQueryResult = NonNullable<Awaited<ReturnType<typeof accountingPartnersList>>>
export type AccountingPartnersListQueryError = unknown

export const useAccountingPartnersList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof accountingPartnersList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getAccountingPartnersListKey() : null);
  const swrFn = () => accountingPartnersList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const accountingPartnersCreate = (
    accountingPartnerRequest: AccountingPartnerRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingPartner>(
      {url: `/api/v1/accounting/partners/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: accountingPartnerRequest
    },
      options);
    }
  


export const accountingPartnersUpdate = (
    id: number,
    accountingPartnerRequest: AccountingPartnerRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingPartner>(
      {url: `/api/v1/accounting/partners/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: accountingPartnerRequest
    },
      options);
    }
  


export const accountingPartnersPartialUpdate = (
    id: number,
    patchedAccountingPartnerRequest: PatchedAccountingPartnerRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingPartner>(
      {url: `/api/v1/accounting/partners/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedAccountingPartnerRequest
    },
      options);
    }
  


export const accountingReportsList = (
    params?: AccountingReportsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingReport[]>(
      {url: `/api/v1/accounting/reports/`, method: 'get',
        params
    },
      options);
    }
  

export const getAccountingReportsListKey = (params?: AccountingReportsListParams,) => [`/api/v1/accounting/reports/`, ...(params ? [params]: [])] as const;

    
export type AccountingReportsListQueryResult = NonNullable<Awaited<ReturnType<typeof accountingReportsList>>>
export type AccountingReportsListQueryError = unknown

export const useAccountingReportsList = <TError = unknown>(
 params?: AccountingReportsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof accountingReportsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getAccountingReportsListKey(params) : null);
  const swrFn = () => accountingReportsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const accountingReportsCreate = (
    accountingReportRequest: AccountingReportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingReport>(
      {url: `/api/v1/accounting/reports/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: accountingReportRequest
    },
      options);
    }
  


export const accountingReportsUpdate = (
    id: number,
    accountingReportRequest: AccountingReportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingReport>(
      {url: `/api/v1/accounting/reports/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: accountingReportRequest
    },
      options);
    }
  


export const accountingReportsPartialUpdate = (
    id: number,
    patchedAccountingReportRequest: PatchedAccountingReportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingReport>(
      {url: `/api/v1/accounting/reports/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedAccountingReportRequest
    },
      options);
    }
  


export const accountingReportsDetailsCreate = (
    reportPk: number,
    accountingReportDetailRequest: AccountingReportDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingReportDetail>(
      {url: `/api/v1/accounting/reports/${reportPk}/details/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: accountingReportDetailRequest
    },
      options);
    }
  


export const accountingReportsDetailsUpdate = (
    reportPk: number,
    id: number,
    accountingReportDetailRequest: AccountingReportDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingReportDetail>(
      {url: `/api/v1/accounting/reports/${reportPk}/details/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: accountingReportDetailRequest
    },
      options);
    }
  


export const accountingReportsDetailsPartialUpdate = (
    reportPk: number,
    id: number,
    patchedAccountingReportDetailRequest: PatchedAccountingReportDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingReportDetail>(
      {url: `/api/v1/accounting/reports/${reportPk}/details/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedAccountingReportDetailRequest
    },
      options);
    }
  


export const accountingReportsDetailsDestroy = (
    reportPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/accounting/reports/${reportPk}/details/${id}/`, method: 'delete'
    },
      options);
    }
  


/**
 * 会計報告を取り消す
 */
export const accountingReportsDetailsCancelCreate = (
    reportPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/accounting/reports/${reportPk}/details/${id}/cancel/`, method: 'post'
    },
      options);
    }
  


/**
 * 会計報告を確定する
 */
export const accountingReportsDetailsFixCreate = (
    reportPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/accounting/reports/${reportPk}/details/${id}/fix/`, method: 'post'
    },
      options);
    }
  


export const accountingReportsDetailsCreateByDocumentCreate = (
    reportPk: number,
    accountingReportByDocumentRequest: AccountingReportByDocumentRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingReportDetail>(
      {url: `/api/v1/accounting/reports/${reportPk}/details/create-by-document/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: accountingReportByDocumentRequest
    },
      options);
    }
  


export const billingsClosingDateTypesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ClosingDateType[]>(
      {url: `/api/v1/billings/closing-date-types/`, method: 'get'
    },
      options);
    }
  

export const getBillingsClosingDateTypesListKey = () => [`/api/v1/billings/closing-date-types/`] as const;

    
export type BillingsClosingDateTypesListQueryResult = NonNullable<Awaited<ReturnType<typeof billingsClosingDateTypesList>>>
export type BillingsClosingDateTypesListQueryError = unknown

export const useBillingsClosingDateTypesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof billingsClosingDateTypesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBillingsClosingDateTypesListKey() : null);
  const swrFn = () => billingsClosingDateTypesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const billingsClosingDateTypesCreate = (
    closingDateTypeRequest: ClosingDateTypeRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ClosingDateType>(
      {url: `/api/v1/billings/closing-date-types/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: closingDateTypeRequest
    },
      options);
    }
  


export const billingsClosingDateTypesRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ClosingDateType>(
      {url: `/api/v1/billings/closing-date-types/${id}/`, method: 'get'
    },
      options);
    }
  

export const getBillingsClosingDateTypesRetrieveKey = (id: number,) => [`/api/v1/billings/closing-date-types/${id}/`] as const;

    
export type BillingsClosingDateTypesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof billingsClosingDateTypesRetrieve>>>
export type BillingsClosingDateTypesRetrieveQueryError = unknown

export const useBillingsClosingDateTypesRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof billingsClosingDateTypesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBillingsClosingDateTypesRetrieveKey(id) : null);
  const swrFn = () => billingsClosingDateTypesRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const billingsClosingDateTypesUpdate = (
    id: number,
    closingDateTypeRequest: ClosingDateTypeRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ClosingDateType>(
      {url: `/api/v1/billings/closing-date-types/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: closingDateTypeRequest
    },
      options);
    }
  


export const billingsClosingDateTypesPartialUpdate = (
    id: number,
    patchedClosingDateTypeRequest: PatchedClosingDateTypeRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ClosingDateType>(
      {url: `/api/v1/billings/closing-date-types/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedClosingDateTypeRequest
    },
      options);
    }
  


export const billingsClosingDateTypesDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/billings/closing-date-types/${id}/`, method: 'delete'
    },
      options);
    }
  


export const billingsPaymentWaysList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaymentWay[]>(
      {url: `/api/v1/billings/payment-ways/`, method: 'get'
    },
      options);
    }
  

export const getBillingsPaymentWaysListKey = () => [`/api/v1/billings/payment-ways/`] as const;

    
export type BillingsPaymentWaysListQueryResult = NonNullable<Awaited<ReturnType<typeof billingsPaymentWaysList>>>
export type BillingsPaymentWaysListQueryError = unknown

export const useBillingsPaymentWaysList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof billingsPaymentWaysList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBillingsPaymentWaysListKey() : null);
  const swrFn = () => billingsPaymentWaysList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const billingsPaymentWaysCreate = (
    paymentWayRequest: PaymentWayRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaymentWay>(
      {url: `/api/v1/billings/payment-ways/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: paymentWayRequest
    },
      options);
    }
  


export const billingsPaymentWaysRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaymentWay>(
      {url: `/api/v1/billings/payment-ways/${id}/`, method: 'get'
    },
      options);
    }
  

export const getBillingsPaymentWaysRetrieveKey = (id: number,) => [`/api/v1/billings/payment-ways/${id}/`] as const;

    
export type BillingsPaymentWaysRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof billingsPaymentWaysRetrieve>>>
export type BillingsPaymentWaysRetrieveQueryError = unknown

export const useBillingsPaymentWaysRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof billingsPaymentWaysRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBillingsPaymentWaysRetrieveKey(id) : null);
  const swrFn = () => billingsPaymentWaysRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const billingsPaymentWaysUpdate = (
    id: number,
    paymentWayRequest: PaymentWayRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaymentWay>(
      {url: `/api/v1/billings/payment-ways/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: paymentWayRequest
    },
      options);
    }
  


export const billingsPaymentWaysPartialUpdate = (
    id: number,
    patchedPaymentWayRequest: PatchedPaymentWayRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaymentWay>(
      {url: `/api/v1/billings/payment-ways/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedPaymentWayRequest
    },
      options);
    }
  


export const billingsPaymentWaysDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/billings/payment-ways/${id}/`, method: 'delete'
    },
      options);
    }
  


export const businessPartnersAirForwardersList = (
    params?: BusinessPartnersAirForwardersListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirForwarder[]>(
      {url: `/api/v1/business-partners/air-forwarders/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersAirForwardersListKey = (params?: BusinessPartnersAirForwardersListParams,) => [`/api/v1/business-partners/air-forwarders/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersAirForwardersListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersAirForwardersList>>>
export type BusinessPartnersAirForwardersListQueryError = unknown

export const useBusinessPartnersAirForwardersList = <TError = unknown>(
 params?: BusinessPartnersAirForwardersListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersAirForwardersList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersAirForwardersListKey(params) : null);
  const swrFn = () => businessPartnersAirForwardersList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersAirForwardersCreate = (
    airForwarderRequest: AirForwarderRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirForwarder>(
      {url: `/api/v1/business-partners/air-forwarders/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: airForwarderRequest
    },
      options);
    }
  


export const businessPartnersAirForwardersRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirForwarder>(
      {url: `/api/v1/business-partners/air-forwarders/${id}/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersAirForwardersRetrieveKey = (id: number,) => [`/api/v1/business-partners/air-forwarders/${id}/`] as const;

    
export type BusinessPartnersAirForwardersRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersAirForwardersRetrieve>>>
export type BusinessPartnersAirForwardersRetrieveQueryError = unknown

export const useBusinessPartnersAirForwardersRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersAirForwardersRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersAirForwardersRetrieveKey(id) : null);
  const swrFn = () => businessPartnersAirForwardersRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersAirForwardersUpdate = (
    id: number,
    airForwarderRequest: AirForwarderRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirForwarder>(
      {url: `/api/v1/business-partners/air-forwarders/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: airForwarderRequest
    },
      options);
    }
  


export const businessPartnersAirForwardersPartialUpdate = (
    id: number,
    patchedAirForwarderRequest: PatchedAirForwarderRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirForwarder>(
      {url: `/api/v1/business-partners/air-forwarders/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedAirForwarderRequest
    },
      options);
    }
  


export const businessPartnersAirForwardersDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/business-partners/air-forwarders/${id}/`, method: 'delete'
    },
      options);
    }
  


export const businessPartnersAirForwardersContactsList = (
    id: number,
    params?: BusinessPartnersAirForwardersContactsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Contact[]>(
      {url: `/api/v1/business-partners/air-forwarders/${id}/contacts/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersAirForwardersContactsListKey = (id: number,
    params?: BusinessPartnersAirForwardersContactsListParams,) => [`/api/v1/business-partners/air-forwarders/${id}/contacts/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersAirForwardersContactsListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersAirForwardersContactsList>>>
export type BusinessPartnersAirForwardersContactsListQueryError = unknown

export const useBusinessPartnersAirForwardersContactsList = <TError = unknown>(
 id: number,
    params?: BusinessPartnersAirForwardersContactsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersAirForwardersContactsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersAirForwardersContactsListKey(id,params) : null);
  const swrFn = () => businessPartnersAirForwardersContactsList(id,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersAirForwardersPaginatedList = (
    params?: BusinessPartnersAirForwardersPaginatedListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedAirForwarderList>(
      {url: `/api/v1/business-partners/air-forwarders/paginated/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersAirForwardersPaginatedListKey = (params?: BusinessPartnersAirForwardersPaginatedListParams,) => [`/api/v1/business-partners/air-forwarders/paginated/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersAirForwardersPaginatedListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersAirForwardersPaginatedList>>>
export type BusinessPartnersAirForwardersPaginatedListQueryError = unknown

export const useBusinessPartnersAirForwardersPaginatedList = <TError = unknown>(
 params?: BusinessPartnersAirForwardersPaginatedListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersAirForwardersPaginatedList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersAirForwardersPaginatedListKey(params) : null);
  const swrFn = () => businessPartnersAirForwardersPaginatedList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersCustomsBrokersList = (
    params?: BusinessPartnersCustomsBrokersListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsBrokers[]>(
      {url: `/api/v1/business-partners/customs-brokers/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersCustomsBrokersListKey = (params?: BusinessPartnersCustomsBrokersListParams,) => [`/api/v1/business-partners/customs-brokers/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersCustomsBrokersListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersCustomsBrokersList>>>
export type BusinessPartnersCustomsBrokersListQueryError = unknown

export const useBusinessPartnersCustomsBrokersList = <TError = unknown>(
 params?: BusinessPartnersCustomsBrokersListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersCustomsBrokersList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersCustomsBrokersListKey(params) : null);
  const swrFn = () => businessPartnersCustomsBrokersList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersCustomsBrokersCreate = (
    customsBrokersRequest: CustomsBrokersRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsBrokers>(
      {url: `/api/v1/business-partners/customs-brokers/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: customsBrokersRequest
    },
      options);
    }
  


export const businessPartnersCustomsBrokersRetrieve = (
    customsBrokersId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsBrokers>(
      {url: `/api/v1/business-partners/customs-brokers/${customsBrokersId}/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersCustomsBrokersRetrieveKey = (customsBrokersId: number,) => [`/api/v1/business-partners/customs-brokers/${customsBrokersId}/`] as const;

    
export type BusinessPartnersCustomsBrokersRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersCustomsBrokersRetrieve>>>
export type BusinessPartnersCustomsBrokersRetrieveQueryError = unknown

export const useBusinessPartnersCustomsBrokersRetrieve = <TError = unknown>(
 customsBrokersId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersCustomsBrokersRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(customsBrokersId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersCustomsBrokersRetrieveKey(customsBrokersId) : null);
  const swrFn = () => businessPartnersCustomsBrokersRetrieve(customsBrokersId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersCustomsBrokersUpdate = (
    customsBrokersId: number,
    customsBrokersRequest: CustomsBrokersRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsBrokers>(
      {url: `/api/v1/business-partners/customs-brokers/${customsBrokersId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: customsBrokersRequest
    },
      options);
    }
  


export const businessPartnersCustomsBrokersPartialUpdate = (
    customsBrokersId: number,
    patchedCustomsBrokersRequest: PatchedCustomsBrokersRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsBrokers>(
      {url: `/api/v1/business-partners/customs-brokers/${customsBrokersId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedCustomsBrokersRequest
    },
      options);
    }
  


export const businessPartnersCustomsBrokersDestroy = (
    customsBrokersId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/business-partners/customs-brokers/${customsBrokersId}/`, method: 'delete'
    },
      options);
    }
  


export const businessPartnersCustomsBrokersContactsList = (
    customsBrokersId: number,
    params?: BusinessPartnersCustomsBrokersContactsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Contact[]>(
      {url: `/api/v1/business-partners/customs-brokers/${customsBrokersId}/contacts/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersCustomsBrokersContactsListKey = (customsBrokersId: number,
    params?: BusinessPartnersCustomsBrokersContactsListParams,) => [`/api/v1/business-partners/customs-brokers/${customsBrokersId}/contacts/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersCustomsBrokersContactsListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersCustomsBrokersContactsList>>>
export type BusinessPartnersCustomsBrokersContactsListQueryError = unknown

export const useBusinessPartnersCustomsBrokersContactsList = <TError = unknown>(
 customsBrokersId: number,
    params?: BusinessPartnersCustomsBrokersContactsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersCustomsBrokersContactsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(customsBrokersId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersCustomsBrokersContactsListKey(customsBrokersId,params) : null);
  const swrFn = () => businessPartnersCustomsBrokersContactsList(customsBrokersId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersCustomsBrokersPaginatedList = (
    params?: BusinessPartnersCustomsBrokersPaginatedListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedCustomsBrokersList>(
      {url: `/api/v1/business-partners/customs-brokers/paginated/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersCustomsBrokersPaginatedListKey = (params?: BusinessPartnersCustomsBrokersPaginatedListParams,) => [`/api/v1/business-partners/customs-brokers/paginated/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersCustomsBrokersPaginatedListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersCustomsBrokersPaginatedList>>>
export type BusinessPartnersCustomsBrokersPaginatedListQueryError = unknown

export const useBusinessPartnersCustomsBrokersPaginatedList = <TError = unknown>(
 params?: BusinessPartnersCustomsBrokersPaginatedListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersCustomsBrokersPaginatedList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersCustomsBrokersPaginatedListKey(params) : null);
  const swrFn = () => businessPartnersCustomsBrokersPaginatedList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersForwardersList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Forwarder[]>(
      {url: `/api/v1/business-partners/forwarders/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersForwardersListKey = () => [`/api/v1/business-partners/forwarders/`] as const;

    
export type BusinessPartnersForwardersListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersForwardersList>>>
export type BusinessPartnersForwardersListQueryError = unknown

export const useBusinessPartnersForwardersList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersForwardersList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersForwardersListKey() : null);
  const swrFn = () => businessPartnersForwardersList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersForwardersCreate = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Forwarder>(
      {url: `/api/v1/business-partners/forwarders/`, method: 'post'
    },
      options);
    }
  


export const businessPartnersForwardersBillingsList = (
    forwarderPk: string,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Billing[]>(
      {url: `/api/v1/business-partners/forwarders/${forwarderPk}/billings/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersForwardersBillingsListKey = (forwarderPk: string,) => [`/api/v1/business-partners/forwarders/${forwarderPk}/billings/`] as const;

    
export type BusinessPartnersForwardersBillingsListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersForwardersBillingsList>>>
export type BusinessPartnersForwardersBillingsListQueryError = unknown

export const useBusinessPartnersForwardersBillingsList = <TError = unknown>(
 forwarderPk: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersForwardersBillingsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(forwarderPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersForwardersBillingsListKey(forwarderPk) : null);
  const swrFn = () => businessPartnersForwardersBillingsList(forwarderPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersForwardersBillingsCreate = (
    forwarderPk: string,
    billingRequest: BillingRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Billing>(
      {url: `/api/v1/business-partners/forwarders/${forwarderPk}/billings/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: billingRequest
    },
      options);
    }
  


export const businessPartnersForwardersBillingsDetailsList = (
    forwarderPk: string,
    billingPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BillingDetail[]>(
      {url: `/api/v1/business-partners/forwarders/${forwarderPk}/billings/${billingPk}/details/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersForwardersBillingsDetailsListKey = (forwarderPk: string,
    billingPk: number,) => [`/api/v1/business-partners/forwarders/${forwarderPk}/billings/${billingPk}/details/`] as const;

    
export type BusinessPartnersForwardersBillingsDetailsListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersForwardersBillingsDetailsList>>>
export type BusinessPartnersForwardersBillingsDetailsListQueryError = unknown

export const useBusinessPartnersForwardersBillingsDetailsList = <TError = unknown>(
 forwarderPk: string,
    billingPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersForwardersBillingsDetailsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(forwarderPk && billingPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersForwardersBillingsDetailsListKey(forwarderPk,billingPk) : null);
  const swrFn = () => businessPartnersForwardersBillingsDetailsList(forwarderPk,billingPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersForwardersBillingsDetailsCreate = (
    forwarderPk: string,
    billingPk: number,
    billingDetailRequest: BillingDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BillingDetail>(
      {url: `/api/v1/business-partners/forwarders/${forwarderPk}/billings/${billingPk}/details/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: billingDetailRequest
    },
      options);
    }
  


export const businessPartnersForwardersBillingsDetailsRetrieve = (
    forwarderPk: string,
    billingPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BillingDetail>(
      {url: `/api/v1/business-partners/forwarders/${forwarderPk}/billings/${billingPk}/details/${id}/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersForwardersBillingsDetailsRetrieveKey = (forwarderPk: string,
    billingPk: number,
    id: number,) => [`/api/v1/business-partners/forwarders/${forwarderPk}/billings/${billingPk}/details/${id}/`] as const;

    
export type BusinessPartnersForwardersBillingsDetailsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersForwardersBillingsDetailsRetrieve>>>
export type BusinessPartnersForwardersBillingsDetailsRetrieveQueryError = unknown

export const useBusinessPartnersForwardersBillingsDetailsRetrieve = <TError = unknown>(
 forwarderPk: string,
    billingPk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersForwardersBillingsDetailsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(forwarderPk && billingPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersForwardersBillingsDetailsRetrieveKey(forwarderPk,billingPk,id) : null);
  const swrFn = () => businessPartnersForwardersBillingsDetailsRetrieve(forwarderPk,billingPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersForwardersBillingsDetailsUpdate = (
    forwarderPk: string,
    billingPk: number,
    id: number,
    billingDetailRequest: BillingDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BillingDetail>(
      {url: `/api/v1/business-partners/forwarders/${forwarderPk}/billings/${billingPk}/details/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: billingDetailRequest
    },
      options);
    }
  


export const businessPartnersForwardersBillingsDetailsPartialUpdate = (
    forwarderPk: string,
    billingPk: number,
    id: number,
    patchedBillingDetailRequest: PatchedBillingDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BillingDetail>(
      {url: `/api/v1/business-partners/forwarders/${forwarderPk}/billings/${billingPk}/details/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedBillingDetailRequest
    },
      options);
    }
  


export const businessPartnersForwardersBillingsDetailsDestroy = (
    forwarderPk: string,
    billingPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/business-partners/forwarders/${forwarderPk}/billings/${billingPk}/details/${id}/`, method: 'delete'
    },
      options);
    }
  


export const businessPartnersForwardersBillingsRetrieve = (
    forwarderPk: string,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Billing>(
      {url: `/api/v1/business-partners/forwarders/${forwarderPk}/billings/${id}/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersForwardersBillingsRetrieveKey = (forwarderPk: string,
    id: number,) => [`/api/v1/business-partners/forwarders/${forwarderPk}/billings/${id}/`] as const;

    
export type BusinessPartnersForwardersBillingsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersForwardersBillingsRetrieve>>>
export type BusinessPartnersForwardersBillingsRetrieveQueryError = unknown

export const useBusinessPartnersForwardersBillingsRetrieve = <TError = unknown>(
 forwarderPk: string,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersForwardersBillingsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(forwarderPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersForwardersBillingsRetrieveKey(forwarderPk,id) : null);
  const swrFn = () => businessPartnersForwardersBillingsRetrieve(forwarderPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersForwardersBillingsUpdate = (
    forwarderPk: string,
    id: number,
    billingRequest: BillingRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Billing>(
      {url: `/api/v1/business-partners/forwarders/${forwarderPk}/billings/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: billingRequest
    },
      options);
    }
  


export const businessPartnersForwardersBillingsPartialUpdate = (
    forwarderPk: string,
    id: number,
    patchedBillingRequest: PatchedBillingRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Billing>(
      {url: `/api/v1/business-partners/forwarders/${forwarderPk}/billings/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedBillingRequest
    },
      options);
    }
  


export const businessPartnersForwardersBillingsDestroy = (
    forwarderPk: string,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/business-partners/forwarders/${forwarderPk}/billings/${id}/`, method: 'delete'
    },
      options);
    }
  


export const businessPartnersForwardersUsersList = (
    forwarderPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Users[]>(
      {url: `/api/v1/business-partners/forwarders/${forwarderPk}/users/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersForwardersUsersListKey = (forwarderPk: number,) => [`/api/v1/business-partners/forwarders/${forwarderPk}/users/`] as const;

    
export type BusinessPartnersForwardersUsersListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersForwardersUsersList>>>
export type BusinessPartnersForwardersUsersListQueryError = unknown

export const useBusinessPartnersForwardersUsersList = <TError = unknown>(
 forwarderPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersForwardersUsersList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(forwarderPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersForwardersUsersListKey(forwarderPk) : null);
  const swrFn = () => businessPartnersForwardersUsersList(forwarderPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersForwardersUsersRetrieve = (
    forwarderPk: number,
    usersId: string,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Users>(
      {url: `/api/v1/business-partners/forwarders/${forwarderPk}/users/${usersId}/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersForwardersUsersRetrieveKey = (forwarderPk: number,
    usersId: string,) => [`/api/v1/business-partners/forwarders/${forwarderPk}/users/${usersId}/`] as const;

    
export type BusinessPartnersForwardersUsersRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersForwardersUsersRetrieve>>>
export type BusinessPartnersForwardersUsersRetrieveQueryError = unknown

export const useBusinessPartnersForwardersUsersRetrieve = <TError = unknown>(
 forwarderPk: number,
    usersId: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersForwardersUsersRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(forwarderPk && usersId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersForwardersUsersRetrieveKey(forwarderPk,usersId) : null);
  const swrFn = () => businessPartnersForwardersUsersRetrieve(forwarderPk,usersId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersForwardersRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Forwarder>(
      {url: `/api/v1/business-partners/forwarders/${id}/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersForwardersRetrieveKey = (id: number,) => [`/api/v1/business-partners/forwarders/${id}/`] as const;

    
export type BusinessPartnersForwardersRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersForwardersRetrieve>>>
export type BusinessPartnersForwardersRetrieveQueryError = unknown

export const useBusinessPartnersForwardersRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersForwardersRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersForwardersRetrieveKey(id) : null);
  const swrFn = () => businessPartnersForwardersRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersForwardersUpdate = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Forwarder>(
      {url: `/api/v1/business-partners/forwarders/${id}/`, method: 'put'
    },
      options);
    }
  


export const businessPartnersForwardersPartialUpdate = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Forwarder>(
      {url: `/api/v1/business-partners/forwarders/${id}/`, method: 'patch'
    },
      options);
    }
  


export const businessPartnersForwardersDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/business-partners/forwarders/${id}/`, method: 'delete'
    },
      options);
    }
  


export const businessPartnersFreightForwardersList = (
    params?: BusinessPartnersFreightForwardersListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MarineForwarders[]>(
      {url: `/api/v1/business-partners/freight-forwarders/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersFreightForwardersListKey = (params?: BusinessPartnersFreightForwardersListParams,) => [`/api/v1/business-partners/freight-forwarders/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersFreightForwardersListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersFreightForwardersList>>>
export type BusinessPartnersFreightForwardersListQueryError = unknown

export const useBusinessPartnersFreightForwardersList = <TError = unknown>(
 params?: BusinessPartnersFreightForwardersListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersFreightForwardersList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersFreightForwardersListKey(params) : null);
  const swrFn = () => businessPartnersFreightForwardersList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersFreightForwardersCreate = (
    marineForwardersRequest: MarineForwardersRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MarineForwarders>(
      {url: `/api/v1/business-partners/freight-forwarders/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: marineForwardersRequest
    },
      options);
    }
  


export const businessPartnersFreightForwardersRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MarineForwarders>(
      {url: `/api/v1/business-partners/freight-forwarders/${id}/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersFreightForwardersRetrieveKey = (id: number,) => [`/api/v1/business-partners/freight-forwarders/${id}/`] as const;

    
export type BusinessPartnersFreightForwardersRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersFreightForwardersRetrieve>>>
export type BusinessPartnersFreightForwardersRetrieveQueryError = unknown

export const useBusinessPartnersFreightForwardersRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersFreightForwardersRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersFreightForwardersRetrieveKey(id) : null);
  const swrFn = () => businessPartnersFreightForwardersRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersFreightForwardersUpdate = (
    id: number,
    marineForwardersRequest: MarineForwardersRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MarineForwarders>(
      {url: `/api/v1/business-partners/freight-forwarders/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: marineForwardersRequest
    },
      options);
    }
  


export const businessPartnersFreightForwardersPartialUpdate = (
    id: number,
    patchedMarineForwardersRequest: PatchedMarineForwardersRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MarineForwarders>(
      {url: `/api/v1/business-partners/freight-forwarders/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedMarineForwardersRequest
    },
      options);
    }
  


export const businessPartnersFreightForwardersDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/business-partners/freight-forwarders/${id}/`, method: 'delete'
    },
      options);
    }
  


export const businessPartnersFreightForwardersContactsList = (
    id: number,
    params?: BusinessPartnersFreightForwardersContactsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Contact[]>(
      {url: `/api/v1/business-partners/freight-forwarders/${id}/contacts/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersFreightForwardersContactsListKey = (id: number,
    params?: BusinessPartnersFreightForwardersContactsListParams,) => [`/api/v1/business-partners/freight-forwarders/${id}/contacts/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersFreightForwardersContactsListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersFreightForwardersContactsList>>>
export type BusinessPartnersFreightForwardersContactsListQueryError = unknown

export const useBusinessPartnersFreightForwardersContactsList = <TError = unknown>(
 id: number,
    params?: BusinessPartnersFreightForwardersContactsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersFreightForwardersContactsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersFreightForwardersContactsListKey(id,params) : null);
  const swrFn = () => businessPartnersFreightForwardersContactsList(id,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersFreightForwardersPaginatedList = (
    params?: BusinessPartnersFreightForwardersPaginatedListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedMarineForwardersList>(
      {url: `/api/v1/business-partners/freight-forwarders/paginated/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersFreightForwardersPaginatedListKey = (params?: BusinessPartnersFreightForwardersPaginatedListParams,) => [`/api/v1/business-partners/freight-forwarders/paginated/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersFreightForwardersPaginatedListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersFreightForwardersPaginatedList>>>
export type BusinessPartnersFreightForwardersPaginatedListQueryError = unknown

export const useBusinessPartnersFreightForwardersPaginatedList = <TError = unknown>(
 params?: BusinessPartnersFreightForwardersPaginatedListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersFreightForwardersPaginatedList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersFreightForwardersPaginatedListKey(params) : null);
  const swrFn = () => businessPartnersFreightForwardersPaginatedList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersShippingCompaniesList = (
    params?: BusinessPartnersShippingCompaniesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShippingCompanies[]>(
      {url: `/api/v1/business-partners/shipping-companies/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersShippingCompaniesListKey = (params?: BusinessPartnersShippingCompaniesListParams,) => [`/api/v1/business-partners/shipping-companies/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersShippingCompaniesListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersShippingCompaniesList>>>
export type BusinessPartnersShippingCompaniesListQueryError = unknown

export const useBusinessPartnersShippingCompaniesList = <TError = unknown>(
 params?: BusinessPartnersShippingCompaniesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersShippingCompaniesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersShippingCompaniesListKey(params) : null);
  const swrFn = () => businessPartnersShippingCompaniesList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersShippingCompaniesCreate = (
    shippingCompaniesRequest: ShippingCompaniesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShippingCompanies>(
      {url: `/api/v1/business-partners/shipping-companies/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: shippingCompaniesRequest
    },
      options);
    }
  


export const businessPartnersShippingCompaniesRetrieve = (
    shippingCompaniesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShippingCompanies>(
      {url: `/api/v1/business-partners/shipping-companies/${shippingCompaniesId}/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersShippingCompaniesRetrieveKey = (shippingCompaniesId: number,) => [`/api/v1/business-partners/shipping-companies/${shippingCompaniesId}/`] as const;

    
export type BusinessPartnersShippingCompaniesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersShippingCompaniesRetrieve>>>
export type BusinessPartnersShippingCompaniesRetrieveQueryError = unknown

export const useBusinessPartnersShippingCompaniesRetrieve = <TError = unknown>(
 shippingCompaniesId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersShippingCompaniesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shippingCompaniesId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersShippingCompaniesRetrieveKey(shippingCompaniesId) : null);
  const swrFn = () => businessPartnersShippingCompaniesRetrieve(shippingCompaniesId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersShippingCompaniesUpdate = (
    shippingCompaniesId: number,
    shippingCompaniesRequest: ShippingCompaniesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShippingCompanies>(
      {url: `/api/v1/business-partners/shipping-companies/${shippingCompaniesId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: shippingCompaniesRequest
    },
      options);
    }
  


export const businessPartnersShippingCompaniesPartialUpdate = (
    shippingCompaniesId: number,
    patchedShippingCompaniesRequest: PatchedShippingCompaniesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShippingCompanies>(
      {url: `/api/v1/business-partners/shipping-companies/${shippingCompaniesId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedShippingCompaniesRequest
    },
      options);
    }
  


export const businessPartnersShippingCompaniesDestroy = (
    shippingCompaniesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/business-partners/shipping-companies/${shippingCompaniesId}/`, method: 'delete'
    },
      options);
    }
  


export const businessPartnersShippingCompaniesContactsList = (
    shippingCompaniesId: number,
    params?: BusinessPartnersShippingCompaniesContactsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Contact[]>(
      {url: `/api/v1/business-partners/shipping-companies/${shippingCompaniesId}/contacts/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersShippingCompaniesContactsListKey = (shippingCompaniesId: number,
    params?: BusinessPartnersShippingCompaniesContactsListParams,) => [`/api/v1/business-partners/shipping-companies/${shippingCompaniesId}/contacts/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersShippingCompaniesContactsListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersShippingCompaniesContactsList>>>
export type BusinessPartnersShippingCompaniesContactsListQueryError = unknown

export const useBusinessPartnersShippingCompaniesContactsList = <TError = unknown>(
 shippingCompaniesId: number,
    params?: BusinessPartnersShippingCompaniesContactsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersShippingCompaniesContactsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shippingCompaniesId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersShippingCompaniesContactsListKey(shippingCompaniesId,params) : null);
  const swrFn = () => businessPartnersShippingCompaniesContactsList(shippingCompaniesId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersShippingCompaniesAirList = (
    params?: BusinessPartnersShippingCompaniesAirListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShippingCompanies[]>(
      {url: `/api/v1/business-partners/shipping-companies/air/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersShippingCompaniesAirListKey = (params?: BusinessPartnersShippingCompaniesAirListParams,) => [`/api/v1/business-partners/shipping-companies/air/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersShippingCompaniesAirListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersShippingCompaniesAirList>>>
export type BusinessPartnersShippingCompaniesAirListQueryError = unknown

export const useBusinessPartnersShippingCompaniesAirList = <TError = unknown>(
 params?: BusinessPartnersShippingCompaniesAirListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersShippingCompaniesAirList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersShippingCompaniesAirListKey(params) : null);
  const swrFn = () => businessPartnersShippingCompaniesAirList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersShippingCompaniesAirPaginatedList = (
    params?: BusinessPartnersShippingCompaniesAirPaginatedListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedShippingCompaniesList>(
      {url: `/api/v1/business-partners/shipping-companies/air/paginated/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersShippingCompaniesAirPaginatedListKey = (params?: BusinessPartnersShippingCompaniesAirPaginatedListParams,) => [`/api/v1/business-partners/shipping-companies/air/paginated/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersShippingCompaniesAirPaginatedListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersShippingCompaniesAirPaginatedList>>>
export type BusinessPartnersShippingCompaniesAirPaginatedListQueryError = unknown

export const useBusinessPartnersShippingCompaniesAirPaginatedList = <TError = unknown>(
 params?: BusinessPartnersShippingCompaniesAirPaginatedListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersShippingCompaniesAirPaginatedList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersShippingCompaniesAirPaginatedListKey(params) : null);
  const swrFn = () => businessPartnersShippingCompaniesAirPaginatedList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersShippingCompaniesMarineList = (
    params?: BusinessPartnersShippingCompaniesMarineListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShippingCompanies[]>(
      {url: `/api/v1/business-partners/shipping-companies/marine/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersShippingCompaniesMarineListKey = (params?: BusinessPartnersShippingCompaniesMarineListParams,) => [`/api/v1/business-partners/shipping-companies/marine/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersShippingCompaniesMarineListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersShippingCompaniesMarineList>>>
export type BusinessPartnersShippingCompaniesMarineListQueryError = unknown

export const useBusinessPartnersShippingCompaniesMarineList = <TError = unknown>(
 params?: BusinessPartnersShippingCompaniesMarineListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersShippingCompaniesMarineList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersShippingCompaniesMarineListKey(params) : null);
  const swrFn = () => businessPartnersShippingCompaniesMarineList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersShippingCompaniesMarinePaginatedList = (
    params?: BusinessPartnersShippingCompaniesMarinePaginatedListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedShippingCompaniesList>(
      {url: `/api/v1/business-partners/shipping-companies/marine/paginated/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersShippingCompaniesMarinePaginatedListKey = (params?: BusinessPartnersShippingCompaniesMarinePaginatedListParams,) => [`/api/v1/business-partners/shipping-companies/marine/paginated/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersShippingCompaniesMarinePaginatedListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersShippingCompaniesMarinePaginatedList>>>
export type BusinessPartnersShippingCompaniesMarinePaginatedListQueryError = unknown

export const useBusinessPartnersShippingCompaniesMarinePaginatedList = <TError = unknown>(
 params?: BusinessPartnersShippingCompaniesMarinePaginatedListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersShippingCompaniesMarinePaginatedList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersShippingCompaniesMarinePaginatedListKey(params) : null);
  const swrFn = () => businessPartnersShippingCompaniesMarinePaginatedList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersSuppliersList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Supplier[]>(
      {url: `/api/v1/business-partners/suppliers/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersSuppliersListKey = () => [`/api/v1/business-partners/suppliers/`] as const;

    
export type BusinessPartnersSuppliersListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersSuppliersList>>>
export type BusinessPartnersSuppliersListQueryError = unknown

export const useBusinessPartnersSuppliersList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersSuppliersList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersSuppliersListKey() : null);
  const swrFn = () => businessPartnersSuppliersList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersTruckCarriersList = (
    params?: BusinessPartnersTruckCarriersListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TruckCarriers[]>(
      {url: `/api/v1/business-partners/truck-carriers/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersTruckCarriersListKey = (params?: BusinessPartnersTruckCarriersListParams,) => [`/api/v1/business-partners/truck-carriers/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersTruckCarriersListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersTruckCarriersList>>>
export type BusinessPartnersTruckCarriersListQueryError = unknown

export const useBusinessPartnersTruckCarriersList = <TError = unknown>(
 params?: BusinessPartnersTruckCarriersListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersTruckCarriersList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersTruckCarriersListKey(params) : null);
  const swrFn = () => businessPartnersTruckCarriersList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersTruckCarriersCreate = (
    truckCarriersRequest: TruckCarriersRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TruckCarriers>(
      {url: `/api/v1/business-partners/truck-carriers/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: truckCarriersRequest
    },
      options);
    }
  


export const businessPartnersTruckCarriersRetrieve = (
    truckCarriersId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TruckCarriers>(
      {url: `/api/v1/business-partners/truck-carriers/${truckCarriersId}/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersTruckCarriersRetrieveKey = (truckCarriersId: number,) => [`/api/v1/business-partners/truck-carriers/${truckCarriersId}/`] as const;

    
export type BusinessPartnersTruckCarriersRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersTruckCarriersRetrieve>>>
export type BusinessPartnersTruckCarriersRetrieveQueryError = unknown

export const useBusinessPartnersTruckCarriersRetrieve = <TError = unknown>(
 truckCarriersId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersTruckCarriersRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(truckCarriersId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersTruckCarriersRetrieveKey(truckCarriersId) : null);
  const swrFn = () => businessPartnersTruckCarriersRetrieve(truckCarriersId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersTruckCarriersUpdate = (
    truckCarriersId: number,
    truckCarriersRequest: TruckCarriersRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TruckCarriers>(
      {url: `/api/v1/business-partners/truck-carriers/${truckCarriersId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: truckCarriersRequest
    },
      options);
    }
  


export const businessPartnersTruckCarriersPartialUpdate = (
    truckCarriersId: number,
    patchedTruckCarriersRequest: PatchedTruckCarriersRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TruckCarriers>(
      {url: `/api/v1/business-partners/truck-carriers/${truckCarriersId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedTruckCarriersRequest
    },
      options);
    }
  


export const businessPartnersTruckCarriersDestroy = (
    truckCarriersId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/business-partners/truck-carriers/${truckCarriersId}/`, method: 'delete'
    },
      options);
    }
  


export const businessPartnersTruckCarriersContactsList = (
    truckCarriersId: number,
    params?: BusinessPartnersTruckCarriersContactsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Contact[]>(
      {url: `/api/v1/business-partners/truck-carriers/${truckCarriersId}/contacts/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersTruckCarriersContactsListKey = (truckCarriersId: number,
    params?: BusinessPartnersTruckCarriersContactsListParams,) => [`/api/v1/business-partners/truck-carriers/${truckCarriersId}/contacts/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersTruckCarriersContactsListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersTruckCarriersContactsList>>>
export type BusinessPartnersTruckCarriersContactsListQueryError = unknown

export const useBusinessPartnersTruckCarriersContactsList = <TError = unknown>(
 truckCarriersId: number,
    params?: BusinessPartnersTruckCarriersContactsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersTruckCarriersContactsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(truckCarriersId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersTruckCarriersContactsListKey(truckCarriersId,params) : null);
  const swrFn = () => businessPartnersTruckCarriersContactsList(truckCarriersId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersTruckCarriersPaginatedList = (
    params?: BusinessPartnersTruckCarriersPaginatedListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedTruckCarriersList>(
      {url: `/api/v1/business-partners/truck-carriers/paginated/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersTruckCarriersPaginatedListKey = (params?: BusinessPartnersTruckCarriersPaginatedListParams,) => [`/api/v1/business-partners/truck-carriers/paginated/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersTruckCarriersPaginatedListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersTruckCarriersPaginatedList>>>
export type BusinessPartnersTruckCarriersPaginatedListQueryError = unknown

export const useBusinessPartnersTruckCarriersPaginatedList = <TError = unknown>(
 params?: BusinessPartnersTruckCarriersPaginatedListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersTruckCarriersPaginatedList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersTruckCarriersPaginatedListKey(params) : null);
  const swrFn = () => businessPartnersTruckCarriersPaginatedList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersWarehousesList = (
    params?: BusinessPartnersWarehousesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Warehouse[]>(
      {url: `/api/v1/business-partners/warehouses/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersWarehousesListKey = (params?: BusinessPartnersWarehousesListParams,) => [`/api/v1/business-partners/warehouses/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersWarehousesListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersWarehousesList>>>
export type BusinessPartnersWarehousesListQueryError = unknown

export const useBusinessPartnersWarehousesList = <TError = unknown>(
 params?: BusinessPartnersWarehousesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersWarehousesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersWarehousesListKey(params) : null);
  const swrFn = () => businessPartnersWarehousesList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersWarehousesCreate = (
    warehouseRequest: WarehouseRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Warehouse>(
      {url: `/api/v1/business-partners/warehouses/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: warehouseRequest
    },
      options);
    }
  


export const businessPartnersWarehousesRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Warehouse>(
      {url: `/api/v1/business-partners/warehouses/${id}/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersWarehousesRetrieveKey = (id: number,) => [`/api/v1/business-partners/warehouses/${id}/`] as const;

    
export type BusinessPartnersWarehousesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersWarehousesRetrieve>>>
export type BusinessPartnersWarehousesRetrieveQueryError = unknown

export const useBusinessPartnersWarehousesRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersWarehousesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersWarehousesRetrieveKey(id) : null);
  const swrFn = () => businessPartnersWarehousesRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersWarehousesUpdate = (
    id: number,
    warehouseRequest: WarehouseRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Warehouse>(
      {url: `/api/v1/business-partners/warehouses/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: warehouseRequest
    },
      options);
    }
  


export const businessPartnersWarehousesPartialUpdate = (
    id: number,
    patchedWarehouseRequest: PatchedWarehouseRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Warehouse>(
      {url: `/api/v1/business-partners/warehouses/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedWarehouseRequest
    },
      options);
    }
  


export const businessPartnersWarehousesDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/business-partners/warehouses/${id}/`, method: 'delete'
    },
      options);
    }
  


export const businessPartnersWarehousingCompaniesList = (
    params?: BusinessPartnersWarehousingCompaniesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<WarehousingCompanies[]>(
      {url: `/api/v1/business-partners/warehousing-companies/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersWarehousingCompaniesListKey = (params?: BusinessPartnersWarehousingCompaniesListParams,) => [`/api/v1/business-partners/warehousing-companies/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersWarehousingCompaniesListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersWarehousingCompaniesList>>>
export type BusinessPartnersWarehousingCompaniesListQueryError = unknown

export const useBusinessPartnersWarehousingCompaniesList = <TError = unknown>(
 params?: BusinessPartnersWarehousingCompaniesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersWarehousingCompaniesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersWarehousingCompaniesListKey(params) : null);
  const swrFn = () => businessPartnersWarehousingCompaniesList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersWarehousingCompaniesCreate = (
    warehousingCompaniesRequest: WarehousingCompaniesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<WarehousingCompanies>(
      {url: `/api/v1/business-partners/warehousing-companies/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: warehousingCompaniesRequest
    },
      options);
    }
  


export const businessPartnersWarehousingCompaniesRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<WarehousingCompanies>(
      {url: `/api/v1/business-partners/warehousing-companies/${id}/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersWarehousingCompaniesRetrieveKey = (id: number,) => [`/api/v1/business-partners/warehousing-companies/${id}/`] as const;

    
export type BusinessPartnersWarehousingCompaniesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersWarehousingCompaniesRetrieve>>>
export type BusinessPartnersWarehousingCompaniesRetrieveQueryError = unknown

export const useBusinessPartnersWarehousingCompaniesRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersWarehousingCompaniesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersWarehousingCompaniesRetrieveKey(id) : null);
  const swrFn = () => businessPartnersWarehousingCompaniesRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersWarehousingCompaniesUpdate = (
    id: number,
    warehousingCompaniesRequest: WarehousingCompaniesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<WarehousingCompanies>(
      {url: `/api/v1/business-partners/warehousing-companies/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: warehousingCompaniesRequest
    },
      options);
    }
  


export const businessPartnersWarehousingCompaniesPartialUpdate = (
    id: number,
    patchedWarehousingCompaniesRequest: PatchedWarehousingCompaniesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<WarehousingCompanies>(
      {url: `/api/v1/business-partners/warehousing-companies/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedWarehousingCompaniesRequest
    },
      options);
    }
  


export const businessPartnersWarehousingCompaniesDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/business-partners/warehousing-companies/${id}/`, method: 'delete'
    },
      options);
    }
  


export const businessPartnersWarehousingCompaniesContactsList = (
    warehousingCompanyPk: number,
    params?: BusinessPartnersWarehousingCompaniesContactsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Contact[]>(
      {url: `/api/v1/business-partners/warehousing-companies/${warehousingCompanyPk}/contacts/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersWarehousingCompaniesContactsListKey = (warehousingCompanyPk: number,
    params?: BusinessPartnersWarehousingCompaniesContactsListParams,) => [`/api/v1/business-partners/warehousing-companies/${warehousingCompanyPk}/contacts/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersWarehousingCompaniesContactsListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersWarehousingCompaniesContactsList>>>
export type BusinessPartnersWarehousingCompaniesContactsListQueryError = unknown

export const useBusinessPartnersWarehousingCompaniesContactsList = <TError = unknown>(
 warehousingCompanyPk: number,
    params?: BusinessPartnersWarehousingCompaniesContactsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersWarehousingCompaniesContactsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(warehousingCompanyPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersWarehousingCompaniesContactsListKey(warehousingCompanyPk,params) : null);
  const swrFn = () => businessPartnersWarehousingCompaniesContactsList(warehousingCompanyPk,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersWarehousingCompaniesContactsCreate = (
    warehousingCompanyPk: number,
    contactRequest: ContactRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Contact>(
      {url: `/api/v1/business-partners/warehousing-companies/${warehousingCompanyPk}/contacts/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: contactRequest
    },
      options);
    }
  


export const businessPartnersWarehousingCompaniesContactsRetrieve = (
    warehousingCompanyPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Contact>(
      {url: `/api/v1/business-partners/warehousing-companies/${warehousingCompanyPk}/contacts/${id}/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersWarehousingCompaniesContactsRetrieveKey = (warehousingCompanyPk: number,
    id: number,) => [`/api/v1/business-partners/warehousing-companies/${warehousingCompanyPk}/contacts/${id}/`] as const;

    
export type BusinessPartnersWarehousingCompaniesContactsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersWarehousingCompaniesContactsRetrieve>>>
export type BusinessPartnersWarehousingCompaniesContactsRetrieveQueryError = unknown

export const useBusinessPartnersWarehousingCompaniesContactsRetrieve = <TError = unknown>(
 warehousingCompanyPk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersWarehousingCompaniesContactsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(warehousingCompanyPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersWarehousingCompaniesContactsRetrieveKey(warehousingCompanyPk,id) : null);
  const swrFn = () => businessPartnersWarehousingCompaniesContactsRetrieve(warehousingCompanyPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersWarehousingCompaniesContactsUpdate = (
    warehousingCompanyPk: number,
    id: number,
    contactRequest: ContactRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Contact>(
      {url: `/api/v1/business-partners/warehousing-companies/${warehousingCompanyPk}/contacts/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: contactRequest
    },
      options);
    }
  


export const businessPartnersWarehousingCompaniesContactsPartialUpdate = (
    warehousingCompanyPk: number,
    id: number,
    patchedContactRequest: PatchedContactRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Contact>(
      {url: `/api/v1/business-partners/warehousing-companies/${warehousingCompanyPk}/contacts/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedContactRequest
    },
      options);
    }
  


export const businessPartnersWarehousingCompaniesContactsDestroy = (
    warehousingCompanyPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/business-partners/warehousing-companies/${warehousingCompanyPk}/contacts/${id}/`, method: 'delete'
    },
      options);
    }
  


export const businessPartnersWarehousingCompaniesWarehousesList = (
    warehousingCompanyPk: number,
    params?: BusinessPartnersWarehousingCompaniesWarehousesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Warehouse[]>(
      {url: `/api/v1/business-partners/warehousing-companies/${warehousingCompanyPk}/warehouses/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersWarehousingCompaniesWarehousesListKey = (warehousingCompanyPk: number,
    params?: BusinessPartnersWarehousingCompaniesWarehousesListParams,) => [`/api/v1/business-partners/warehousing-companies/${warehousingCompanyPk}/warehouses/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersWarehousingCompaniesWarehousesListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersWarehousingCompaniesWarehousesList>>>
export type BusinessPartnersWarehousingCompaniesWarehousesListQueryError = unknown

export const useBusinessPartnersWarehousingCompaniesWarehousesList = <TError = unknown>(
 warehousingCompanyPk: number,
    params?: BusinessPartnersWarehousingCompaniesWarehousesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersWarehousingCompaniesWarehousesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(warehousingCompanyPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersWarehousingCompaniesWarehousesListKey(warehousingCompanyPk,params) : null);
  const swrFn = () => businessPartnersWarehousingCompaniesWarehousesList(warehousingCompanyPk,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersWarehousingCompaniesWarehousesCreate = (
    warehousingCompanyPk: number,
    warehouseRequest: WarehouseRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Warehouse>(
      {url: `/api/v1/business-partners/warehousing-companies/${warehousingCompanyPk}/warehouses/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: warehouseRequest
    },
      options);
    }
  


export const businessPartnersWarehousingCompaniesWarehousesRetrieve = (
    warehousingCompanyPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Warehouse>(
      {url: `/api/v1/business-partners/warehousing-companies/${warehousingCompanyPk}/warehouses/${id}/`, method: 'get'
    },
      options);
    }
  

export const getBusinessPartnersWarehousingCompaniesWarehousesRetrieveKey = (warehousingCompanyPk: number,
    id: number,) => [`/api/v1/business-partners/warehousing-companies/${warehousingCompanyPk}/warehouses/${id}/`] as const;

    
export type BusinessPartnersWarehousingCompaniesWarehousesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersWarehousingCompaniesWarehousesRetrieve>>>
export type BusinessPartnersWarehousingCompaniesWarehousesRetrieveQueryError = unknown

export const useBusinessPartnersWarehousingCompaniesWarehousesRetrieve = <TError = unknown>(
 warehousingCompanyPk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersWarehousingCompaniesWarehousesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(warehousingCompanyPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersWarehousingCompaniesWarehousesRetrieveKey(warehousingCompanyPk,id) : null);
  const swrFn = () => businessPartnersWarehousingCompaniesWarehousesRetrieve(warehousingCompanyPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const businessPartnersWarehousingCompaniesWarehousesUpdate = (
    warehousingCompanyPk: number,
    id: number,
    warehouseRequest: WarehouseRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Warehouse>(
      {url: `/api/v1/business-partners/warehousing-companies/${warehousingCompanyPk}/warehouses/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: warehouseRequest
    },
      options);
    }
  


export const businessPartnersWarehousingCompaniesWarehousesPartialUpdate = (
    warehousingCompanyPk: number,
    id: number,
    patchedWarehouseRequest: PatchedWarehouseRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Warehouse>(
      {url: `/api/v1/business-partners/warehousing-companies/${warehousingCompanyPk}/warehouses/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedWarehouseRequest
    },
      options);
    }
  


export const businessPartnersWarehousingCompaniesWarehousesDestroy = (
    warehousingCompanyPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/business-partners/warehousing-companies/${warehousingCompanyPk}/warehouses/${id}/`, method: 'delete'
    },
      options);
    }
  


export const businessPartnersWarehousingCompaniesPaginatedList = (
    params?: BusinessPartnersWarehousingCompaniesPaginatedListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedWarehousingCompaniesList>(
      {url: `/api/v1/business-partners/warehousing-companies/paginated/`, method: 'get',
        params
    },
      options);
    }
  

export const getBusinessPartnersWarehousingCompaniesPaginatedListKey = (params?: BusinessPartnersWarehousingCompaniesPaginatedListParams,) => [`/api/v1/business-partners/warehousing-companies/paginated/`, ...(params ? [params]: [])] as const;

    
export type BusinessPartnersWarehousingCompaniesPaginatedListQueryResult = NonNullable<Awaited<ReturnType<typeof businessPartnersWarehousingCompaniesPaginatedList>>>
export type BusinessPartnersWarehousingCompaniesPaginatedListQueryError = unknown

export const useBusinessPartnersWarehousingCompaniesPaginatedList = <TError = unknown>(
 params?: BusinessPartnersWarehousingCompaniesPaginatedListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof businessPartnersWarehousingCompaniesPaginatedList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBusinessPartnersWarehousingCompaniesPaginatedListKey(params) : null);
  const swrFn = () => businessPartnersWarehousingCompaniesPaginatedList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const chatList = (
    params?: ChatListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Chat[]>(
      {url: `/api/v1/chat/`, method: 'get',
        params
    },
      options);
    }
  

export const getChatListKey = (params?: ChatListParams,) => [`/api/v1/chat/`, ...(params ? [params]: [])] as const;

    
export type ChatListQueryResult = NonNullable<Awaited<ReturnType<typeof chatList>>>
export type ChatListQueryError = unknown

export const useChatList = <TError = unknown>(
 params?: ChatListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof chatList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getChatListKey(params) : null);
  const swrFn = () => chatList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const chatCreate = (
    chatRequest: ChatRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Chat>(
      {url: `/api/v1/chat/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: chatRequest
    },
      options);
    }
  


export const chatRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Chat>(
      {url: `/api/v1/chat/${id}/`, method: 'get'
    },
      options);
    }
  

export const getChatRetrieveKey = (id: number,) => [`/api/v1/chat/${id}/`] as const;

    
export type ChatRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof chatRetrieve>>>
export type ChatRetrieveQueryError = unknown

export const useChatRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof chatRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getChatRetrieveKey(id) : null);
  const swrFn = () => chatRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const chatUpdate = (
    id: number,
    chatRequest: ChatRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Chat>(
      {url: `/api/v1/chat/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: chatRequest
    },
      options);
    }
  


export const chatPartialUpdate = (
    id: number,
    patchedChatRequest: PatchedChatRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Chat>(
      {url: `/api/v1/chat/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedChatRequest
    },
      options);
    }
  


export const chatDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/chat/${id}/`, method: 'delete'
    },
      options);
    }
  


export const chatRoomsList = (
    params?: ChatRoomsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ChatRoom[]>(
      {url: `/api/v1/chat/rooms/`, method: 'get',
        params
    },
      options);
    }
  

export const getChatRoomsListKey = (params?: ChatRoomsListParams,) => [`/api/v1/chat/rooms/`, ...(params ? [params]: [])] as const;

    
export type ChatRoomsListQueryResult = NonNullable<Awaited<ReturnType<typeof chatRoomsList>>>
export type ChatRoomsListQueryError = unknown

export const useChatRoomsList = <TError = unknown>(
 params?: ChatRoomsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof chatRoomsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getChatRoomsListKey(params) : null);
  const swrFn = () => chatRoomsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const chatRoomsCreate = (
    chatRoomRequest: ChatRoomRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ChatRoom>(
      {url: `/api/v1/chat/rooms/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: chatRoomRequest
    },
      options);
    }
  


export const chatRoomsUpdate = (
    id: number,
    chatRoomRequest: ChatRoomRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ChatRoom>(
      {url: `/api/v1/chat/rooms/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: chatRoomRequest
    },
      options);
    }
  


export const chatRoomsPartialUpdate = (
    id: number,
    patchedChatRoomRequest: PatchedChatRoomRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ChatRoom>(
      {url: `/api/v1/chat/rooms/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedChatRoomRequest
    },
      options);
    }
  


export const chatRoomsDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/chat/rooms/${id}/`, method: 'delete'
    },
      options);
    }
  


export const chatRoomsReadCreate = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/chat/rooms/${id}/read/`, method: 'post'
    },
      options);
    }
  


export const chatRoomsUnreadList = (
    params?: ChatRoomsUnreadListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UnreadChatRoom[]>(
      {url: `/api/v1/chat/rooms/unread/`, method: 'get',
        params
    },
      options);
    }
  

export const getChatRoomsUnreadListKey = (params?: ChatRoomsUnreadListParams,) => [`/api/v1/chat/rooms/unread/`, ...(params ? [params]: [])] as const;

    
export type ChatRoomsUnreadListQueryResult = NonNullable<Awaited<ReturnType<typeof chatRoomsUnreadList>>>
export type ChatRoomsUnreadListQueryError = unknown

export const useChatRoomsUnreadList = <TError = unknown>(
 params?: ChatRoomsUnreadListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof chatRoomsUnreadList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getChatRoomsUnreadListKey(params) : null);
  const swrFn = () => chatRoomsUnreadList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const contactsList = (
    params?: ContactsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Contact[]>(
      {url: `/api/v1/contacts/`, method: 'get',
        params
    },
      options);
    }
  

export const getContactsListKey = (params?: ContactsListParams,) => [`/api/v1/contacts/`, ...(params ? [params]: [])] as const;

    
export type ContactsListQueryResult = NonNullable<Awaited<ReturnType<typeof contactsList>>>
export type ContactsListQueryError = unknown

export const useContactsList = <TError = unknown>(
 params?: ContactsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof contactsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getContactsListKey(params) : null);
  const swrFn = () => contactsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const contactsCreate = (
    contactRequest: ContactRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Contact>(
      {url: `/api/v1/contacts/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: contactRequest
    },
      options);
    }
  


export const contactsRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Contact>(
      {url: `/api/v1/contacts/${id}/`, method: 'get'
    },
      options);
    }
  

export const getContactsRetrieveKey = (id: number,) => [`/api/v1/contacts/${id}/`] as const;

    
export type ContactsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof contactsRetrieve>>>
export type ContactsRetrieveQueryError = unknown

export const useContactsRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof contactsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getContactsRetrieveKey(id) : null);
  const swrFn = () => contactsRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const contactsUpdate = (
    id: number,
    contactRequest: ContactRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Contact>(
      {url: `/api/v1/contacts/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: contactRequest
    },
      options);
    }
  


export const contactsPartialUpdate = (
    id: number,
    patchedContactRequest: PatchedContactRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Contact>(
      {url: `/api/v1/contacts/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedContactRequest
    },
      options);
    }
  


export const contactsDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/contacts/${id}/`, method: 'delete'
    },
      options);
    }
  


export const customsClearanceGoodsList = (
    params?: CustomsClearanceGoodsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsGoodsInformation[]>(
      {url: `/api/v1/customs-clearance/goods/`, method: 'get',
        params
    },
      options);
    }
  

export const getCustomsClearanceGoodsListKey = (params?: CustomsClearanceGoodsListParams,) => [`/api/v1/customs-clearance/goods/`, ...(params ? [params]: [])] as const;

    
export type CustomsClearanceGoodsListQueryResult = NonNullable<Awaited<ReturnType<typeof customsClearanceGoodsList>>>
export type CustomsClearanceGoodsListQueryError = unknown

export const useCustomsClearanceGoodsList = <TError = unknown>(
 params?: CustomsClearanceGoodsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof customsClearanceGoodsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getCustomsClearanceGoodsListKey(params) : null);
  const swrFn = () => customsClearanceGoodsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const customsClearanceGoodsCreate = (
    customsGoodsInformationRequest: CustomsGoodsInformationRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsGoodsInformation>(
      {url: `/api/v1/customs-clearance/goods/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: customsGoodsInformationRequest
    },
      options);
    }
  


export const customsClearanceGoodsRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsGoodsInformation>(
      {url: `/api/v1/customs-clearance/goods/${id}/`, method: 'get'
    },
      options);
    }
  

export const getCustomsClearanceGoodsRetrieveKey = (id: number,) => [`/api/v1/customs-clearance/goods/${id}/`] as const;

    
export type CustomsClearanceGoodsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof customsClearanceGoodsRetrieve>>>
export type CustomsClearanceGoodsRetrieveQueryError = unknown

export const useCustomsClearanceGoodsRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof customsClearanceGoodsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getCustomsClearanceGoodsRetrieveKey(id) : null);
  const swrFn = () => customsClearanceGoodsRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const customsClearanceGoodsUpdate = (
    id: number,
    customsGoodsInformationRequest: CustomsGoodsInformationRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsGoodsInformation>(
      {url: `/api/v1/customs-clearance/goods/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: customsGoodsInformationRequest
    },
      options);
    }
  


export const customsClearanceGoodsPartialUpdate = (
    id: number,
    patchedCustomsGoodsInformationRequest: PatchedCustomsGoodsInformationRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsGoodsInformation>(
      {url: `/api/v1/customs-clearance/goods/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedCustomsGoodsInformationRequest
    },
      options);
    }
  


export const customsClearanceGoodsDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/customs-clearance/goods/${id}/`, method: 'delete'
    },
      options);
    }
  


export const customsClearanceGoodsPaginatedRetrieve = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsGoodsInformation>(
      {url: `/api/v1/customs-clearance/goods/paginated/`, method: 'get'
    },
      options);
    }
  

export const getCustomsClearanceGoodsPaginatedRetrieveKey = () => [`/api/v1/customs-clearance/goods/paginated/`] as const;

    
export type CustomsClearanceGoodsPaginatedRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof customsClearanceGoodsPaginatedRetrieve>>>
export type CustomsClearanceGoodsPaginatedRetrieveQueryError = unknown

export const useCustomsClearanceGoodsPaginatedRetrieve = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof customsClearanceGoodsPaginatedRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getCustomsClearanceGoodsPaginatedRetrieveKey() : null);
  const swrFn = () => customsClearanceGoodsPaginatedRetrieve(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const dashboardMapList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MapData[]>(
      {url: `/api/v1/dashboard/map/`, method: 'get'
    },
      options);
    }
  

export const getDashboardMapListKey = () => [`/api/v1/dashboard/map/`] as const;

    
export type DashboardMapListQueryResult = NonNullable<Awaited<ReturnType<typeof dashboardMapList>>>
export type DashboardMapListQueryError = unknown

export const useDashboardMapList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof dashboardMapList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDashboardMapListKey() : null);
  const swrFn = () => dashboardMapList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradeArrivalNoticesList = (
    params?: DocumentsForTradeArrivalNoticesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ArrivalNotices[]>(
      {url: `/api/v1/documents-for-trade/arrival-notices/`, method: 'get',
        params
    },
      options);
    }
  

export const getDocumentsForTradeArrivalNoticesListKey = (params?: DocumentsForTradeArrivalNoticesListParams,) => [`/api/v1/documents-for-trade/arrival-notices/`, ...(params ? [params]: [])] as const;

    
export type DocumentsForTradeArrivalNoticesListQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradeArrivalNoticesList>>>
export type DocumentsForTradeArrivalNoticesListQueryError = unknown

export const useDocumentsForTradeArrivalNoticesList = <TError = unknown>(
 params?: DocumentsForTradeArrivalNoticesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradeArrivalNoticesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradeArrivalNoticesListKey(params) : null);
  const swrFn = () => documentsForTradeArrivalNoticesList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradeArrivalNoticesReportsList = (
    arrivalNoticePk: string,
    params?: DocumentsForTradeArrivalNoticesReportsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingReport[]>(
      {url: `/api/v1/documents-for-trade/arrival-notices/${arrivalNoticePk}/reports/`, method: 'get',
        params
    },
      options);
    }
  

export const getDocumentsForTradeArrivalNoticesReportsListKey = (arrivalNoticePk: string,
    params?: DocumentsForTradeArrivalNoticesReportsListParams,) => [`/api/v1/documents-for-trade/arrival-notices/${arrivalNoticePk}/reports/`, ...(params ? [params]: [])] as const;

    
export type DocumentsForTradeArrivalNoticesReportsListQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradeArrivalNoticesReportsList>>>
export type DocumentsForTradeArrivalNoticesReportsListQueryError = unknown

export const useDocumentsForTradeArrivalNoticesReportsList = <TError = unknown>(
 arrivalNoticePk: string,
    params?: DocumentsForTradeArrivalNoticesReportsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradeArrivalNoticesReportsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(arrivalNoticePk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradeArrivalNoticesReportsListKey(arrivalNoticePk,params) : null);
  const swrFn = () => documentsForTradeArrivalNoticesReportsList(arrivalNoticePk,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradeArrivalNoticesReportsCreate = (
    arrivalNoticePk: string,
    accountingReportRequest: AccountingReportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingReport>(
      {url: `/api/v1/documents-for-trade/arrival-notices/${arrivalNoticePk}/reports/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: accountingReportRequest
    },
      options);
    }
  


export const documentsForTradeArrivalNoticesReportsUpdate = (
    arrivalNoticePk: string,
    id: number,
    accountingReportRequest: AccountingReportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingReport>(
      {url: `/api/v1/documents-for-trade/arrival-notices/${arrivalNoticePk}/reports/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: accountingReportRequest
    },
      options);
    }
  


export const documentsForTradeArrivalNoticesReportsPartialUpdate = (
    arrivalNoticePk: string,
    id: number,
    patchedAccountingReportRequest: PatchedAccountingReportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingReport>(
      {url: `/api/v1/documents-for-trade/arrival-notices/${arrivalNoticePk}/reports/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedAccountingReportRequest
    },
      options);
    }
  


export const documentsForTradeArrivalNoticesReportsDetailsCreate = (
    arrivalNoticePk: string,
    reportPk: number,
    byArrivalNoticeRequest: ByArrivalNoticeRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ByArrivalNotice>(
      {url: `/api/v1/documents-for-trade/arrival-notices/${arrivalNoticePk}/reports/${reportPk}/details/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: byArrivalNoticeRequest
    },
      options);
    }
  


export const documentsForTradeArrivalNoticesRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ArrivalNotices>(
      {url: `/api/v1/documents-for-trade/arrival-notices/${id}/`, method: 'get'
    },
      options);
    }
  

export const getDocumentsForTradeArrivalNoticesRetrieveKey = (id: number,) => [`/api/v1/documents-for-trade/arrival-notices/${id}/`] as const;

    
export type DocumentsForTradeArrivalNoticesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradeArrivalNoticesRetrieve>>>
export type DocumentsForTradeArrivalNoticesRetrieveQueryError = unknown

export const useDocumentsForTradeArrivalNoticesRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradeArrivalNoticesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradeArrivalNoticesRetrieveKey(id) : null);
  const swrFn = () => documentsForTradeArrivalNoticesRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradeArrivalNoticesLimitedList = (
    params?: DocumentsForTradeArrivalNoticesLimitedListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ArrivalNotices[]>(
      {url: `/api/v1/documents-for-trade/arrival-notices/limited/`, method: 'get',
        params
    },
      options);
    }
  

export const getDocumentsForTradeArrivalNoticesLimitedListKey = (params?: DocumentsForTradeArrivalNoticesLimitedListParams,) => [`/api/v1/documents-for-trade/arrival-notices/limited/`, ...(params ? [params]: [])] as const;

    
export type DocumentsForTradeArrivalNoticesLimitedListQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradeArrivalNoticesLimitedList>>>
export type DocumentsForTradeArrivalNoticesLimitedListQueryError = unknown

export const useDocumentsForTradeArrivalNoticesLimitedList = <TError = unknown>(
 params?: DocumentsForTradeArrivalNoticesLimitedListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradeArrivalNoticesLimitedList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradeArrivalNoticesLimitedListKey(params) : null);
  const swrFn = () => documentsForTradeArrivalNoticesLimitedList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradeBookingNoteList = (
    params?: DocumentsForTradeBookingNoteListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BookingNote[]>(
      {url: `/api/v1/documents-for-trade/booking-note/`, method: 'get',
        params
    },
      options);
    }
  

export const getDocumentsForTradeBookingNoteListKey = (params?: DocumentsForTradeBookingNoteListParams,) => [`/api/v1/documents-for-trade/booking-note/`, ...(params ? [params]: [])] as const;

    
export type DocumentsForTradeBookingNoteListQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradeBookingNoteList>>>
export type DocumentsForTradeBookingNoteListQueryError = unknown

export const useDocumentsForTradeBookingNoteList = <TError = unknown>(
 params?: DocumentsForTradeBookingNoteListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradeBookingNoteList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradeBookingNoteListKey(params) : null);
  const swrFn = () => documentsForTradeBookingNoteList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradeBookingNoteRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BookingNote>(
      {url: `/api/v1/documents-for-trade/booking-note/${id}/`, method: 'get'
    },
      options);
    }
  

export const getDocumentsForTradeBookingNoteRetrieveKey = (id: number,) => [`/api/v1/documents-for-trade/booking-note/${id}/`] as const;

    
export type DocumentsForTradeBookingNoteRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradeBookingNoteRetrieve>>>
export type DocumentsForTradeBookingNoteRetrieveQueryError = unknown

export const useDocumentsForTradeBookingNoteRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradeBookingNoteRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradeBookingNoteRetrieveKey(id) : null);
  const swrFn = () => documentsForTradeBookingNoteRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradeBookingNoteLimitedList = (
    params?: DocumentsForTradeBookingNoteLimitedListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BookingNote[]>(
      {url: `/api/v1/documents-for-trade/booking-note/limited/`, method: 'get',
        params
    },
      options);
    }
  

export const getDocumentsForTradeBookingNoteLimitedListKey = (params?: DocumentsForTradeBookingNoteLimitedListParams,) => [`/api/v1/documents-for-trade/booking-note/limited/`, ...(params ? [params]: [])] as const;

    
export type DocumentsForTradeBookingNoteLimitedListQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradeBookingNoteLimitedList>>>
export type DocumentsForTradeBookingNoteLimitedListQueryError = unknown

export const useDocumentsForTradeBookingNoteLimitedList = <TError = unknown>(
 params?: DocumentsForTradeBookingNoteLimitedListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradeBookingNoteLimitedList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradeBookingNoteLimitedListKey(params) : null);
  const swrFn = () => documentsForTradeBookingNoteLimitedList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradeCompleteMultipartUploadCreate = (
    multipartUploadRequest: MultipartUploadRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/documents-for-trade/complete-multipart-upload/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: multipartUploadRequest
    },
      options);
    }
  


export const documentsForTradeCustomsClearanceRequestList = (
    params?: DocumentsForTradeCustomsClearanceRequestListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsClearanceRequest[]>(
      {url: `/api/v1/documents-for-trade/customs-clearance-request/`, method: 'get',
        params
    },
      options);
    }
  

export const getDocumentsForTradeCustomsClearanceRequestListKey = (params?: DocumentsForTradeCustomsClearanceRequestListParams,) => [`/api/v1/documents-for-trade/customs-clearance-request/`, ...(params ? [params]: [])] as const;

    
export type DocumentsForTradeCustomsClearanceRequestListQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradeCustomsClearanceRequestList>>>
export type DocumentsForTradeCustomsClearanceRequestListQueryError = unknown

export const useDocumentsForTradeCustomsClearanceRequestList = <TError = unknown>(
 params?: DocumentsForTradeCustomsClearanceRequestListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradeCustomsClearanceRequestList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradeCustomsClearanceRequestListKey(params) : null);
  const swrFn = () => documentsForTradeCustomsClearanceRequestList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradeCustomsClearanceRequestRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsClearanceRequest>(
      {url: `/api/v1/documents-for-trade/customs-clearance-request/${id}/`, method: 'get'
    },
      options);
    }
  

export const getDocumentsForTradeCustomsClearanceRequestRetrieveKey = (id: number,) => [`/api/v1/documents-for-trade/customs-clearance-request/${id}/`] as const;

    
export type DocumentsForTradeCustomsClearanceRequestRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradeCustomsClearanceRequestRetrieve>>>
export type DocumentsForTradeCustomsClearanceRequestRetrieveQueryError = unknown

export const useDocumentsForTradeCustomsClearanceRequestRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradeCustomsClearanceRequestRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradeCustomsClearanceRequestRetrieveKey(id) : null);
  const swrFn = () => documentsForTradeCustomsClearanceRequestRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradeCustomsClearanceRequestLimitedList = (
    params?: DocumentsForTradeCustomsClearanceRequestLimitedListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsClearanceRequest[]>(
      {url: `/api/v1/documents-for-trade/customs-clearance-request/limited/`, method: 'get',
        params
    },
      options);
    }
  

export const getDocumentsForTradeCustomsClearanceRequestLimitedListKey = (params?: DocumentsForTradeCustomsClearanceRequestLimitedListParams,) => [`/api/v1/documents-for-trade/customs-clearance-request/limited/`, ...(params ? [params]: [])] as const;

    
export type DocumentsForTradeCustomsClearanceRequestLimitedListQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradeCustomsClearanceRequestLimitedList>>>
export type DocumentsForTradeCustomsClearanceRequestLimitedListQueryError = unknown

export const useDocumentsForTradeCustomsClearanceRequestLimitedList = <TError = unknown>(
 params?: DocumentsForTradeCustomsClearanceRequestLimitedListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradeCustomsClearanceRequestLimitedList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradeCustomsClearanceRequestLimitedListKey(params) : null);
  const swrFn = () => documentsForTradeCustomsClearanceRequestLimitedList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradeDocumentsList = (
    params?: DocumentsForTradeDocumentsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<DocumentsForTrade[]>(
      {url: `/api/v1/documents-for-trade/documents/`, method: 'get',
        params
    },
      options);
    }
  

export const getDocumentsForTradeDocumentsListKey = (params?: DocumentsForTradeDocumentsListParams,) => [`/api/v1/documents-for-trade/documents/`, ...(params ? [params]: [])] as const;

    
export type DocumentsForTradeDocumentsListQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradeDocumentsList>>>
export type DocumentsForTradeDocumentsListQueryError = unknown

export const useDocumentsForTradeDocumentsList = <TError = unknown>(
 params?: DocumentsForTradeDocumentsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradeDocumentsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradeDocumentsListKey(params) : null);
  const swrFn = () => documentsForTradeDocumentsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradeDocumentsCreate = (
    documentsForTradeRequest: DocumentsForTradeRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<DocumentsForTrade>(
      {url: `/api/v1/documents-for-trade/documents/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: documentsForTradeRequest
    },
      options);
    }
  


export const documentsForTradeDocumentsVersionsDestroy = (
    documentsPk: string,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/documents-for-trade/documents/${documentsPk}/versions/${id}/`, method: 'delete'
    },
      options);
    }
  


export const documentsForTradeDocumentsRetrieve = (
    documentsForTradeId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<DocumentsForTrade>(
      {url: `/api/v1/documents-for-trade/documents/${documentsForTradeId}/`, method: 'get'
    },
      options);
    }
  

export const getDocumentsForTradeDocumentsRetrieveKey = (documentsForTradeId: number,) => [`/api/v1/documents-for-trade/documents/${documentsForTradeId}/`] as const;

    
export type DocumentsForTradeDocumentsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradeDocumentsRetrieve>>>
export type DocumentsForTradeDocumentsRetrieveQueryError = unknown

export const useDocumentsForTradeDocumentsRetrieve = <TError = unknown>(
 documentsForTradeId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradeDocumentsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(documentsForTradeId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradeDocumentsRetrieveKey(documentsForTradeId) : null);
  const swrFn = () => documentsForTradeDocumentsRetrieve(documentsForTradeId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradeDocumentsUpdate = (
    documentsForTradeId: number,
    documentsForTradeRequest: DocumentsForTradeRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<DocumentsForTrade>(
      {url: `/api/v1/documents-for-trade/documents/${documentsForTradeId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: documentsForTradeRequest
    },
      options);
    }
  


export const documentsForTradeDocumentsPartialUpdate = (
    documentsForTradeId: number,
    patchedDocumentsForTradeRequest: PatchedDocumentsForTradeRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<DocumentsForTrade>(
      {url: `/api/v1/documents-for-trade/documents/${documentsForTradeId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedDocumentsForTradeRequest
    },
      options);
    }
  


export const documentsForTradeDocumentsDestroy = (
    documentsForTradeId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/documents-for-trade/documents/${documentsForTradeId}/`, method: 'delete'
    },
      options);
    }
  


/**
 * @deprecated
 */
export const documentsForTradeDocumentsPermissionsUpdate = (
    documentsForTradeId: number,
    collaboratorRequest: CollaboratorRequest[],
    params?: DocumentsForTradeDocumentsPermissionsUpdateParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Collaborator[]>(
      {url: `/api/v1/documents-for-trade/documents/${documentsForTradeId}/permissions/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: collaboratorRequest,
        params
    },
      options);
    }
  


export const documentsForTradeDocumentsRequestWithdrawalCreate = (
    documentsForTradeId: number,
    messageRequest: MessageRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Message>(
      {url: `/api/v1/documents-for-trade/documents/${documentsForTradeId}/request-withdrawal/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: messageRequest
    },
      options);
    }
  


export const documentsForTradeDocumentsCompleteMultipartUploadCreate = (
    multipartUploadRequest: MultipartUploadRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/documents-for-trade/documents/complete_multipart_upload/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: multipartUploadRequest
    },
      options);
    }
  


export const documentsForTradeMultipartUploadPresignedUrlCreate = (
    multiPartUploadPresignedUrlRequest: MultiPartUploadPresignedUrlRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MultiPartUploadPresignedUrl>(
      {url: `/api/v1/documents-for-trade/multipart-upload-presigned-url/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: multiPartUploadPresignedUrlRequest
    },
      options);
    }
  


export const documentsForTradePortrichBillOfLadingsList = (
    params?: DocumentsForTradePortrichBillOfLadingsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PortrichBillOfLadings[]>(
      {url: `/api/v1/documents-for-trade/portrich-bill-of-ladings/`, method: 'get',
        params
    },
      options);
    }
  

export const getDocumentsForTradePortrichBillOfLadingsListKey = (params?: DocumentsForTradePortrichBillOfLadingsListParams,) => [`/api/v1/documents-for-trade/portrich-bill-of-ladings/`, ...(params ? [params]: [])] as const;

    
export type DocumentsForTradePortrichBillOfLadingsListQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradePortrichBillOfLadingsList>>>
export type DocumentsForTradePortrichBillOfLadingsListQueryError = unknown

export const useDocumentsForTradePortrichBillOfLadingsList = <TError = unknown>(
 params?: DocumentsForTradePortrichBillOfLadingsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradePortrichBillOfLadingsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradePortrichBillOfLadingsListKey(params) : null);
  const swrFn = () => documentsForTradePortrichBillOfLadingsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradePortrichBillOfLadingsRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PortrichBillOfLadings>(
      {url: `/api/v1/documents-for-trade/portrich-bill-of-ladings/${id}/`, method: 'get'
    },
      options);
    }
  

export const getDocumentsForTradePortrichBillOfLadingsRetrieveKey = (id: number,) => [`/api/v1/documents-for-trade/portrich-bill-of-ladings/${id}/`] as const;

    
export type DocumentsForTradePortrichBillOfLadingsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradePortrichBillOfLadingsRetrieve>>>
export type DocumentsForTradePortrichBillOfLadingsRetrieveQueryError = unknown

export const useDocumentsForTradePortrichBillOfLadingsRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradePortrichBillOfLadingsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradePortrichBillOfLadingsRetrieveKey(id) : null);
  const swrFn = () => documentsForTradePortrichBillOfLadingsRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradePortrichBillOfLadingsLimitedList = (
    params?: DocumentsForTradePortrichBillOfLadingsLimitedListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PortrichBillOfLadings[]>(
      {url: `/api/v1/documents-for-trade/portrich-bill-of-ladings/limited/`, method: 'get',
        params
    },
      options);
    }
  

export const getDocumentsForTradePortrichBillOfLadingsLimitedListKey = (params?: DocumentsForTradePortrichBillOfLadingsLimitedListParams,) => [`/api/v1/documents-for-trade/portrich-bill-of-ladings/limited/`, ...(params ? [params]: [])] as const;

    
export type DocumentsForTradePortrichBillOfLadingsLimitedListQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradePortrichBillOfLadingsLimitedList>>>
export type DocumentsForTradePortrichBillOfLadingsLimitedListQueryError = unknown

export const useDocumentsForTradePortrichBillOfLadingsLimitedList = <TError = unknown>(
 params?: DocumentsForTradePortrichBillOfLadingsLimitedListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradePortrichBillOfLadingsLimitedList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradePortrichBillOfLadingsLimitedListKey(params) : null);
  const swrFn = () => documentsForTradePortrichBillOfLadingsLimitedList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradePresignedUrlCreate = (
    presignedUrlRequest: PresignedUrlRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PresignedUrl>(
      {url: `/api/v1/documents-for-trade/presigned-url/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: presignedUrlRequest
    },
      options);
    }
  


export const documentsForTradeShippingInstructionsList = (
    params?: DocumentsForTradeShippingInstructionsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShippingInstructions[]>(
      {url: `/api/v1/documents-for-trade/shipping-instructions/`, method: 'get',
        params
    },
      options);
    }
  

export const getDocumentsForTradeShippingInstructionsListKey = (params?: DocumentsForTradeShippingInstructionsListParams,) => [`/api/v1/documents-for-trade/shipping-instructions/`, ...(params ? [params]: [])] as const;

    
export type DocumentsForTradeShippingInstructionsListQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradeShippingInstructionsList>>>
export type DocumentsForTradeShippingInstructionsListQueryError = unknown

export const useDocumentsForTradeShippingInstructionsList = <TError = unknown>(
 params?: DocumentsForTradeShippingInstructionsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradeShippingInstructionsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradeShippingInstructionsListKey(params) : null);
  const swrFn = () => documentsForTradeShippingInstructionsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradeShippingInstructionsRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShippingInstructions>(
      {url: `/api/v1/documents-for-trade/shipping-instructions/${id}/`, method: 'get'
    },
      options);
    }
  

export const getDocumentsForTradeShippingInstructionsRetrieveKey = (id: number,) => [`/api/v1/documents-for-trade/shipping-instructions/${id}/`] as const;

    
export type DocumentsForTradeShippingInstructionsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradeShippingInstructionsRetrieve>>>
export type DocumentsForTradeShippingInstructionsRetrieveQueryError = unknown

export const useDocumentsForTradeShippingInstructionsRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradeShippingInstructionsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradeShippingInstructionsRetrieveKey(id) : null);
  const swrFn = () => documentsForTradeShippingInstructionsRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const documentsForTradeShippingInstructionsLimitedList = (
    params?: DocumentsForTradeShippingInstructionsLimitedListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShippingInstructions[]>(
      {url: `/api/v1/documents-for-trade/shipping-instructions/limited/`, method: 'get',
        params
    },
      options);
    }
  

export const getDocumentsForTradeShippingInstructionsLimitedListKey = (params?: DocumentsForTradeShippingInstructionsLimitedListParams,) => [`/api/v1/documents-for-trade/shipping-instructions/limited/`, ...(params ? [params]: [])] as const;

    
export type DocumentsForTradeShippingInstructionsLimitedListQueryResult = NonNullable<Awaited<ReturnType<typeof documentsForTradeShippingInstructionsLimitedList>>>
export type DocumentsForTradeShippingInstructionsLimitedListQueryError = unknown

export const useDocumentsForTradeShippingInstructionsLimitedList = <TError = unknown>(
 params?: DocumentsForTradeShippingInstructionsLimitedListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof documentsForTradeShippingInstructionsLimitedList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getDocumentsForTradeShippingInstructionsLimitedListKey(params) : null);
  const swrFn = () => documentsForTradeShippingInstructionsLimitedList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const forwardingOperationsAxisFeeStatusList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AxisFeeStatus[]>(
      {url: `/api/v1/forwarding-operations/axis-fee-status/`, method: 'get'
    },
      options);
    }
  

export const getForwardingOperationsAxisFeeStatusListKey = () => [`/api/v1/forwarding-operations/axis-fee-status/`] as const;

    
export type ForwardingOperationsAxisFeeStatusListQueryResult = NonNullable<Awaited<ReturnType<typeof forwardingOperationsAxisFeeStatusList>>>
export type ForwardingOperationsAxisFeeStatusListQueryError = unknown

export const useForwardingOperationsAxisFeeStatusList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof forwardingOperationsAxisFeeStatusList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getForwardingOperationsAxisFeeStatusListKey() : null);
  const swrFn = () => forwardingOperationsAxisFeeStatusList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const forwardingOperationsAxisFeeStatusCreate = (
    axisFeeStatusRequest: AxisFeeStatusRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AxisFeeStatus>(
      {url: `/api/v1/forwarding-operations/axis-fee-status/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: axisFeeStatusRequest
    },
      options);
    }
  


export const forwardingOperationsAxisFeeStatusRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AxisFeeStatus>(
      {url: `/api/v1/forwarding-operations/axis-fee-status/${id}/`, method: 'get'
    },
      options);
    }
  

export const getForwardingOperationsAxisFeeStatusRetrieveKey = (id: number,) => [`/api/v1/forwarding-operations/axis-fee-status/${id}/`] as const;

    
export type ForwardingOperationsAxisFeeStatusRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof forwardingOperationsAxisFeeStatusRetrieve>>>
export type ForwardingOperationsAxisFeeStatusRetrieveQueryError = unknown

export const useForwardingOperationsAxisFeeStatusRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof forwardingOperationsAxisFeeStatusRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getForwardingOperationsAxisFeeStatusRetrieveKey(id) : null);
  const swrFn = () => forwardingOperationsAxisFeeStatusRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const forwardingOperationsAxisFeeStatusUpdate = (
    id: number,
    axisFeeStatusRequest: AxisFeeStatusRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AxisFeeStatus>(
      {url: `/api/v1/forwarding-operations/axis-fee-status/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: axisFeeStatusRequest
    },
      options);
    }
  


export const forwardingOperationsAxisFeeStatusPartialUpdate = (
    id: number,
    patchedAxisFeeStatusRequest: PatchedAxisFeeStatusRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AxisFeeStatus>(
      {url: `/api/v1/forwarding-operations/axis-fee-status/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedAxisFeeStatusRequest
    },
      options);
    }
  


export const forwardingOperationsAxisFeeStatusDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/forwarding-operations/axis-fee-status/${id}/`, method: 'delete'
    },
      options);
    }
  


export const forwardingOperationsGensetChassisAvailabilitiesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<GensetChassisAvailability[]>(
      {url: `/api/v1/forwarding-operations/genset-chassis-availabilities/`, method: 'get'
    },
      options);
    }
  

export const getForwardingOperationsGensetChassisAvailabilitiesListKey = () => [`/api/v1/forwarding-operations/genset-chassis-availabilities/`] as const;

    
export type ForwardingOperationsGensetChassisAvailabilitiesListQueryResult = NonNullable<Awaited<ReturnType<typeof forwardingOperationsGensetChassisAvailabilitiesList>>>
export type ForwardingOperationsGensetChassisAvailabilitiesListQueryError = unknown

export const useForwardingOperationsGensetChassisAvailabilitiesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof forwardingOperationsGensetChassisAvailabilitiesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getForwardingOperationsGensetChassisAvailabilitiesListKey() : null);
  const swrFn = () => forwardingOperationsGensetChassisAvailabilitiesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const forwardingOperationsGensetChassisAvailabilitiesCreate = (
    gensetChassisAvailabilityRequest: GensetChassisAvailabilityRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<GensetChassisAvailability>(
      {url: `/api/v1/forwarding-operations/genset-chassis-availabilities/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: gensetChassisAvailabilityRequest
    },
      options);
    }
  


export const forwardingOperationsGensetChassisAvailabilitiesRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<GensetChassisAvailability>(
      {url: `/api/v1/forwarding-operations/genset-chassis-availabilities/${id}/`, method: 'get'
    },
      options);
    }
  

export const getForwardingOperationsGensetChassisAvailabilitiesRetrieveKey = (id: number,) => [`/api/v1/forwarding-operations/genset-chassis-availabilities/${id}/`] as const;

    
export type ForwardingOperationsGensetChassisAvailabilitiesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof forwardingOperationsGensetChassisAvailabilitiesRetrieve>>>
export type ForwardingOperationsGensetChassisAvailabilitiesRetrieveQueryError = unknown

export const useForwardingOperationsGensetChassisAvailabilitiesRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof forwardingOperationsGensetChassisAvailabilitiesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getForwardingOperationsGensetChassisAvailabilitiesRetrieveKey(id) : null);
  const swrFn = () => forwardingOperationsGensetChassisAvailabilitiesRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const forwardingOperationsGensetChassisAvailabilitiesUpdate = (
    id: number,
    gensetChassisAvailabilityRequest: GensetChassisAvailabilityRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<GensetChassisAvailability>(
      {url: `/api/v1/forwarding-operations/genset-chassis-availabilities/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: gensetChassisAvailabilityRequest
    },
      options);
    }
  


export const forwardingOperationsGensetChassisAvailabilitiesPartialUpdate = (
    id: number,
    patchedGensetChassisAvailabilityRequest: PatchedGensetChassisAvailabilityRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<GensetChassisAvailability>(
      {url: `/api/v1/forwarding-operations/genset-chassis-availabilities/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedGensetChassisAvailabilityRequest
    },
      options);
    }
  


export const forwardingOperationsGensetChassisAvailabilitiesDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/forwarding-operations/genset-chassis-availabilities/${id}/`, method: 'delete'
    },
      options);
    }
  


export const forwardingOperationsInsuranceCoveredList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<InsuranceCovered[]>(
      {url: `/api/v1/forwarding-operations/insurance-covered/`, method: 'get'
    },
      options);
    }
  

export const getForwardingOperationsInsuranceCoveredListKey = () => [`/api/v1/forwarding-operations/insurance-covered/`] as const;

    
export type ForwardingOperationsInsuranceCoveredListQueryResult = NonNullable<Awaited<ReturnType<typeof forwardingOperationsInsuranceCoveredList>>>
export type ForwardingOperationsInsuranceCoveredListQueryError = unknown

export const useForwardingOperationsInsuranceCoveredList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof forwardingOperationsInsuranceCoveredList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getForwardingOperationsInsuranceCoveredListKey() : null);
  const swrFn = () => forwardingOperationsInsuranceCoveredList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const forwardingOperationsInsuranceCoveredCreate = (
    insuranceCoveredRequest: InsuranceCoveredRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<InsuranceCovered>(
      {url: `/api/v1/forwarding-operations/insurance-covered/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: insuranceCoveredRequest
    },
      options);
    }
  


export const forwardingOperationsInsuranceCoveredRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<InsuranceCovered>(
      {url: `/api/v1/forwarding-operations/insurance-covered/${id}/`, method: 'get'
    },
      options);
    }
  

export const getForwardingOperationsInsuranceCoveredRetrieveKey = (id: number,) => [`/api/v1/forwarding-operations/insurance-covered/${id}/`] as const;

    
export type ForwardingOperationsInsuranceCoveredRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof forwardingOperationsInsuranceCoveredRetrieve>>>
export type ForwardingOperationsInsuranceCoveredRetrieveQueryError = unknown

export const useForwardingOperationsInsuranceCoveredRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof forwardingOperationsInsuranceCoveredRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getForwardingOperationsInsuranceCoveredRetrieveKey(id) : null);
  const swrFn = () => forwardingOperationsInsuranceCoveredRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const forwardingOperationsInsuranceCoveredUpdate = (
    id: number,
    insuranceCoveredRequest: InsuranceCoveredRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<InsuranceCovered>(
      {url: `/api/v1/forwarding-operations/insurance-covered/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: insuranceCoveredRequest
    },
      options);
    }
  


export const forwardingOperationsInsuranceCoveredPartialUpdate = (
    id: number,
    patchedInsuranceCoveredRequest: PatchedInsuranceCoveredRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<InsuranceCovered>(
      {url: `/api/v1/forwarding-operations/insurance-covered/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedInsuranceCoveredRequest
    },
      options);
    }
  


export const forwardingOperationsInsuranceCoveredDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/forwarding-operations/insurance-covered/${id}/`, method: 'delete'
    },
      options);
    }
  


export const forwardingOperationsLetterOfAuthorizationStatusList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<LetterOfAuthorizationStatus[]>(
      {url: `/api/v1/forwarding-operations/letter-of-authorization-status/`, method: 'get'
    },
      options);
    }
  

export const getForwardingOperationsLetterOfAuthorizationStatusListKey = () => [`/api/v1/forwarding-operations/letter-of-authorization-status/`] as const;

    
export type ForwardingOperationsLetterOfAuthorizationStatusListQueryResult = NonNullable<Awaited<ReturnType<typeof forwardingOperationsLetterOfAuthorizationStatusList>>>
export type ForwardingOperationsLetterOfAuthorizationStatusListQueryError = unknown

export const useForwardingOperationsLetterOfAuthorizationStatusList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof forwardingOperationsLetterOfAuthorizationStatusList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getForwardingOperationsLetterOfAuthorizationStatusListKey() : null);
  const swrFn = () => forwardingOperationsLetterOfAuthorizationStatusList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const forwardingOperationsLetterOfAuthorizationStatusCreate = (
    letterOfAuthorizationStatusRequest: LetterOfAuthorizationStatusRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<LetterOfAuthorizationStatus>(
      {url: `/api/v1/forwarding-operations/letter-of-authorization-status/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: letterOfAuthorizationStatusRequest
    },
      options);
    }
  


export const forwardingOperationsLetterOfAuthorizationStatusRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<LetterOfAuthorizationStatus>(
      {url: `/api/v1/forwarding-operations/letter-of-authorization-status/${id}/`, method: 'get'
    },
      options);
    }
  

export const getForwardingOperationsLetterOfAuthorizationStatusRetrieveKey = (id: number,) => [`/api/v1/forwarding-operations/letter-of-authorization-status/${id}/`] as const;

    
export type ForwardingOperationsLetterOfAuthorizationStatusRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof forwardingOperationsLetterOfAuthorizationStatusRetrieve>>>
export type ForwardingOperationsLetterOfAuthorizationStatusRetrieveQueryError = unknown

export const useForwardingOperationsLetterOfAuthorizationStatusRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof forwardingOperationsLetterOfAuthorizationStatusRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getForwardingOperationsLetterOfAuthorizationStatusRetrieveKey(id) : null);
  const swrFn = () => forwardingOperationsLetterOfAuthorizationStatusRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const forwardingOperationsLetterOfAuthorizationStatusUpdate = (
    id: number,
    letterOfAuthorizationStatusRequest: LetterOfAuthorizationStatusRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<LetterOfAuthorizationStatus>(
      {url: `/api/v1/forwarding-operations/letter-of-authorization-status/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: letterOfAuthorizationStatusRequest
    },
      options);
    }
  


export const forwardingOperationsLetterOfAuthorizationStatusPartialUpdate = (
    id: number,
    patchedLetterOfAuthorizationStatusRequest: PatchedLetterOfAuthorizationStatusRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<LetterOfAuthorizationStatus>(
      {url: `/api/v1/forwarding-operations/letter-of-authorization-status/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedLetterOfAuthorizationStatusRequest
    },
      options);
    }
  


export const forwardingOperationsLetterOfAuthorizationStatusDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/forwarding-operations/letter-of-authorization-status/${id}/`, method: 'delete'
    },
      options);
    }
  


export const forwardingOperationsScheduleCommunicationOptionsList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ScheduleCommunicationOption[]>(
      {url: `/api/v1/forwarding-operations/schedule-communication-options/`, method: 'get'
    },
      options);
    }
  

export const getForwardingOperationsScheduleCommunicationOptionsListKey = () => [`/api/v1/forwarding-operations/schedule-communication-options/`] as const;

    
export type ForwardingOperationsScheduleCommunicationOptionsListQueryResult = NonNullable<Awaited<ReturnType<typeof forwardingOperationsScheduleCommunicationOptionsList>>>
export type ForwardingOperationsScheduleCommunicationOptionsListQueryError = unknown

export const useForwardingOperationsScheduleCommunicationOptionsList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof forwardingOperationsScheduleCommunicationOptionsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getForwardingOperationsScheduleCommunicationOptionsListKey() : null);
  const swrFn = () => forwardingOperationsScheduleCommunicationOptionsList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const forwardingOperationsScheduleCommunicationOptionsCreate = (
    scheduleCommunicationOptionRequest: ScheduleCommunicationOptionRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ScheduleCommunicationOption>(
      {url: `/api/v1/forwarding-operations/schedule-communication-options/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: scheduleCommunicationOptionRequest
    },
      options);
    }
  


export const forwardingOperationsScheduleCommunicationOptionsRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ScheduleCommunicationOption>(
      {url: `/api/v1/forwarding-operations/schedule-communication-options/${id}/`, method: 'get'
    },
      options);
    }
  

export const getForwardingOperationsScheduleCommunicationOptionsRetrieveKey = (id: number,) => [`/api/v1/forwarding-operations/schedule-communication-options/${id}/`] as const;

    
export type ForwardingOperationsScheduleCommunicationOptionsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof forwardingOperationsScheduleCommunicationOptionsRetrieve>>>
export type ForwardingOperationsScheduleCommunicationOptionsRetrieveQueryError = unknown

export const useForwardingOperationsScheduleCommunicationOptionsRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof forwardingOperationsScheduleCommunicationOptionsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getForwardingOperationsScheduleCommunicationOptionsRetrieveKey(id) : null);
  const swrFn = () => forwardingOperationsScheduleCommunicationOptionsRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const forwardingOperationsScheduleCommunicationOptionsUpdate = (
    id: number,
    scheduleCommunicationOptionRequest: ScheduleCommunicationOptionRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ScheduleCommunicationOption>(
      {url: `/api/v1/forwarding-operations/schedule-communication-options/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: scheduleCommunicationOptionRequest
    },
      options);
    }
  


export const forwardingOperationsScheduleCommunicationOptionsPartialUpdate = (
    id: number,
    patchedScheduleCommunicationOptionRequest: PatchedScheduleCommunicationOptionRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ScheduleCommunicationOption>(
      {url: `/api/v1/forwarding-operations/schedule-communication-options/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedScheduleCommunicationOptionRequest
    },
      options);
    }
  


export const forwardingOperationsScheduleCommunicationOptionsDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/forwarding-operations/schedule-communication-options/${id}/`, method: 'delete'
    },
      options);
    }
  


export const forwardingOperationsTransportationOptionsList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TransportationOption[]>(
      {url: `/api/v1/forwarding-operations/transportation-options/`, method: 'get'
    },
      options);
    }
  

export const getForwardingOperationsTransportationOptionsListKey = () => [`/api/v1/forwarding-operations/transportation-options/`] as const;

    
export type ForwardingOperationsTransportationOptionsListQueryResult = NonNullable<Awaited<ReturnType<typeof forwardingOperationsTransportationOptionsList>>>
export type ForwardingOperationsTransportationOptionsListQueryError = unknown

export const useForwardingOperationsTransportationOptionsList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof forwardingOperationsTransportationOptionsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getForwardingOperationsTransportationOptionsListKey() : null);
  const swrFn = () => forwardingOperationsTransportationOptionsList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const forwardingOperationsTransportationOptionsCreate = (
    transportationOptionRequest: TransportationOptionRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TransportationOption>(
      {url: `/api/v1/forwarding-operations/transportation-options/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: transportationOptionRequest
    },
      options);
    }
  


export const forwardingOperationsTransportationOptionsRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TransportationOption>(
      {url: `/api/v1/forwarding-operations/transportation-options/${id}/`, method: 'get'
    },
      options);
    }
  

export const getForwardingOperationsTransportationOptionsRetrieveKey = (id: number,) => [`/api/v1/forwarding-operations/transportation-options/${id}/`] as const;

    
export type ForwardingOperationsTransportationOptionsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof forwardingOperationsTransportationOptionsRetrieve>>>
export type ForwardingOperationsTransportationOptionsRetrieveQueryError = unknown

export const useForwardingOperationsTransportationOptionsRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof forwardingOperationsTransportationOptionsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getForwardingOperationsTransportationOptionsRetrieveKey(id) : null);
  const swrFn = () => forwardingOperationsTransportationOptionsRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const forwardingOperationsTransportationOptionsUpdate = (
    id: number,
    transportationOptionRequest: TransportationOptionRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TransportationOption>(
      {url: `/api/v1/forwarding-operations/transportation-options/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: transportationOptionRequest
    },
      options);
    }
  


export const forwardingOperationsTransportationOptionsPartialUpdate = (
    id: number,
    patchedTransportationOptionRequest: PatchedTransportationOptionRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TransportationOption>(
      {url: `/api/v1/forwarding-operations/transportation-options/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedTransportationOptionRequest
    },
      options);
    }
  


export const forwardingOperationsTransportationOptionsDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/forwarding-operations/transportation-options/${id}/`, method: 'delete'
    },
      options);
    }
  


export const loginCreate = (
    portrichTokenObtainPairRequest: PortrichTokenObtainPairRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PortrichTokenObtainPair>(
      {url: `/api/v1/login/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: portrichTokenObtainPairRequest
    },
      options);
    }
  


/**
 * Takes a refresh type JSON web token and returns an access type JSON web
token if the refresh token is valid.
 */
export const loginRefreshCreate = (
    tokenRefreshRequest: TokenRefreshRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TokenRefresh>(
      {url: `/api/v1/login/refresh/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: tokenRefreshRequest
    },
      options);
    }
  


export const masterdataAirFlightNumberList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirFlightNumber[]>(
      {url: `/api/v1/masterdata/air-flight-number/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataAirFlightNumberListKey = () => [`/api/v1/masterdata/air-flight-number/`] as const;

    
export type MasterdataAirFlightNumberListQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataAirFlightNumberList>>>
export type MasterdataAirFlightNumberListQueryError = unknown

export const useMasterdataAirFlightNumberList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataAirFlightNumberList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataAirFlightNumberListKey() : null);
  const swrFn = () => masterdataAirFlightNumberList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataAirFlightNumberCreate = (
    airFlightNumberRequest: AirFlightNumberRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirFlightNumber>(
      {url: `/api/v1/masterdata/air-flight-number/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: airFlightNumberRequest
    },
      options);
    }
  


export const masterdataAirFlightNumberRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirFlightNumber>(
      {url: `/api/v1/masterdata/air-flight-number/${id}/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataAirFlightNumberRetrieveKey = (id: number,) => [`/api/v1/masterdata/air-flight-number/${id}/`] as const;

    
export type MasterdataAirFlightNumberRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataAirFlightNumberRetrieve>>>
export type MasterdataAirFlightNumberRetrieveQueryError = unknown

export const useMasterdataAirFlightNumberRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataAirFlightNumberRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataAirFlightNumberRetrieveKey(id) : null);
  const swrFn = () => masterdataAirFlightNumberRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataAirFlightNumberUpdate = (
    id: number,
    airFlightNumberRequest: AirFlightNumberRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirFlightNumber>(
      {url: `/api/v1/masterdata/air-flight-number/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: airFlightNumberRequest
    },
      options);
    }
  


export const masterdataAirFlightNumberPartialUpdate = (
    id: number,
    patchedAirFlightNumberRequest: PatchedAirFlightNumberRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirFlightNumber>(
      {url: `/api/v1/masterdata/air-flight-number/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedAirFlightNumberRequest
    },
      options);
    }
  


export const masterdataAirFlightNumberDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/masterdata/air-flight-number/${id}/`, method: 'delete'
    },
      options);
    }
  


export const masterdataAircraftTypesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirCraftType[]>(
      {url: `/api/v1/masterdata/aircraft-types/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataAircraftTypesListKey = () => [`/api/v1/masterdata/aircraft-types/`] as const;

    
export type MasterdataAircraftTypesListQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataAircraftTypesList>>>
export type MasterdataAircraftTypesListQueryError = unknown

export const useMasterdataAircraftTypesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataAircraftTypesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataAircraftTypesListKey() : null);
  const swrFn = () => masterdataAircraftTypesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataAircraftTypesCreate = (
    airCraftTypeRequest: AirCraftTypeRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirCraftType>(
      {url: `/api/v1/masterdata/aircraft-types/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: airCraftTypeRequest
    },
      options);
    }
  


export const masterdataAircraftTypesRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirCraftType>(
      {url: `/api/v1/masterdata/aircraft-types/${id}/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataAircraftTypesRetrieveKey = (id: number,) => [`/api/v1/masterdata/aircraft-types/${id}/`] as const;

    
export type MasterdataAircraftTypesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataAircraftTypesRetrieve>>>
export type MasterdataAircraftTypesRetrieveQueryError = unknown

export const useMasterdataAircraftTypesRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataAircraftTypesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataAircraftTypesRetrieveKey(id) : null);
  const swrFn = () => masterdataAircraftTypesRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataAircraftTypesUpdate = (
    id: number,
    airCraftTypeRequest: AirCraftTypeRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirCraftType>(
      {url: `/api/v1/masterdata/aircraft-types/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: airCraftTypeRequest
    },
      options);
    }
  


export const masterdataAircraftTypesPartialUpdate = (
    id: number,
    patchedAirCraftTypeRequest: PatchedAirCraftTypeRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirCraftType>(
      {url: `/api/v1/masterdata/aircraft-types/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedAirCraftTypeRequest
    },
      options);
    }
  


export const masterdataAircraftTypesDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/masterdata/aircraft-types/${id}/`, method: 'delete'
    },
      options);
    }
  


export const masterdataBillingUnitTypesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BillingUnitType[]>(
      {url: `/api/v1/masterdata/billing-unit-types/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataBillingUnitTypesListKey = () => [`/api/v1/masterdata/billing-unit-types/`] as const;

    
export type MasterdataBillingUnitTypesListQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataBillingUnitTypesList>>>
export type MasterdataBillingUnitTypesListQueryError = unknown

export const useMasterdataBillingUnitTypesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataBillingUnitTypesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataBillingUnitTypesListKey() : null);
  const swrFn = () => masterdataBillingUnitTypesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataBillingUnitTypesRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BillingUnitType>(
      {url: `/api/v1/masterdata/billing-unit-types/${id}/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataBillingUnitTypesRetrieveKey = (id: number,) => [`/api/v1/masterdata/billing-unit-types/${id}/`] as const;

    
export type MasterdataBillingUnitTypesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataBillingUnitTypesRetrieve>>>
export type MasterdataBillingUnitTypesRetrieveQueryError = unknown

export const useMasterdataBillingUnitTypesRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataBillingUnitTypesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataBillingUnitTypesRetrieveKey(id) : null);
  const swrFn = () => masterdataBillingUnitTypesRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataCargoesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Cargo[]>(
      {url: `/api/v1/masterdata/cargoes/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataCargoesListKey = () => [`/api/v1/masterdata/cargoes/`] as const;

    
export type MasterdataCargoesListQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataCargoesList>>>
export type MasterdataCargoesListQueryError = unknown

export const useMasterdataCargoesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataCargoesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataCargoesListKey() : null);
  const swrFn = () => masterdataCargoesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataCargoesCreate = (
    cargoRequest: CargoRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Cargo>(
      {url: `/api/v1/masterdata/cargoes/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: cargoRequest
    },
      options);
    }
  


export const masterdataCargoesRetrieve = (
    cargoesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Cargo>(
      {url: `/api/v1/masterdata/cargoes/${cargoesId}/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataCargoesRetrieveKey = (cargoesId: number,) => [`/api/v1/masterdata/cargoes/${cargoesId}/`] as const;

    
export type MasterdataCargoesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataCargoesRetrieve>>>
export type MasterdataCargoesRetrieveQueryError = unknown

export const useMasterdataCargoesRetrieve = <TError = unknown>(
 cargoesId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataCargoesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(cargoesId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataCargoesRetrieveKey(cargoesId) : null);
  const swrFn = () => masterdataCargoesRetrieve(cargoesId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataCargoesUpdate = (
    cargoesId: number,
    cargoRequest: CargoRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Cargo>(
      {url: `/api/v1/masterdata/cargoes/${cargoesId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: cargoRequest
    },
      options);
    }
  


export const masterdataCargoesPartialUpdate = (
    cargoesId: number,
    patchedCargoRequest: PatchedCargoRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Cargo>(
      {url: `/api/v1/masterdata/cargoes/${cargoesId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedCargoRequest
    },
      options);
    }
  


export const masterdataCargoesDestroy = (
    cargoesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/masterdata/cargoes/${cargoesId}/`, method: 'delete'
    },
      options);
    }
  


export const masterdataContainerTypesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ContainerTypes[]>(
      {url: `/api/v1/masterdata/container-types/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataContainerTypesListKey = () => [`/api/v1/masterdata/container-types/`] as const;

    
export type MasterdataContainerTypesListQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataContainerTypesList>>>
export type MasterdataContainerTypesListQueryError = unknown

export const useMasterdataContainerTypesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataContainerTypesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataContainerTypesListKey() : null);
  const swrFn = () => masterdataContainerTypesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataContainerTypesCreate = (
    containerTypesRequest: ContainerTypesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ContainerTypes>(
      {url: `/api/v1/masterdata/container-types/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: containerTypesRequest
    },
      options);
    }
  


export const masterdataContainerTypesRetrieve = (
    containerTypesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ContainerTypes>(
      {url: `/api/v1/masterdata/container-types/${containerTypesId}/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataContainerTypesRetrieveKey = (containerTypesId: number,) => [`/api/v1/masterdata/container-types/${containerTypesId}/`] as const;

    
export type MasterdataContainerTypesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataContainerTypesRetrieve>>>
export type MasterdataContainerTypesRetrieveQueryError = unknown

export const useMasterdataContainerTypesRetrieve = <TError = unknown>(
 containerTypesId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataContainerTypesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(containerTypesId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataContainerTypesRetrieveKey(containerTypesId) : null);
  const swrFn = () => masterdataContainerTypesRetrieve(containerTypesId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataContainerTypesUpdate = (
    containerTypesId: number,
    containerTypesRequest: ContainerTypesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ContainerTypes>(
      {url: `/api/v1/masterdata/container-types/${containerTypesId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: containerTypesRequest
    },
      options);
    }
  


export const masterdataContainerTypesPartialUpdate = (
    containerTypesId: number,
    patchedContainerTypesRequest: PatchedContainerTypesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ContainerTypes>(
      {url: `/api/v1/masterdata/container-types/${containerTypesId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedContainerTypesRequest
    },
      options);
    }
  


export const masterdataContainerTypesDestroy = (
    containerTypesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/masterdata/container-types/${containerTypesId}/`, method: 'delete'
    },
      options);
    }
  


export const masterdataCountriesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Countries[]>(
      {url: `/api/v1/masterdata/countries/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataCountriesListKey = () => [`/api/v1/masterdata/countries/`] as const;

    
export type MasterdataCountriesListQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataCountriesList>>>
export type MasterdataCountriesListQueryError = unknown

export const useMasterdataCountriesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataCountriesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataCountriesListKey() : null);
  const swrFn = () => masterdataCountriesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataCountriesCreate = (
    countriesRequest: CountriesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Countries>(
      {url: `/api/v1/masterdata/countries/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: countriesRequest
    },
      options);
    }
  


export const masterdataCountriesRetrieve = (
    countriesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Countries>(
      {url: `/api/v1/masterdata/countries/${countriesId}/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataCountriesRetrieveKey = (countriesId: number,) => [`/api/v1/masterdata/countries/${countriesId}/`] as const;

    
export type MasterdataCountriesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataCountriesRetrieve>>>
export type MasterdataCountriesRetrieveQueryError = unknown

export const useMasterdataCountriesRetrieve = <TError = unknown>(
 countriesId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataCountriesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(countriesId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataCountriesRetrieveKey(countriesId) : null);
  const swrFn = () => masterdataCountriesRetrieve(countriesId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataCountriesUpdate = (
    countriesId: number,
    countriesRequest: CountriesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Countries>(
      {url: `/api/v1/masterdata/countries/${countriesId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: countriesRequest
    },
      options);
    }
  


export const masterdataCountriesPartialUpdate = (
    countriesId: number,
    patchedCountriesRequest: PatchedCountriesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Countries>(
      {url: `/api/v1/masterdata/countries/${countriesId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedCountriesRequest
    },
      options);
    }
  


export const masterdataCountriesDestroy = (
    countriesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/masterdata/countries/${countriesId}/`, method: 'delete'
    },
      options);
    }
  


export const masterdataCurrencyUnitsList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CurrencyUnits[]>(
      {url: `/api/v1/masterdata/currency-units/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataCurrencyUnitsListKey = () => [`/api/v1/masterdata/currency-units/`] as const;

    
export type MasterdataCurrencyUnitsListQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataCurrencyUnitsList>>>
export type MasterdataCurrencyUnitsListQueryError = unknown

export const useMasterdataCurrencyUnitsList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataCurrencyUnitsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataCurrencyUnitsListKey() : null);
  const swrFn = () => masterdataCurrencyUnitsList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataCurrencyUnitsCreate = (
    currencyUnitsRequest: CurrencyUnitsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CurrencyUnits>(
      {url: `/api/v1/masterdata/currency-units/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: currencyUnitsRequest
    },
      options);
    }
  


export const masterdataCurrencyUnitsRetrieve = (
    currencyUnitsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CurrencyUnits>(
      {url: `/api/v1/masterdata/currency-units/${currencyUnitsId}/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataCurrencyUnitsRetrieveKey = (currencyUnitsId: number,) => [`/api/v1/masterdata/currency-units/${currencyUnitsId}/`] as const;

    
export type MasterdataCurrencyUnitsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataCurrencyUnitsRetrieve>>>
export type MasterdataCurrencyUnitsRetrieveQueryError = unknown

export const useMasterdataCurrencyUnitsRetrieve = <TError = unknown>(
 currencyUnitsId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataCurrencyUnitsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(currencyUnitsId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataCurrencyUnitsRetrieveKey(currencyUnitsId) : null);
  const swrFn = () => masterdataCurrencyUnitsRetrieve(currencyUnitsId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataCurrencyUnitsUpdate = (
    currencyUnitsId: number,
    currencyUnitsRequest: CurrencyUnitsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CurrencyUnits>(
      {url: `/api/v1/masterdata/currency-units/${currencyUnitsId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: currencyUnitsRequest
    },
      options);
    }
  


export const masterdataCurrencyUnitsPartialUpdate = (
    currencyUnitsId: number,
    patchedCurrencyUnitsRequest: PatchedCurrencyUnitsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CurrencyUnits>(
      {url: `/api/v1/masterdata/currency-units/${currencyUnitsId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedCurrencyUnitsRequest
    },
      options);
    }
  


export const masterdataCurrencyUnitsDestroy = (
    currencyUnitsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/masterdata/currency-units/${currencyUnitsId}/`, method: 'delete'
    },
      options);
    }
  


export const masterdataPackingTypesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PackingType[]>(
      {url: `/api/v1/masterdata/packing-types/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataPackingTypesListKey = () => [`/api/v1/masterdata/packing-types/`] as const;

    
export type MasterdataPackingTypesListQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataPackingTypesList>>>
export type MasterdataPackingTypesListQueryError = unknown

export const useMasterdataPackingTypesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataPackingTypesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataPackingTypesListKey() : null);
  const swrFn = () => masterdataPackingTypesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataPackingTypesCreate = (
    packingTypeRequest: PackingTypeRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PackingType>(
      {url: `/api/v1/masterdata/packing-types/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: packingTypeRequest
    },
      options);
    }
  


export const masterdataPackingTypesRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PackingType>(
      {url: `/api/v1/masterdata/packing-types/${id}/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataPackingTypesRetrieveKey = (id: number,) => [`/api/v1/masterdata/packing-types/${id}/`] as const;

    
export type MasterdataPackingTypesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataPackingTypesRetrieve>>>
export type MasterdataPackingTypesRetrieveQueryError = unknown

export const useMasterdataPackingTypesRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataPackingTypesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataPackingTypesRetrieveKey(id) : null);
  const swrFn = () => masterdataPackingTypesRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataPackingTypesUpdate = (
    id: number,
    packingTypeRequest: PackingTypeRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PackingType>(
      {url: `/api/v1/masterdata/packing-types/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: packingTypeRequest
    },
      options);
    }
  


export const masterdataPackingTypesPartialUpdate = (
    id: number,
    patchedPackingTypeRequest: PatchedPackingTypeRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PackingType>(
      {url: `/api/v1/masterdata/packing-types/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedPackingTypeRequest
    },
      options);
    }
  


export const masterdataPackingTypesDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/masterdata/packing-types/${id}/`, method: 'delete'
    },
      options);
    }
  


export const masterdataPortsList = (
    params?: MasterdataPortsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Ports[]>(
      {url: `/api/v1/masterdata/ports/`, method: 'get',
        params
    },
      options);
    }
  

export const getMasterdataPortsListKey = (params?: MasterdataPortsListParams,) => [`/api/v1/masterdata/ports/`, ...(params ? [params]: [])] as const;

    
export type MasterdataPortsListQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataPortsList>>>
export type MasterdataPortsListQueryError = unknown

export const useMasterdataPortsList = <TError = unknown>(
 params?: MasterdataPortsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataPortsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataPortsListKey(params) : null);
  const swrFn = () => masterdataPortsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataPortsCreate = (
    portsRequest: PortsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Ports>(
      {url: `/api/v1/masterdata/ports/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: portsRequest
    },
      options);
    }
  


export const masterdataPortsRetrieve = (
    portsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Ports>(
      {url: `/api/v1/masterdata/ports/${portsId}/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataPortsRetrieveKey = (portsId: number,) => [`/api/v1/masterdata/ports/${portsId}/`] as const;

    
export type MasterdataPortsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataPortsRetrieve>>>
export type MasterdataPortsRetrieveQueryError = unknown

export const useMasterdataPortsRetrieve = <TError = unknown>(
 portsId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataPortsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(portsId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataPortsRetrieveKey(portsId) : null);
  const swrFn = () => masterdataPortsRetrieve(portsId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataPortsUpdate = (
    portsId: number,
    portsRequest: PortsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Ports>(
      {url: `/api/v1/masterdata/ports/${portsId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: portsRequest
    },
      options);
    }
  


export const masterdataPortsPartialUpdate = (
    portsId: number,
    patchedPortsRequest: PatchedPortsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Ports>(
      {url: `/api/v1/masterdata/ports/${portsId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedPortsRequest
    },
      options);
    }
  


export const masterdataPortsDestroy = (
    portsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/masterdata/ports/${portsId}/`, method: 'delete'
    },
      options);
    }
  


export const masterdataPortsAirList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Ports[]>(
      {url: `/api/v1/masterdata/ports/air/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataPortsAirListKey = () => [`/api/v1/masterdata/ports/air/`] as const;

    
export type MasterdataPortsAirListQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataPortsAirList>>>
export type MasterdataPortsAirListQueryError = unknown

export const useMasterdataPortsAirList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataPortsAirList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataPortsAirListKey() : null);
  const swrFn = () => masterdataPortsAirList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataPortsMarineList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Ports[]>(
      {url: `/api/v1/masterdata/ports/marine/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataPortsMarineListKey = () => [`/api/v1/masterdata/ports/marine/`] as const;

    
export type MasterdataPortsMarineListQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataPortsMarineList>>>
export type MasterdataPortsMarineListQueryError = unknown

export const useMasterdataPortsMarineList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataPortsMarineList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataPortsMarineListKey() : null);
  const swrFn = () => masterdataPortsMarineList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataPriceGroupsList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PriceGroup[]>(
      {url: `/api/v1/masterdata/price-groups/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataPriceGroupsListKey = () => [`/api/v1/masterdata/price-groups/`] as const;

    
export type MasterdataPriceGroupsListQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataPriceGroupsList>>>
export type MasterdataPriceGroupsListQueryError = unknown

export const useMasterdataPriceGroupsList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataPriceGroupsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataPriceGroupsListKey() : null);
  const swrFn = () => masterdataPriceGroupsList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataPriceGroupsRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PriceGroup>(
      {url: `/api/v1/masterdata/price-groups/${id}/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataPriceGroupsRetrieveKey = (id: number,) => [`/api/v1/masterdata/price-groups/${id}/`] as const;

    
export type MasterdataPriceGroupsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataPriceGroupsRetrieve>>>
export type MasterdataPriceGroupsRetrieveQueryError = unknown

export const useMasterdataPriceGroupsRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataPriceGroupsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataPriceGroupsRetrieveKey(id) : null);
  const swrFn = () => masterdataPriceGroupsRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataPriceTypesList = (
    params?: MasterdataPriceTypesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PriceType[]>(
      {url: `/api/v1/masterdata/price-types/`, method: 'get',
        params
    },
      options);
    }
  

export const getMasterdataPriceTypesListKey = (params?: MasterdataPriceTypesListParams,) => [`/api/v1/masterdata/price-types/`, ...(params ? [params]: [])] as const;

    
export type MasterdataPriceTypesListQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataPriceTypesList>>>
export type MasterdataPriceTypesListQueryError = unknown

export const useMasterdataPriceTypesList = <TError = unknown>(
 params?: MasterdataPriceTypesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataPriceTypesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataPriceTypesListKey(params) : null);
  const swrFn = () => masterdataPriceTypesList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataPriceTypesRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PriceType>(
      {url: `/api/v1/masterdata/price-types/${id}/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataPriceTypesRetrieveKey = (id: number,) => [`/api/v1/masterdata/price-types/${id}/`] as const;

    
export type MasterdataPriceTypesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataPriceTypesRetrieve>>>
export type MasterdataPriceTypesRetrieveQueryError = unknown

export const useMasterdataPriceTypesRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataPriceTypesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataPriceTypesRetrieveKey(id) : null);
  const swrFn = () => masterdataPriceTypesRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataServiceTypesLabelValuesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ServiceTypeLabelValue[]>(
      {url: `/api/v1/masterdata/service-types/label-values/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataServiceTypesLabelValuesListKey = () => [`/api/v1/masterdata/service-types/label-values/`] as const;

    
export type MasterdataServiceTypesLabelValuesListQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataServiceTypesLabelValuesList>>>
export type MasterdataServiceTypesLabelValuesListQueryError = unknown

export const useMasterdataServiceTypesLabelValuesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataServiceTypesLabelValuesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataServiceTypesLabelValuesListKey() : null);
  const swrFn = () => masterdataServiceTypesLabelValuesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataTruckSizesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TruckSize[]>(
      {url: `/api/v1/masterdata/truck-sizes/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataTruckSizesListKey = () => [`/api/v1/masterdata/truck-sizes/`] as const;

    
export type MasterdataTruckSizesListQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataTruckSizesList>>>
export type MasterdataTruckSizesListQueryError = unknown

export const useMasterdataTruckSizesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataTruckSizesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataTruckSizesListKey() : null);
  const swrFn = () => masterdataTruckSizesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataTruckSizesCreate = (
    truckSizeRequest: TruckSizeRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TruckSize>(
      {url: `/api/v1/masterdata/truck-sizes/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: truckSizeRequest
    },
      options);
    }
  


export const masterdataTruckSizesRetrieve = (
    truckSizesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TruckSize>(
      {url: `/api/v1/masterdata/truck-sizes/${truckSizesId}/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataTruckSizesRetrieveKey = (truckSizesId: number,) => [`/api/v1/masterdata/truck-sizes/${truckSizesId}/`] as const;

    
export type MasterdataTruckSizesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataTruckSizesRetrieve>>>
export type MasterdataTruckSizesRetrieveQueryError = unknown

export const useMasterdataTruckSizesRetrieve = <TError = unknown>(
 truckSizesId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataTruckSizesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(truckSizesId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataTruckSizesRetrieveKey(truckSizesId) : null);
  const swrFn = () => masterdataTruckSizesRetrieve(truckSizesId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataTruckSizesUpdate = (
    truckSizesId: number,
    truckSizeRequest: TruckSizeRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TruckSize>(
      {url: `/api/v1/masterdata/truck-sizes/${truckSizesId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: truckSizeRequest
    },
      options);
    }
  


export const masterdataTruckSizesPartialUpdate = (
    truckSizesId: number,
    patchedTruckSizeRequest: PatchedTruckSizeRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TruckSize>(
      {url: `/api/v1/masterdata/truck-sizes/${truckSizesId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedTruckSizeRequest
    },
      options);
    }
  


export const masterdataTruckSizesDestroy = (
    truckSizesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/masterdata/truck-sizes/${truckSizesId}/`, method: 'delete'
    },
      options);
    }
  


export const masterdataVesselsList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Vessels[]>(
      {url: `/api/v1/masterdata/vessels/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataVesselsListKey = () => [`/api/v1/masterdata/vessels/`] as const;

    
export type MasterdataVesselsListQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataVesselsList>>>
export type MasterdataVesselsListQueryError = unknown

export const useMasterdataVesselsList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataVesselsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataVesselsListKey() : null);
  const swrFn = () => masterdataVesselsList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataVesselsCreate = (
    vesselsRequest: VesselsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Vessels>(
      {url: `/api/v1/masterdata/vessels/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: vesselsRequest
    },
      options);
    }
  


export const masterdataVesselsRetrieve = (
    vesselsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Vessels>(
      {url: `/api/v1/masterdata/vessels/${vesselsId}/`, method: 'get'
    },
      options);
    }
  

export const getMasterdataVesselsRetrieveKey = (vesselsId: number,) => [`/api/v1/masterdata/vessels/${vesselsId}/`] as const;

    
export type MasterdataVesselsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof masterdataVesselsRetrieve>>>
export type MasterdataVesselsRetrieveQueryError = unknown

export const useMasterdataVesselsRetrieve = <TError = unknown>(
 vesselsId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof masterdataVesselsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(vesselsId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getMasterdataVesselsRetrieveKey(vesselsId) : null);
  const swrFn = () => masterdataVesselsRetrieve(vesselsId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const masterdataVesselsUpdate = (
    vesselsId: number,
    vesselsRequest: VesselsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Vessels>(
      {url: `/api/v1/masterdata/vessels/${vesselsId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: vesselsRequest
    },
      options);
    }
  


export const masterdataVesselsPartialUpdate = (
    vesselsId: number,
    patchedVesselsRequest: PatchedVesselsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Vessels>(
      {url: `/api/v1/masterdata/vessels/${vesselsId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedVesselsRequest
    },
      options);
    }
  


export const masterdataVesselsDestroy = (
    vesselsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/masterdata/vessels/${vesselsId}/`, method: 'delete'
    },
      options);
    }
  


export const operationFreightMethodList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<FreightMethod[]>(
      {url: `/api/v1/operation/freight-method/`, method: 'get'
    },
      options);
    }
  

export const getOperationFreightMethodListKey = () => [`/api/v1/operation/freight-method/`] as const;

    
export type OperationFreightMethodListQueryResult = NonNullable<Awaited<ReturnType<typeof operationFreightMethodList>>>
export type OperationFreightMethodListQueryError = unknown

export const useOperationFreightMethodList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof operationFreightMethodList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getOperationFreightMethodListKey() : null);
  const swrFn = () => operationFreightMethodList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const paymentInvoicesList = (
    params?: PaymentInvoicesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedPaymentInvoicesList>(
      {url: `/api/v1/payment-invoices/`, method: 'get',
        params
    },
      options);
    }
  

export const getPaymentInvoicesListKey = (params?: PaymentInvoicesListParams,) => [`/api/v1/payment-invoices/`, ...(params ? [params]: [])] as const;

    
export type PaymentInvoicesListQueryResult = NonNullable<Awaited<ReturnType<typeof paymentInvoicesList>>>
export type PaymentInvoicesListQueryError = unknown

export const usePaymentInvoicesList = <TError = unknown>(
 params?: PaymentInvoicesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof paymentInvoicesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPaymentInvoicesListKey(params) : null);
  const swrFn = () => paymentInvoicesList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const paymentInvoicesCreate = (
    paymentInvoicesRequest: PaymentInvoicesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaymentInvoices>(
      {url: `/api/v1/payment-invoices/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: paymentInvoicesRequest
    },
      options);
    }
  


export const paymentInvoicesPricesCreate = (
    paymentInvoicePk: number,
    paymentInvoicePriceRequest: PaymentInvoicePriceRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaymentInvoicePrice>(
      {url: `/api/v1/payment-invoices/${paymentInvoicePk}/prices/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: paymentInvoicePriceRequest
    },
      options);
    }
  


export const paymentInvoicesPricesUpdate = (
    paymentInvoicePk: number,
    id: number,
    paymentInvoicePriceRequest: PaymentInvoicePriceRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaymentInvoicePrice>(
      {url: `/api/v1/payment-invoices/${paymentInvoicePk}/prices/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: paymentInvoicePriceRequest
    },
      options);
    }
  


export const paymentInvoicesPricesPartialUpdate = (
    paymentInvoicePk: number,
    id: number,
    patchedPaymentInvoicePriceRequest: PatchedPaymentInvoicePriceRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaymentInvoicePrice>(
      {url: `/api/v1/payment-invoices/${paymentInvoicePk}/prices/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedPaymentInvoicePriceRequest
    },
      options);
    }
  


export const paymentInvoicesPricesDestroy = (
    paymentInvoicePk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/payment-invoices/${paymentInvoicePk}/prices/${id}/`, method: 'delete'
    },
      options);
    }
  


export const paymentInvoicesReportsList = (
    paymentInvoicePk: string,
    params?: PaymentInvoicesReportsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingReport[]>(
      {url: `/api/v1/payment-invoices/${paymentInvoicePk}/reports/`, method: 'get',
        params
    },
      options);
    }
  

export const getPaymentInvoicesReportsListKey = (paymentInvoicePk: string,
    params?: PaymentInvoicesReportsListParams,) => [`/api/v1/payment-invoices/${paymentInvoicePk}/reports/`, ...(params ? [params]: [])] as const;

    
export type PaymentInvoicesReportsListQueryResult = NonNullable<Awaited<ReturnType<typeof paymentInvoicesReportsList>>>
export type PaymentInvoicesReportsListQueryError = unknown

export const usePaymentInvoicesReportsList = <TError = unknown>(
 paymentInvoicePk: string,
    params?: PaymentInvoicesReportsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof paymentInvoicesReportsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(paymentInvoicePk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPaymentInvoicesReportsListKey(paymentInvoicePk,params) : null);
  const swrFn = () => paymentInvoicesReportsList(paymentInvoicePk,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const paymentInvoicesReportsCreate = (
    paymentInvoicePk: string,
    accountingReportRequest: AccountingReportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingReport>(
      {url: `/api/v1/payment-invoices/${paymentInvoicePk}/reports/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: accountingReportRequest
    },
      options);
    }
  


export const paymentInvoicesReportsUpdate = (
    paymentInvoicePk: string,
    id: number,
    accountingReportRequest: AccountingReportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingReport>(
      {url: `/api/v1/payment-invoices/${paymentInvoicePk}/reports/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: accountingReportRequest
    },
      options);
    }
  


export const paymentInvoicesReportsPartialUpdate = (
    paymentInvoicePk: string,
    id: number,
    patchedAccountingReportRequest: PatchedAccountingReportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AccountingReport>(
      {url: `/api/v1/payment-invoices/${paymentInvoicePk}/reports/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedAccountingReportRequest
    },
      options);
    }
  


export const paymentInvoicesReportsDetailsCreate = (
    paymentInvoicePk: string,
    reportPk: number,
    byPaymentInvoiceRequest: ByPaymentInvoiceRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ByPaymentInvoice>(
      {url: `/api/v1/payment-invoices/${paymentInvoicePk}/reports/${reportPk}/details/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: byPaymentInvoiceRequest
    },
      options);
    }
  


export const paymentInvoicesRetrieve = (
    paymentInvoicesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaymentInvoices>(
      {url: `/api/v1/payment-invoices/${paymentInvoicesId}/`, method: 'get'
    },
      options);
    }
  

export const getPaymentInvoicesRetrieveKey = (paymentInvoicesId: number,) => [`/api/v1/payment-invoices/${paymentInvoicesId}/`] as const;

    
export type PaymentInvoicesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof paymentInvoicesRetrieve>>>
export type PaymentInvoicesRetrieveQueryError = unknown

export const usePaymentInvoicesRetrieve = <TError = unknown>(
 paymentInvoicesId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof paymentInvoicesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(paymentInvoicesId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPaymentInvoicesRetrieveKey(paymentInvoicesId) : null);
  const swrFn = () => paymentInvoicesRetrieve(paymentInvoicesId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const paymentInvoicesUpdate = (
    paymentInvoicesId: number,
    paymentInvoicesRequest: PaymentInvoicesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaymentInvoices>(
      {url: `/api/v1/payment-invoices/${paymentInvoicesId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: paymentInvoicesRequest
    },
      options);
    }
  


export const paymentInvoicesPartialUpdate = (
    paymentInvoicesId: number,
    patchedPaymentInvoicesRequest: PatchedPaymentInvoicesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaymentInvoices>(
      {url: `/api/v1/payment-invoices/${paymentInvoicesId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedPaymentInvoicesRequest
    },
      options);
    }
  


export const paymentInvoicesDestroy = (
    paymentInvoicesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/payment-invoices/${paymentInvoicesId}/`, method: 'delete'
    },
      options);
    }
  


export const paymentInvoicesDocumentCreate = (
    paymentInvoicesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/payment-invoices/${paymentInvoicesId}/document/`, method: 'post'
    },
      options);
    }
  


export const paymentInvoicesSummariesByShipmentList = (
    params?: PaymentInvoicesSummariesByShipmentListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SummaryByShipment[]>(
      {url: `/api/v1/payment-invoices/summaries-by-shipment/`, method: 'get',
        params
    },
      options);
    }
  

export const getPaymentInvoicesSummariesByShipmentListKey = (params?: PaymentInvoicesSummariesByShipmentListParams,) => [`/api/v1/payment-invoices/summaries-by-shipment/`, ...(params ? [params]: [])] as const;

    
export type PaymentInvoicesSummariesByShipmentListQueryResult = NonNullable<Awaited<ReturnType<typeof paymentInvoicesSummariesByShipmentList>>>
export type PaymentInvoicesSummariesByShipmentListQueryError = unknown

export const usePaymentInvoicesSummariesByShipmentList = <TError = unknown>(
 params?: PaymentInvoicesSummariesByShipmentListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof paymentInvoicesSummariesByShipmentList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPaymentInvoicesSummariesByShipmentListKey(params) : null);
  const swrFn = () => paymentInvoicesSummariesByShipmentList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const paymentInvoicesSummariesByShipmentCreate = (
    summaryByShipmentRequest: SummaryByShipmentRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SummaryByShipment>(
      {url: `/api/v1/payment-invoices/summaries-by-shipment/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: summaryByShipmentRequest
    },
      options);
    }
  


export const paymentInvoicesSummariesByShipmentRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SummaryByShipment>(
      {url: `/api/v1/payment-invoices/summaries-by-shipment/${id}/`, method: 'get'
    },
      options);
    }
  

export const getPaymentInvoicesSummariesByShipmentRetrieveKey = (id: number,) => [`/api/v1/payment-invoices/summaries-by-shipment/${id}/`] as const;

    
export type PaymentInvoicesSummariesByShipmentRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof paymentInvoicesSummariesByShipmentRetrieve>>>
export type PaymentInvoicesSummariesByShipmentRetrieveQueryError = unknown

export const usePaymentInvoicesSummariesByShipmentRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof paymentInvoicesSummariesByShipmentRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPaymentInvoicesSummariesByShipmentRetrieveKey(id) : null);
  const swrFn = () => paymentInvoicesSummariesByShipmentRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const paymentInvoicesSummariesByShipmentUpdate = (
    id: number,
    summaryByShipmentRequest: SummaryByShipmentRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SummaryByShipment>(
      {url: `/api/v1/payment-invoices/summaries-by-shipment/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: summaryByShipmentRequest
    },
      options);
    }
  


export const paymentInvoicesSummariesByShipmentPartialUpdate = (
    id: number,
    patchedSummaryByShipmentRequest: PatchedSummaryByShipmentRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SummaryByShipment>(
      {url: `/api/v1/payment-invoices/summaries-by-shipment/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedSummaryByShipmentRequest
    },
      options);
    }
  


export const paymentInvoicesSummariesByShipmentDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/payment-invoices/summaries-by-shipment/${id}/`, method: 'delete'
    },
      options);
    }
  


export const paymentInvoicesSummariesByShipmentSummariesByCompanyList = (
    summaryPk: string,
    params?: PaymentInvoicesSummariesByShipmentSummariesByCompanyListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SummaryByCompanySkelton[]>(
      {url: `/api/v1/payment-invoices/summaries-by-shipment/${summaryPk}/summaries-by-company/`, method: 'get',
        params
    },
      options);
    }
  

export const getPaymentInvoicesSummariesByShipmentSummariesByCompanyListKey = (summaryPk: string,
    params?: PaymentInvoicesSummariesByShipmentSummariesByCompanyListParams,) => [`/api/v1/payment-invoices/summaries-by-shipment/${summaryPk}/summaries-by-company/`, ...(params ? [params]: [])] as const;

    
export type PaymentInvoicesSummariesByShipmentSummariesByCompanyListQueryResult = NonNullable<Awaited<ReturnType<typeof paymentInvoicesSummariesByShipmentSummariesByCompanyList>>>
export type PaymentInvoicesSummariesByShipmentSummariesByCompanyListQueryError = unknown

export const usePaymentInvoicesSummariesByShipmentSummariesByCompanyList = <TError = unknown>(
 summaryPk: string,
    params?: PaymentInvoicesSummariesByShipmentSummariesByCompanyListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof paymentInvoicesSummariesByShipmentSummariesByCompanyList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(summaryPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPaymentInvoicesSummariesByShipmentSummariesByCompanyListKey(summaryPk,params) : null);
  const swrFn = () => paymentInvoicesSummariesByShipmentSummariesByCompanyList(summaryPk,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const paymentInvoicesSummariesByShipmentSummariesByCompanyCreate = (
    summaryPk: string,
    summaryByCompanySkeltonRequest: SummaryByCompanySkeltonRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SummaryByCompanySkelton>(
      {url: `/api/v1/payment-invoices/summaries-by-shipment/${summaryPk}/summaries-by-company/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: summaryByCompanySkeltonRequest
    },
      options);
    }
  


export const paymentInvoicesSummariesByShipmentSummariesByCompanyRetrieve = (
    summaryPk: string,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SummaryByCompanySkelton>(
      {url: `/api/v1/payment-invoices/summaries-by-shipment/${summaryPk}/summaries-by-company/${id}/`, method: 'get'
    },
      options);
    }
  

export const getPaymentInvoicesSummariesByShipmentSummariesByCompanyRetrieveKey = (summaryPk: string,
    id: number,) => [`/api/v1/payment-invoices/summaries-by-shipment/${summaryPk}/summaries-by-company/${id}/`] as const;

    
export type PaymentInvoicesSummariesByShipmentSummariesByCompanyRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof paymentInvoicesSummariesByShipmentSummariesByCompanyRetrieve>>>
export type PaymentInvoicesSummariesByShipmentSummariesByCompanyRetrieveQueryError = unknown

export const usePaymentInvoicesSummariesByShipmentSummariesByCompanyRetrieve = <TError = unknown>(
 summaryPk: string,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof paymentInvoicesSummariesByShipmentSummariesByCompanyRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(summaryPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPaymentInvoicesSummariesByShipmentSummariesByCompanyRetrieveKey(summaryPk,id) : null);
  const swrFn = () => paymentInvoicesSummariesByShipmentSummariesByCompanyRetrieve(summaryPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const paymentInvoicesSummariesByShipmentSummariesByCompanyUpdate = (
    summaryPk: string,
    id: number,
    summaryByCompanySkeltonRequest: SummaryByCompanySkeltonRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SummaryByCompanySkelton>(
      {url: `/api/v1/payment-invoices/summaries-by-shipment/${summaryPk}/summaries-by-company/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: summaryByCompanySkeltonRequest
    },
      options);
    }
  


export const paymentInvoicesSummariesByShipmentSummariesByCompanyPartialUpdate = (
    summaryPk: string,
    id: number,
    patchedSummaryByCompanySkeltonRequest: PatchedSummaryByCompanySkeltonRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SummaryByCompanySkelton>(
      {url: `/api/v1/payment-invoices/summaries-by-shipment/${summaryPk}/summaries-by-company/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedSummaryByCompanySkeltonRequest
    },
      options);
    }
  


export const paymentInvoicesSummariesByShipmentSummariesByCompanyDestroy = (
    summaryPk: string,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/payment-invoices/summaries-by-shipment/${summaryPk}/summaries-by-company/${id}/`, method: 'delete'
    },
      options);
    }
  


/**
 * pre-registration の作成だけを行うための ViewSet
 */
export const preRegistrationsCreate = (
    preRegistrationCreateRequest: PreRegistrationCreateRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PreRegistrationCreate>(
      {url: `/api/v1/pre-registrations/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: preRegistrationCreateRequest
    },
      options);
    }
  


export const preRegistrationsCargoesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ReadOnlyCargo[]>(
      {url: `/api/v1/pre-registrations/cargoes/`, method: 'get'
    },
      options);
    }
  

export const getPreRegistrationsCargoesListKey = () => [`/api/v1/pre-registrations/cargoes/`] as const;

    
export type PreRegistrationsCargoesListQueryResult = NonNullable<Awaited<ReturnType<typeof preRegistrationsCargoesList>>>
export type PreRegistrationsCargoesListQueryError = unknown

export const usePreRegistrationsCargoesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof preRegistrationsCargoesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPreRegistrationsCargoesListKey() : null);
  const swrFn = () => preRegistrationsCargoesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const preRegistrationsCargoesRetrieve = (
    cargoesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ReadOnlyCargo>(
      {url: `/api/v1/pre-registrations/cargoes/${cargoesId}/`, method: 'get'
    },
      options);
    }
  

export const getPreRegistrationsCargoesRetrieveKey = (cargoesId: number,) => [`/api/v1/pre-registrations/cargoes/${cargoesId}/`] as const;

    
export type PreRegistrationsCargoesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof preRegistrationsCargoesRetrieve>>>
export type PreRegistrationsCargoesRetrieveQueryError = unknown

export const usePreRegistrationsCargoesRetrieve = <TError = unknown>(
 cargoesId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof preRegistrationsCargoesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(cargoesId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPreRegistrationsCargoesRetrieveKey(cargoesId) : null);
  const swrFn = () => preRegistrationsCargoesRetrieve(cargoesId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const preRegistrationsContainerTypesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ReadOnlyContainerType[]>(
      {url: `/api/v1/pre-registrations/container-types/`, method: 'get'
    },
      options);
    }
  

export const getPreRegistrationsContainerTypesListKey = () => [`/api/v1/pre-registrations/container-types/`] as const;

    
export type PreRegistrationsContainerTypesListQueryResult = NonNullable<Awaited<ReturnType<typeof preRegistrationsContainerTypesList>>>
export type PreRegistrationsContainerTypesListQueryError = unknown

export const usePreRegistrationsContainerTypesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof preRegistrationsContainerTypesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPreRegistrationsContainerTypesListKey() : null);
  const swrFn = () => preRegistrationsContainerTypesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const preRegistrationsContainerTypesRetrieve = (
    containerTypesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ReadOnlyContainerType>(
      {url: `/api/v1/pre-registrations/container-types/${containerTypesId}/`, method: 'get'
    },
      options);
    }
  

export const getPreRegistrationsContainerTypesRetrieveKey = (containerTypesId: number,) => [`/api/v1/pre-registrations/container-types/${containerTypesId}/`] as const;

    
export type PreRegistrationsContainerTypesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof preRegistrationsContainerTypesRetrieve>>>
export type PreRegistrationsContainerTypesRetrieveQueryError = unknown

export const usePreRegistrationsContainerTypesRetrieve = <TError = unknown>(
 containerTypesId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof preRegistrationsContainerTypesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(containerTypesId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPreRegistrationsContainerTypesRetrieveKey(containerTypesId) : null);
  const swrFn = () => preRegistrationsContainerTypesRetrieve(containerTypesId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const preRegistrationsCountriesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ReadOnlyCountry[]>(
      {url: `/api/v1/pre-registrations/countries/`, method: 'get'
    },
      options);
    }
  

export const getPreRegistrationsCountriesListKey = () => [`/api/v1/pre-registrations/countries/`] as const;

    
export type PreRegistrationsCountriesListQueryResult = NonNullable<Awaited<ReturnType<typeof preRegistrationsCountriesList>>>
export type PreRegistrationsCountriesListQueryError = unknown

export const usePreRegistrationsCountriesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof preRegistrationsCountriesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPreRegistrationsCountriesListKey() : null);
  const swrFn = () => preRegistrationsCountriesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const preRegistrationsCountriesRetrieve = (
    countriesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ReadOnlyCountry>(
      {url: `/api/v1/pre-registrations/countries/${countriesId}/`, method: 'get'
    },
      options);
    }
  

export const getPreRegistrationsCountriesRetrieveKey = (countriesId: number,) => [`/api/v1/pre-registrations/countries/${countriesId}/`] as const;

    
export type PreRegistrationsCountriesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof preRegistrationsCountriesRetrieve>>>
export type PreRegistrationsCountriesRetrieveQueryError = unknown

export const usePreRegistrationsCountriesRetrieve = <TError = unknown>(
 countriesId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof preRegistrationsCountriesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(countriesId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPreRegistrationsCountriesRetrieveKey(countriesId) : null);
  const swrFn = () => preRegistrationsCountriesRetrieve(countriesId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const preRegistrationsPortsList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ReadOnlyPort[]>(
      {url: `/api/v1/pre-registrations/ports/`, method: 'get'
    },
      options);
    }
  

export const getPreRegistrationsPortsListKey = () => [`/api/v1/pre-registrations/ports/`] as const;

    
export type PreRegistrationsPortsListQueryResult = NonNullable<Awaited<ReturnType<typeof preRegistrationsPortsList>>>
export type PreRegistrationsPortsListQueryError = unknown

export const usePreRegistrationsPortsList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof preRegistrationsPortsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPreRegistrationsPortsListKey() : null);
  const swrFn = () => preRegistrationsPortsList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const preRegistrationsPortsRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ReadOnlyPort>(
      {url: `/api/v1/pre-registrations/ports/${id}/`, method: 'get'
    },
      options);
    }
  

export const getPreRegistrationsPortsRetrieveKey = (id: number,) => [`/api/v1/pre-registrations/ports/${id}/`] as const;

    
export type PreRegistrationsPortsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof preRegistrationsPortsRetrieve>>>
export type PreRegistrationsPortsRetrieveQueryError = unknown

export const usePreRegistrationsPortsRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof preRegistrationsPortsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPreRegistrationsPortsRetrieveKey(id) : null);
  const swrFn = () => preRegistrationsPortsRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const preRegistrationsPortsAirList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ReadOnlyPort[]>(
      {url: `/api/v1/pre-registrations/ports/air/`, method: 'get'
    },
      options);
    }
  

export const getPreRegistrationsPortsAirListKey = () => [`/api/v1/pre-registrations/ports/air/`] as const;

    
export type PreRegistrationsPortsAirListQueryResult = NonNullable<Awaited<ReturnType<typeof preRegistrationsPortsAirList>>>
export type PreRegistrationsPortsAirListQueryError = unknown

export const usePreRegistrationsPortsAirList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof preRegistrationsPortsAirList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPreRegistrationsPortsAirListKey() : null);
  const swrFn = () => preRegistrationsPortsAirList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const preRegistrationsPortsMarineList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ReadOnlyPort[]>(
      {url: `/api/v1/pre-registrations/ports/marine/`, method: 'get'
    },
      options);
    }
  

export const getPreRegistrationsPortsMarineListKey = () => [`/api/v1/pre-registrations/ports/marine/`] as const;

    
export type PreRegistrationsPortsMarineListQueryResult = NonNullable<Awaited<ReturnType<typeof preRegistrationsPortsMarineList>>>
export type PreRegistrationsPortsMarineListQueryError = unknown

export const usePreRegistrationsPortsMarineList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof preRegistrationsPortsMarineList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPreRegistrationsPortsMarineListKey() : null);
  const swrFn = () => preRegistrationsPortsMarineList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const purchaseManagementsAirTransportList = (
    params?: PurchaseManagementsAirTransportListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedPurchasePriceForAirTransportList>(
      {url: `/api/v1/purchase-managements/air-transport/`, method: 'get',
        params
    },
      options);
    }
  

export const getPurchaseManagementsAirTransportListKey = (params?: PurchaseManagementsAirTransportListParams,) => [`/api/v1/purchase-managements/air-transport/`, ...(params ? [params]: [])] as const;

    
export type PurchaseManagementsAirTransportListQueryResult = NonNullable<Awaited<ReturnType<typeof purchaseManagementsAirTransportList>>>
export type PurchaseManagementsAirTransportListQueryError = unknown

export const usePurchaseManagementsAirTransportList = <TError = unknown>(
 params?: PurchaseManagementsAirTransportListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof purchaseManagementsAirTransportList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPurchaseManagementsAirTransportListKey(params) : null);
  const swrFn = () => purchaseManagementsAirTransportList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const purchaseManagementsAirTransportCreate = (
    purchasePriceForAirTransportRequest: PurchasePriceForAirTransportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePriceForAirTransport>(
      {url: `/api/v1/purchase-managements/air-transport/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: purchasePriceForAirTransportRequest
    },
      options);
    }
  


export const purchaseManagementsAirTransportRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePriceForAirTransport>(
      {url: `/api/v1/purchase-managements/air-transport/${id}/`, method: 'get'
    },
      options);
    }
  

export const getPurchaseManagementsAirTransportRetrieveKey = (id: number,) => [`/api/v1/purchase-managements/air-transport/${id}/`] as const;

    
export type PurchaseManagementsAirTransportRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof purchaseManagementsAirTransportRetrieve>>>
export type PurchaseManagementsAirTransportRetrieveQueryError = unknown

export const usePurchaseManagementsAirTransportRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof purchaseManagementsAirTransportRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPurchaseManagementsAirTransportRetrieveKey(id) : null);
  const swrFn = () => purchaseManagementsAirTransportRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const purchaseManagementsAirTransportUpdate = (
    id: number,
    purchasePriceForAirTransportRequest: PurchasePriceForAirTransportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePriceForAirTransport>(
      {url: `/api/v1/purchase-managements/air-transport/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: purchasePriceForAirTransportRequest
    },
      options);
    }
  


export const purchaseManagementsAirTransportPartialUpdate = (
    id: number,
    patchedPurchasePriceForAirTransportRequest: PatchedPurchasePriceForAirTransportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePriceForAirTransport>(
      {url: `/api/v1/purchase-managements/air-transport/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedPurchasePriceForAirTransportRequest
    },
      options);
    }
  


export const purchaseManagementsAirTransportDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/purchase-managements/air-transport/${id}/`, method: 'delete'
    },
      options);
    }
  


export const purchaseManagementsCustomsFeesList = (
    params?: PurchaseManagementsCustomsFeesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedPurchasePricesForCustomsFeesList>(
      {url: `/api/v1/purchase-managements/customs-fees/`, method: 'get',
        params
    },
      options);
    }
  

export const getPurchaseManagementsCustomsFeesListKey = (params?: PurchaseManagementsCustomsFeesListParams,) => [`/api/v1/purchase-managements/customs-fees/`, ...(params ? [params]: [])] as const;

    
export type PurchaseManagementsCustomsFeesListQueryResult = NonNullable<Awaited<ReturnType<typeof purchaseManagementsCustomsFeesList>>>
export type PurchaseManagementsCustomsFeesListQueryError = unknown

export const usePurchaseManagementsCustomsFeesList = <TError = unknown>(
 params?: PurchaseManagementsCustomsFeesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof purchaseManagementsCustomsFeesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPurchaseManagementsCustomsFeesListKey(params) : null);
  const swrFn = () => purchaseManagementsCustomsFeesList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const purchaseManagementsCustomsFeesCreate = (
    purchasePricesForCustomsFeesRequest: PurchasePricesForCustomsFeesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePricesForCustomsFees>(
      {url: `/api/v1/purchase-managements/customs-fees/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: purchasePricesForCustomsFeesRequest
    },
      options);
    }
  


export const purchaseManagementsCustomsFeesRetrieve = (
    purchasePricesForCustomsFeesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePricesForCustomsFees>(
      {url: `/api/v1/purchase-managements/customs-fees/${purchasePricesForCustomsFeesId}/`, method: 'get'
    },
      options);
    }
  

export const getPurchaseManagementsCustomsFeesRetrieveKey = (purchasePricesForCustomsFeesId: number,) => [`/api/v1/purchase-managements/customs-fees/${purchasePricesForCustomsFeesId}/`] as const;

    
export type PurchaseManagementsCustomsFeesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof purchaseManagementsCustomsFeesRetrieve>>>
export type PurchaseManagementsCustomsFeesRetrieveQueryError = unknown

export const usePurchaseManagementsCustomsFeesRetrieve = <TError = unknown>(
 purchasePricesForCustomsFeesId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof purchaseManagementsCustomsFeesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(purchasePricesForCustomsFeesId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPurchaseManagementsCustomsFeesRetrieveKey(purchasePricesForCustomsFeesId) : null);
  const swrFn = () => purchaseManagementsCustomsFeesRetrieve(purchasePricesForCustomsFeesId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const purchaseManagementsCustomsFeesUpdate = (
    purchasePricesForCustomsFeesId: number,
    purchasePricesForCustomsFeesRequest: PurchasePricesForCustomsFeesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePricesForCustomsFees>(
      {url: `/api/v1/purchase-managements/customs-fees/${purchasePricesForCustomsFeesId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: purchasePricesForCustomsFeesRequest
    },
      options);
    }
  


export const purchaseManagementsCustomsFeesPartialUpdate = (
    purchasePricesForCustomsFeesId: number,
    patchedPurchasePricesForCustomsFeesRequest: PatchedPurchasePricesForCustomsFeesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePricesForCustomsFees>(
      {url: `/api/v1/purchase-managements/customs-fees/${purchasePricesForCustomsFeesId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedPurchasePricesForCustomsFeesRequest
    },
      options);
    }
  


export const purchaseManagementsCustomsFeesDestroy = (
    purchasePricesForCustomsFeesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/purchase-managements/customs-fees/${purchasePricesForCustomsFeesId}/`, method: 'delete'
    },
      options);
    }
  


export const purchaseManagementsLandFreightsList = (
    params?: PurchaseManagementsLandFreightsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedPurchasePricesForLandFreightsList>(
      {url: `/api/v1/purchase-managements/land-freights/`, method: 'get',
        params
    },
      options);
    }
  

export const getPurchaseManagementsLandFreightsListKey = (params?: PurchaseManagementsLandFreightsListParams,) => [`/api/v1/purchase-managements/land-freights/`, ...(params ? [params]: [])] as const;

    
export type PurchaseManagementsLandFreightsListQueryResult = NonNullable<Awaited<ReturnType<typeof purchaseManagementsLandFreightsList>>>
export type PurchaseManagementsLandFreightsListQueryError = unknown

export const usePurchaseManagementsLandFreightsList = <TError = unknown>(
 params?: PurchaseManagementsLandFreightsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof purchaseManagementsLandFreightsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPurchaseManagementsLandFreightsListKey(params) : null);
  const swrFn = () => purchaseManagementsLandFreightsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const purchaseManagementsLandFreightsCreate = (
    purchasePricesForLandFreightsRequest: PurchasePricesForLandFreightsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePricesForLandFreights>(
      {url: `/api/v1/purchase-managements/land-freights/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: purchasePricesForLandFreightsRequest
    },
      options);
    }
  


export const purchaseManagementsLandFreightsRetrieve = (
    purchasePricesForLandFreightsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePricesForLandFreights>(
      {url: `/api/v1/purchase-managements/land-freights/${purchasePricesForLandFreightsId}/`, method: 'get'
    },
      options);
    }
  

export const getPurchaseManagementsLandFreightsRetrieveKey = (purchasePricesForLandFreightsId: number,) => [`/api/v1/purchase-managements/land-freights/${purchasePricesForLandFreightsId}/`] as const;

    
export type PurchaseManagementsLandFreightsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof purchaseManagementsLandFreightsRetrieve>>>
export type PurchaseManagementsLandFreightsRetrieveQueryError = unknown

export const usePurchaseManagementsLandFreightsRetrieve = <TError = unknown>(
 purchasePricesForLandFreightsId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof purchaseManagementsLandFreightsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(purchasePricesForLandFreightsId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPurchaseManagementsLandFreightsRetrieveKey(purchasePricesForLandFreightsId) : null);
  const swrFn = () => purchaseManagementsLandFreightsRetrieve(purchasePricesForLandFreightsId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const purchaseManagementsLandFreightsUpdate = (
    purchasePricesForLandFreightsId: number,
    purchasePricesForLandFreightsRequest: PurchasePricesForLandFreightsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePricesForLandFreights>(
      {url: `/api/v1/purchase-managements/land-freights/${purchasePricesForLandFreightsId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: purchasePricesForLandFreightsRequest
    },
      options);
    }
  


export const purchaseManagementsLandFreightsPartialUpdate = (
    purchasePricesForLandFreightsId: number,
    patchedPurchasePricesForLandFreightsRequest: PatchedPurchasePricesForLandFreightsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePricesForLandFreights>(
      {url: `/api/v1/purchase-managements/land-freights/${purchasePricesForLandFreightsId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedPurchasePricesForLandFreightsRequest
    },
      options);
    }
  


export const purchaseManagementsLandFreightsDestroy = (
    purchasePricesForLandFreightsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/purchase-managements/land-freights/${purchasePricesForLandFreightsId}/`, method: 'delete'
    },
      options);
    }
  


export const purchaseManagementsSeaFreightsList = (
    params?: PurchaseManagementsSeaFreightsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedPurchasePricesForSeaFreightsList>(
      {url: `/api/v1/purchase-managements/sea-freights/`, method: 'get',
        params
    },
      options);
    }
  

export const getPurchaseManagementsSeaFreightsListKey = (params?: PurchaseManagementsSeaFreightsListParams,) => [`/api/v1/purchase-managements/sea-freights/`, ...(params ? [params]: [])] as const;

    
export type PurchaseManagementsSeaFreightsListQueryResult = NonNullable<Awaited<ReturnType<typeof purchaseManagementsSeaFreightsList>>>
export type PurchaseManagementsSeaFreightsListQueryError = unknown

export const usePurchaseManagementsSeaFreightsList = <TError = unknown>(
 params?: PurchaseManagementsSeaFreightsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof purchaseManagementsSeaFreightsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPurchaseManagementsSeaFreightsListKey(params) : null);
  const swrFn = () => purchaseManagementsSeaFreightsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const purchaseManagementsSeaFreightsCreate = (
    purchasePricesForSeaFreightsRequest: PurchasePricesForSeaFreightsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePricesForSeaFreights>(
      {url: `/api/v1/purchase-managements/sea-freights/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: purchasePricesForSeaFreightsRequest
    },
      options);
    }
  


export const purchaseManagementsSeaFreightsRetrieve = (
    purchasePricesForSeaFreightsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePricesForSeaFreights>(
      {url: `/api/v1/purchase-managements/sea-freights/${purchasePricesForSeaFreightsId}/`, method: 'get'
    },
      options);
    }
  

export const getPurchaseManagementsSeaFreightsRetrieveKey = (purchasePricesForSeaFreightsId: number,) => [`/api/v1/purchase-managements/sea-freights/${purchasePricesForSeaFreightsId}/`] as const;

    
export type PurchaseManagementsSeaFreightsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof purchaseManagementsSeaFreightsRetrieve>>>
export type PurchaseManagementsSeaFreightsRetrieveQueryError = unknown

export const usePurchaseManagementsSeaFreightsRetrieve = <TError = unknown>(
 purchasePricesForSeaFreightsId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof purchaseManagementsSeaFreightsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(purchasePricesForSeaFreightsId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getPurchaseManagementsSeaFreightsRetrieveKey(purchasePricesForSeaFreightsId) : null);
  const swrFn = () => purchaseManagementsSeaFreightsRetrieve(purchasePricesForSeaFreightsId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const purchaseManagementsSeaFreightsUpdate = (
    purchasePricesForSeaFreightsId: number,
    purchasePricesForSeaFreightsRequest: PurchasePricesForSeaFreightsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePricesForSeaFreights>(
      {url: `/api/v1/purchase-managements/sea-freights/${purchasePricesForSeaFreightsId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: purchasePricesForSeaFreightsRequest
    },
      options);
    }
  


export const purchaseManagementsSeaFreightsPartialUpdate = (
    purchasePricesForSeaFreightsId: number,
    patchedPurchasePricesForSeaFreightsRequest: PatchedPurchasePricesForSeaFreightsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePricesForSeaFreights>(
      {url: `/api/v1/purchase-managements/sea-freights/${purchasePricesForSeaFreightsId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedPurchasePricesForSeaFreightsRequest
    },
      options);
    }
  


export const purchaseManagementsSeaFreightsDestroy = (
    purchasePricesForSeaFreightsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/purchase-managements/sea-freights/${purchasePricesForSeaFreightsId}/`, method: 'delete'
    },
      options);
    }
  


export const quoteRequestsList = (
    params?: QuoteRequestsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedQuoteRequestList>(
      {url: `/api/v1/quote-requests/`, method: 'get',
        params
    },
      options);
    }
  

export const getQuoteRequestsListKey = (params?: QuoteRequestsListParams,) => [`/api/v1/quote-requests/`, ...(params ? [params]: [])] as const;

    
export type QuoteRequestsListQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsList>>>
export type QuoteRequestsListQueryError = unknown

export const useQuoteRequestsList = <TError = unknown>(
 params?: QuoteRequestsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsListKey(params) : null);
  const swrFn = () => quoteRequestsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quoteRequestsCreate = (
    quoteRequestRequest: QuoteRequestRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<QuoteRequest>(
      {url: `/api/v1/quote-requests/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: quoteRequestRequest
    },
      options);
    }
  


export const quoteRequestsRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<QuoteRequest>(
      {url: `/api/v1/quote-requests/${id}/`, method: 'get'
    },
      options);
    }
  

export const getQuoteRequestsRetrieveKey = (id: number,) => [`/api/v1/quote-requests/${id}/`] as const;

    
export type QuoteRequestsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsRetrieve>>>
export type QuoteRequestsRetrieveQueryError = unknown

export const useQuoteRequestsRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsRetrieveKey(id) : null);
  const swrFn = () => quoteRequestsRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quoteRequestsUpdate = (
    id: number,
    quoteRequestRequest: QuoteRequestRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<QuoteRequest>(
      {url: `/api/v1/quote-requests/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: quoteRequestRequest
    },
      options);
    }
  


export const quoteRequestsPartialUpdate = (
    id: number,
    patchedQuoteRequestRequest: PatchedQuoteRequestRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<QuoteRequest>(
      {url: `/api/v1/quote-requests/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedQuoteRequestRequest
    },
      options);
    }
  


export const quoteRequestsDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/quote-requests/${id}/`, method: 'delete'
    },
      options);
    }
  


/**
 * 公開済の見積のみを返します
 */
export const quoteRequestsAirQuotesList = (
    quoteRequestPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportQuote[]>(
      {url: `/api/v1/quote-requests/${quoteRequestPk}/air_quotes/`, method: 'get'
    },
      options);
    }
  

export const getQuoteRequestsAirQuotesListKey = (quoteRequestPk: number,) => [`/api/v1/quote-requests/${quoteRequestPk}/air_quotes/`] as const;

    
export type QuoteRequestsAirQuotesListQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsAirQuotesList>>>
export type QuoteRequestsAirQuotesListQueryError = unknown

export const useQuoteRequestsAirQuotesList = <TError = unknown>(
 quoteRequestPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsAirQuotesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(quoteRequestPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsAirQuotesListKey(quoteRequestPk) : null);
  const swrFn = () => quoteRequestsAirQuotesList(quoteRequestPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 公開済の見積のみを返します
 */
export const quoteRequestsAirQuotesRetrieve = (
    quoteRequestPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportQuote>(
      {url: `/api/v1/quote-requests/${quoteRequestPk}/air_quotes/${id}/`, method: 'get'
    },
      options);
    }
  

export const getQuoteRequestsAirQuotesRetrieveKey = (quoteRequestPk: number,
    id: number,) => [`/api/v1/quote-requests/${quoteRequestPk}/air_quotes/${id}/`] as const;

    
export type QuoteRequestsAirQuotesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsAirQuotesRetrieve>>>
export type QuoteRequestsAirQuotesRetrieveQueryError = unknown

export const useQuoteRequestsAirQuotesRetrieve = <TError = unknown>(
 quoteRequestPk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsAirQuotesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(quoteRequestPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsAirQuotesRetrieveKey(quoteRequestPk,id) : null);
  const swrFn = () => quoteRequestsAirQuotesRetrieve(quoteRequestPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 公開済の見積のみを返します
 */
export const quoteRequestsQuotesList = (
    quoteRequestPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Quote[]>(
      {url: `/api/v1/quote-requests/${quoteRequestPk}/quotes/`, method: 'get'
    },
      options);
    }
  

export const getQuoteRequestsQuotesListKey = (quoteRequestPk: number,) => [`/api/v1/quote-requests/${quoteRequestPk}/quotes/`] as const;

    
export type QuoteRequestsQuotesListQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsQuotesList>>>
export type QuoteRequestsQuotesListQueryError = unknown

export const useQuoteRequestsQuotesList = <TError = unknown>(
 quoteRequestPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsQuotesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(quoteRequestPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsQuotesListKey(quoteRequestPk) : null);
  const swrFn = () => quoteRequestsQuotesList(quoteRequestPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 公開済の見積のみを返します
 */
export const quoteRequestsQuotesRetrieve = (
    quoteRequestPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Quote>(
      {url: `/api/v1/quote-requests/${quoteRequestPk}/quotes/${id}/`, method: 'get'
    },
      options);
    }
  

export const getQuoteRequestsQuotesRetrieveKey = (quoteRequestPk: number,
    id: number,) => [`/api/v1/quote-requests/${quoteRequestPk}/quotes/${id}/`] as const;

    
export type QuoteRequestsQuotesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsQuotesRetrieve>>>
export type QuoteRequestsQuotesRetrieveQueryError = unknown

export const useQuoteRequestsQuotesRetrieve = <TError = unknown>(
 quoteRequestPk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsQuotesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(quoteRequestPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsQuotesRetrieveKey(quoteRequestPk,id) : null);
  const swrFn = () => quoteRequestsQuotesRetrieve(quoteRequestPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quoteRequestsAirTransportList = (
    params?: QuoteRequestsAirTransportListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedAirTransportRequestList>(
      {url: `/api/v1/quote-requests/air-transport/`, method: 'get',
        params
    },
      options);
    }
  

export const getQuoteRequestsAirTransportListKey = (params?: QuoteRequestsAirTransportListParams,) => [`/api/v1/quote-requests/air-transport/`, ...(params ? [params]: [])] as const;

    
export type QuoteRequestsAirTransportListQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsAirTransportList>>>
export type QuoteRequestsAirTransportListQueryError = unknown

export const useQuoteRequestsAirTransportList = <TError = unknown>(
 params?: QuoteRequestsAirTransportListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsAirTransportList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsAirTransportListKey(params) : null);
  const swrFn = () => quoteRequestsAirTransportList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quoteRequestsAirTransportCreate = (
    airTransportRequestRequest: AirTransportRequestRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportRequest>(
      {url: `/api/v1/quote-requests/air-transport/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: airTransportRequestRequest
    },
      options);
    }
  


export const quoteRequestsAirTransportRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportRequest>(
      {url: `/api/v1/quote-requests/air-transport/${id}/`, method: 'get'
    },
      options);
    }
  

export const getQuoteRequestsAirTransportRetrieveKey = (id: number,) => [`/api/v1/quote-requests/air-transport/${id}/`] as const;

    
export type QuoteRequestsAirTransportRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsAirTransportRetrieve>>>
export type QuoteRequestsAirTransportRetrieveQueryError = unknown

export const useQuoteRequestsAirTransportRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsAirTransportRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsAirTransportRetrieveKey(id) : null);
  const swrFn = () => quoteRequestsAirTransportRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quoteRequestsAirTransportUpdate = (
    id: number,
    airTransportRequestRequest: AirTransportRequestRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportRequest>(
      {url: `/api/v1/quote-requests/air-transport/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: airTransportRequestRequest
    },
      options);
    }
  


export const quoteRequestsAirTransportPartialUpdate = (
    id: number,
    patchedAirTransportRequestRequest: PatchedAirTransportRequestRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportRequest>(
      {url: `/api/v1/quote-requests/air-transport/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedAirTransportRequestRequest
    },
      options);
    }
  


export const quoteRequestsAirTransportDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/quote-requests/air-transport/${id}/`, method: 'delete'
    },
      options);
    }
  


export const quoteRequestsCustomsUserCompaniesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserCompanies[]>(
      {url: `/api/v1/quote-requests/customs/user-companies/`, method: 'get'
    },
      options);
    }
  

export const getQuoteRequestsCustomsUserCompaniesListKey = () => [`/api/v1/quote-requests/customs/user-companies/`] as const;

    
export type QuoteRequestsCustomsUserCompaniesListQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsCustomsUserCompaniesList>>>
export type QuoteRequestsCustomsUserCompaniesListQueryError = unknown

export const useQuoteRequestsCustomsUserCompaniesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsCustomsUserCompaniesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsCustomsUserCompaniesListKey() : null);
  const swrFn = () => quoteRequestsCustomsUserCompaniesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quoteRequestsDeliveryUserCompaniesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserCompanies[]>(
      {url: `/api/v1/quote-requests/delivery/user-companies/`, method: 'get'
    },
      options);
    }
  

export const getQuoteRequestsDeliveryUserCompaniesListKey = () => [`/api/v1/quote-requests/delivery/user-companies/`] as const;

    
export type QuoteRequestsDeliveryUserCompaniesListQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsDeliveryUserCompaniesList>>>
export type QuoteRequestsDeliveryUserCompaniesListQueryError = unknown

export const useQuoteRequestsDeliveryUserCompaniesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsDeliveryUserCompaniesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsDeliveryUserCompaniesListKey() : null);
  const swrFn = () => quoteRequestsDeliveryUserCompaniesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quoteRequestsMarineTransportList = (
    params?: QuoteRequestsMarineTransportListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedQuoteRequestForMarineTransportList>(
      {url: `/api/v1/quote-requests/marine-transport/`, method: 'get',
        params
    },
      options);
    }
  

export const getQuoteRequestsMarineTransportListKey = (params?: QuoteRequestsMarineTransportListParams,) => [`/api/v1/quote-requests/marine-transport/`, ...(params ? [params]: [])] as const;

    
export type QuoteRequestsMarineTransportListQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsMarineTransportList>>>
export type QuoteRequestsMarineTransportListQueryError = unknown

export const useQuoteRequestsMarineTransportList = <TError = unknown>(
 params?: QuoteRequestsMarineTransportListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsMarineTransportList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsMarineTransportListKey(params) : null);
  const swrFn = () => quoteRequestsMarineTransportList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quoteRequestsMarineTransportRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<QuoteRequestForMarineTransport>(
      {url: `/api/v1/quote-requests/marine-transport/${id}/`, method: 'get'
    },
      options);
    }
  

export const getQuoteRequestsMarineTransportRetrieveKey = (id: number,) => [`/api/v1/quote-requests/marine-transport/${id}/`] as const;

    
export type QuoteRequestsMarineTransportRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsMarineTransportRetrieve>>>
export type QuoteRequestsMarineTransportRetrieveQueryError = unknown

export const useQuoteRequestsMarineTransportRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsMarineTransportRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsMarineTransportRetrieveKey(id) : null);
  const swrFn = () => quoteRequestsMarineTransportRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quoteRequestsMarineTransportUpdate = (
    id: number,
    quoteRequestForMarineTransportRequest: QuoteRequestForMarineTransportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<QuoteRequestForMarineTransport>(
      {url: `/api/v1/quote-requests/marine-transport/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: quoteRequestForMarineTransportRequest
    },
      options);
    }
  


export const quoteRequestsMarineTransportPartialUpdate = (
    id: number,
    patchedQuoteRequestForMarineTransportRequest: PatchedQuoteRequestForMarineTransportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<QuoteRequestForMarineTransport>(
      {url: `/api/v1/quote-requests/marine-transport/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedQuoteRequestForMarineTransportRequest
    },
      options);
    }
  


export const quoteRequestsMarineTransportDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/quote-requests/marine-transport/${id}/`, method: 'delete'
    },
      options);
    }
  


export const quoteRequestsMarineTransportAvailableCustomPurchasesList = (
    quoteRequestForMarineTransportPk: number,
    params: QuoteRequestsMarineTransportAvailableCustomPurchasesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePricesForCustomsFees[]>(
      {url: `/api/v1/quote-requests/marine-transport/${quoteRequestForMarineTransportPk}/available-custom-purchases/`, method: 'get',
        params
    },
      options);
    }
  

export const getQuoteRequestsMarineTransportAvailableCustomPurchasesListKey = (quoteRequestForMarineTransportPk: number,
    params: QuoteRequestsMarineTransportAvailableCustomPurchasesListParams,) => [`/api/v1/quote-requests/marine-transport/${quoteRequestForMarineTransportPk}/available-custom-purchases/`, ...(params ? [params]: [])] as const;

    
export type QuoteRequestsMarineTransportAvailableCustomPurchasesListQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsMarineTransportAvailableCustomPurchasesList>>>
export type QuoteRequestsMarineTransportAvailableCustomPurchasesListQueryError = unknown

export const useQuoteRequestsMarineTransportAvailableCustomPurchasesList = <TError = unknown>(
 quoteRequestForMarineTransportPk: number,
    params: QuoteRequestsMarineTransportAvailableCustomPurchasesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsMarineTransportAvailableCustomPurchasesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(quoteRequestForMarineTransportPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsMarineTransportAvailableCustomPurchasesListKey(quoteRequestForMarineTransportPk,params) : null);
  const swrFn = () => quoteRequestsMarineTransportAvailableCustomPurchasesList(quoteRequestForMarineTransportPk,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 買値を再利用する, 買値をコピーするという動線はなくなるため削除予定です.
 * @deprecated
 */
export const quoteRequestsMarineTransportAvailablePurchasesList = (
    quoteRequestForMarineTransportPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PurchasePricesForSeaFreights[]>(
      {url: `/api/v1/quote-requests/marine-transport/${quoteRequestForMarineTransportPk}/available-purchases/`, method: 'get'
    },
      options);
    }
  

export const getQuoteRequestsMarineTransportAvailablePurchasesListKey = (quoteRequestForMarineTransportPk: number,) => [`/api/v1/quote-requests/marine-transport/${quoteRequestForMarineTransportPk}/available-purchases/`] as const;

    
export type QuoteRequestsMarineTransportAvailablePurchasesListQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsMarineTransportAvailablePurchasesList>>>
export type QuoteRequestsMarineTransportAvailablePurchasesListQueryError = unknown

/**
 * @deprecated
 */
export const useQuoteRequestsMarineTransportAvailablePurchasesList = <TError = unknown>(
 quoteRequestForMarineTransportPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsMarineTransportAvailablePurchasesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(quoteRequestForMarineTransportPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsMarineTransportAvailablePurchasesListKey(quoteRequestForMarineTransportPk) : null);
  const swrFn = () => quoteRequestsMarineTransportAvailablePurchasesList(quoteRequestForMarineTransportPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * @deprecated
 */
export const quoteRequestsMarineTransportQuotesList = (
    quoteRequestForMarineTransportPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForSeaFreights[]>(
      {url: `/api/v1/quote-requests/marine-transport/${quoteRequestForMarineTransportPk}/quotes/`, method: 'get'
    },
      options);
    }
  

export const getQuoteRequestsMarineTransportQuotesListKey = (quoteRequestForMarineTransportPk: number,) => [`/api/v1/quote-requests/marine-transport/${quoteRequestForMarineTransportPk}/quotes/`] as const;

    
export type QuoteRequestsMarineTransportQuotesListQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsMarineTransportQuotesList>>>
export type QuoteRequestsMarineTransportQuotesListQueryError = unknown

/**
 * @deprecated
 */
export const useQuoteRequestsMarineTransportQuotesList = <TError = unknown>(
 quoteRequestForMarineTransportPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsMarineTransportQuotesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(quoteRequestForMarineTransportPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsMarineTransportQuotesListKey(quoteRequestForMarineTransportPk) : null);
  const swrFn = () => quoteRequestsMarineTransportQuotesList(quoteRequestForMarineTransportPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quoteRequestsSeaFreightUserCompaniesList = (
    params?: QuoteRequestsSeaFreightUserCompaniesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserCompanies[]>(
      {url: `/api/v1/quote-requests/sea-freight/user-companies/`, method: 'get',
        params
    },
      options);
    }
  

export const getQuoteRequestsSeaFreightUserCompaniesListKey = (params?: QuoteRequestsSeaFreightUserCompaniesListParams,) => [`/api/v1/quote-requests/sea-freight/user-companies/`, ...(params ? [params]: [])] as const;

    
export type QuoteRequestsSeaFreightUserCompaniesListQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsSeaFreightUserCompaniesList>>>
export type QuoteRequestsSeaFreightUserCompaniesListQueryError = unknown

export const useQuoteRequestsSeaFreightUserCompaniesList = <TError = unknown>(
 params?: QuoteRequestsSeaFreightUserCompaniesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsSeaFreightUserCompaniesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsSeaFreightUserCompaniesListKey(params) : null);
  const swrFn = () => quoteRequestsSeaFreightUserCompaniesList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quoteRequestsTrucksList = (
    params?: QuoteRequestsTrucksListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedQuoteRequestsForTrucksList>(
      {url: `/api/v1/quote-requests/trucks/`, method: 'get',
        params
    },
      options);
    }
  

export const getQuoteRequestsTrucksListKey = (params?: QuoteRequestsTrucksListParams,) => [`/api/v1/quote-requests/trucks/`, ...(params ? [params]: [])] as const;

    
export type QuoteRequestsTrucksListQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsTrucksList>>>
export type QuoteRequestsTrucksListQueryError = unknown

export const useQuoteRequestsTrucksList = <TError = unknown>(
 params?: QuoteRequestsTrucksListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsTrucksList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsTrucksListKey(params) : null);
  const swrFn = () => quoteRequestsTrucksList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quoteRequestsTrucksRetrieve = (
    quoteRequestsForTrucksId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<QuoteRequestsForTrucks>(
      {url: `/api/v1/quote-requests/trucks/${quoteRequestsForTrucksId}/`, method: 'get'
    },
      options);
    }
  

export const getQuoteRequestsTrucksRetrieveKey = (quoteRequestsForTrucksId: number,) => [`/api/v1/quote-requests/trucks/${quoteRequestsForTrucksId}/`] as const;

    
export type QuoteRequestsTrucksRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof quoteRequestsTrucksRetrieve>>>
export type QuoteRequestsTrucksRetrieveQueryError = unknown

export const useQuoteRequestsTrucksRetrieve = <TError = unknown>(
 quoteRequestsForTrucksId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quoteRequestsTrucksRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(quoteRequestsForTrucksId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuoteRequestsTrucksRetrieveKey(quoteRequestsForTrucksId) : null);
  const swrFn = () => quoteRequestsTrucksRetrieve(quoteRequestsForTrucksId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quoteRequestsTrucksUpdate = (
    quoteRequestsForTrucksId: number,
    quoteRequestsForTrucksRequest: QuoteRequestsForTrucksRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<QuoteRequestsForTrucks>(
      {url: `/api/v1/quote-requests/trucks/${quoteRequestsForTrucksId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: quoteRequestsForTrucksRequest
    },
      options);
    }
  


export const quoteRequestsTrucksPartialUpdate = (
    quoteRequestsForTrucksId: number,
    patchedQuoteRequestsForTrucksRequest: PatchedQuoteRequestsForTrucksRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<QuoteRequestsForTrucks>(
      {url: `/api/v1/quote-requests/trucks/${quoteRequestsForTrucksId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedQuoteRequestsForTrucksRequest
    },
      options);
    }
  


export const quoteRequestsTrucksDestroy = (
    quoteRequestsForTrucksId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/quote-requests/trucks/${quoteRequestsForTrucksId}/`, method: 'delete'
    },
      options);
    }
  


export const quotesList = (
    params?: QuotesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedQuoteList>(
      {url: `/api/v1/quotes/`, method: 'get',
        params
    },
      options);
    }
  

export const getQuotesListKey = (params?: QuotesListParams,) => [`/api/v1/quotes/`, ...(params ? [params]: [])] as const;

    
export type QuotesListQueryResult = NonNullable<Awaited<ReturnType<typeof quotesList>>>
export type QuotesListQueryError = unknown

export const useQuotesList = <TError = unknown>(
 params?: QuotesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quotesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuotesListKey(params) : null);
  const swrFn = () => quotesList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quotesCreate = (
    quoteCreateRequest: QuoteCreateRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<QuoteCreate>(
      {url: `/api/v1/quotes/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: quoteCreateRequest
    },
      options);
    }
  


export const quotesRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Quote>(
      {url: `/api/v1/quotes/${id}/`, method: 'get'
    },
      options);
    }
  

export const getQuotesRetrieveKey = (id: number,) => [`/api/v1/quotes/${id}/`] as const;

    
export type QuotesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof quotesRetrieve>>>
export type QuotesRetrieveQueryError = unknown

export const useQuotesRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quotesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuotesRetrieveKey(id) : null);
  const swrFn = () => quotesRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quotesUpdate = (
    id: number,
    quoteRequest: NonReadonly<QuoteRequest>,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Quote>(
      {url: `/api/v1/quotes/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: quoteRequest
    },
      options);
    }
  


export const quotesPartialUpdate = (
    id: number,
    patchedQuoteRequest: PatchedQuoteRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Quote>(
      {url: `/api/v1/quotes/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedQuoteRequest
    },
      options);
    }
  


export const quotesDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/quotes/${id}/`, method: 'delete'
    },
      options);
    }
  


export const quotesPdfCreate = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Quote>(
      {url: `/api/v1/quotes/${id}/pdf/`, method: 'post'
    },
      options);
    }
  


export const quotesPdfDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/quotes/${id}/pdf/`, method: 'delete'
    },
      options);
    }
  


export const quotesCommodityDetailsList = (
    quotePk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CommodityDetail[]>(
      {url: `/api/v1/quotes/${quotePk}/commodity-details/`, method: 'get'
    },
      options);
    }
  

export const getQuotesCommodityDetailsListKey = (quotePk: number,) => [`/api/v1/quotes/${quotePk}/commodity-details/`] as const;

    
export type QuotesCommodityDetailsListQueryResult = NonNullable<Awaited<ReturnType<typeof quotesCommodityDetailsList>>>
export type QuotesCommodityDetailsListQueryError = unknown

export const useQuotesCommodityDetailsList = <TError = unknown>(
 quotePk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quotesCommodityDetailsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(quotePk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuotesCommodityDetailsListKey(quotePk) : null);
  const swrFn = () => quotesCommodityDetailsList(quotePk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quotesCommodityDetailsCreate = (
    quotePk: number,
    commodityDetailRequest: CommodityDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CommodityDetail>(
      {url: `/api/v1/quotes/${quotePk}/commodity-details/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: commodityDetailRequest
    },
      options);
    }
  


export const quotesCommodityDetailsRetrieve = (
    quotePk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CommodityDetail>(
      {url: `/api/v1/quotes/${quotePk}/commodity-details/${id}/`, method: 'get'
    },
      options);
    }
  

export const getQuotesCommodityDetailsRetrieveKey = (quotePk: number,
    id: number,) => [`/api/v1/quotes/${quotePk}/commodity-details/${id}/`] as const;

    
export type QuotesCommodityDetailsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof quotesCommodityDetailsRetrieve>>>
export type QuotesCommodityDetailsRetrieveQueryError = unknown

export const useQuotesCommodityDetailsRetrieve = <TError = unknown>(
 quotePk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quotesCommodityDetailsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(quotePk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuotesCommodityDetailsRetrieveKey(quotePk,id) : null);
  const swrFn = () => quotesCommodityDetailsRetrieve(quotePk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quotesCommodityDetailsUpdate = (
    quotePk: number,
    id: number,
    commodityDetailRequest: CommodityDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CommodityDetail>(
      {url: `/api/v1/quotes/${quotePk}/commodity-details/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: commodityDetailRequest
    },
      options);
    }
  


export const quotesCommodityDetailsPartialUpdate = (
    quotePk: number,
    id: number,
    patchedCommodityDetailRequest: PatchedCommodityDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CommodityDetail>(
      {url: `/api/v1/quotes/${quotePk}/commodity-details/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedCommodityDetailRequest
    },
      options);
    }
  


export const quotesCommodityDetailsDestroy = (
    quotePk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/quotes/${quotePk}/commodity-details/${id}/`, method: 'delete'
    },
      options);
    }
  


export const quotesCommodityDetailsSetCreate = (
    quotePk: number,
    commodityDetailRequest: CommodityDetailRequest[],
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CommodityDetail>(
      {url: `/api/v1/quotes/${quotePk}/commodity-details/set/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: commodityDetailRequest
    },
      options);
    }
  


export const quotesCustomClearancesList = (
    quotePk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomClearanceDetail[]>(
      {url: `/api/v1/quotes/${quotePk}/custom-clearances/`, method: 'get'
    },
      options);
    }
  

export const getQuotesCustomClearancesListKey = (quotePk: number,) => [`/api/v1/quotes/${quotePk}/custom-clearances/`] as const;

    
export type QuotesCustomClearancesListQueryResult = NonNullable<Awaited<ReturnType<typeof quotesCustomClearancesList>>>
export type QuotesCustomClearancesListQueryError = unknown

export const useQuotesCustomClearancesList = <TError = unknown>(
 quotePk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quotesCustomClearancesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(quotePk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuotesCustomClearancesListKey(quotePk) : null);
  const swrFn = () => quotesCustomClearancesList(quotePk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quotesCustomClearancesCreate = (
    quotePk: number,
    customClearanceDetailRequest: CustomClearanceDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomClearanceDetail>(
      {url: `/api/v1/quotes/${quotePk}/custom-clearances/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: customClearanceDetailRequest
    },
      options);
    }
  


export const quotesCustomClearancesRetrieve = (
    quotePk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomClearanceDetail>(
      {url: `/api/v1/quotes/${quotePk}/custom-clearances/${id}/`, method: 'get'
    },
      options);
    }
  

export const getQuotesCustomClearancesRetrieveKey = (quotePk: number,
    id: number,) => [`/api/v1/quotes/${quotePk}/custom-clearances/${id}/`] as const;

    
export type QuotesCustomClearancesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof quotesCustomClearancesRetrieve>>>
export type QuotesCustomClearancesRetrieveQueryError = unknown

export const useQuotesCustomClearancesRetrieve = <TError = unknown>(
 quotePk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quotesCustomClearancesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(quotePk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuotesCustomClearancesRetrieveKey(quotePk,id) : null);
  const swrFn = () => quotesCustomClearancesRetrieve(quotePk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quotesCustomClearancesUpdate = (
    quotePk: number,
    id: number,
    customClearanceDetailRequest: CustomClearanceDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomClearanceDetail>(
      {url: `/api/v1/quotes/${quotePk}/custom-clearances/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: customClearanceDetailRequest
    },
      options);
    }
  


export const quotesCustomClearancesPartialUpdate = (
    quotePk: number,
    id: number,
    patchedCustomClearanceDetailRequest: PatchedCustomClearanceDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomClearanceDetail>(
      {url: `/api/v1/quotes/${quotePk}/custom-clearances/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedCustomClearanceDetailRequest
    },
      options);
    }
  


export const quotesCustomClearancesDestroy = (
    quotePk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/quotes/${quotePk}/custom-clearances/${id}/`, method: 'delete'
    },
      options);
    }
  


export const quotesSelectedQuotesCreate = (
    quotePk: number,
    nestedSelectedQuoteRequest: NestedSelectedQuoteRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<NestedSelectedQuote>(
      {url: `/api/v1/quotes/${quotePk}/selected-quotes/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: nestedSelectedQuoteRequest
    },
      options);
    }
  


/**
 * 将来的に削除するので使わない
 * @deprecated
 */
export const quotesTempSelectedQuotesCreate = (
    quotePk: number,
    tempNestedSelectedQuoteRequest: TempNestedSelectedQuoteRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TempNestedSelectedQuote>(
      {url: `/api/v1/quotes/${quotePk}/temp-selected-quotes/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: tempNestedSelectedQuoteRequest
    },
      options);
    }
  


export const quotesAirTransportList = (
    params?: QuotesAirTransportListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedAirTransportQuoteList>(
      {url: `/api/v1/quotes/air-transport/`, method: 'get',
        params
    },
      options);
    }
  

export const getQuotesAirTransportListKey = (params?: QuotesAirTransportListParams,) => [`/api/v1/quotes/air-transport/`, ...(params ? [params]: [])] as const;

    
export type QuotesAirTransportListQueryResult = NonNullable<Awaited<ReturnType<typeof quotesAirTransportList>>>
export type QuotesAirTransportListQueryError = unknown

export const useQuotesAirTransportList = <TError = unknown>(
 params?: QuotesAirTransportListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quotesAirTransportList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuotesAirTransportListKey(params) : null);
  const swrFn = () => quotesAirTransportList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quotesAirTransportCreate = (
    airTransportQuoteCreateRequest: AirTransportQuoteCreateRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportQuoteCreate>(
      {url: `/api/v1/quotes/air-transport/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: airTransportQuoteCreateRequest
    },
      options);
    }
  


export const quotesAirTransportRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportQuote>(
      {url: `/api/v1/quotes/air-transport/${id}/`, method: 'get'
    },
      options);
    }
  

export const getQuotesAirTransportRetrieveKey = (id: number,) => [`/api/v1/quotes/air-transport/${id}/`] as const;

    
export type QuotesAirTransportRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof quotesAirTransportRetrieve>>>
export type QuotesAirTransportRetrieveQueryError = unknown

export const useQuotesAirTransportRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quotesAirTransportRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuotesAirTransportRetrieveKey(id) : null);
  const swrFn = () => quotesAirTransportRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quotesAirTransportUpdate = (
    id: number,
    airTransportQuoteRequest: AirTransportQuoteRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportQuote>(
      {url: `/api/v1/quotes/air-transport/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: airTransportQuoteRequest
    },
      options);
    }
  


export const quotesAirTransportPartialUpdate = (
    id: number,
    patchedAirTransportQuoteRequest: PatchedAirTransportQuoteRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportQuote>(
      {url: `/api/v1/quotes/air-transport/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedAirTransportQuoteRequest
    },
      options);
    }
  


export const quotesAirTransportDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/quotes/air-transport/${id}/`, method: 'delete'
    },
      options);
    }
  


export const quotesFromPriceManagementCreate = (
    fromPriceManagementRequest: FromPriceManagementRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<FromPriceManagement>(
      {url: `/api/v1/quotes/from-price-management/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: fromPriceManagementRequest
    },
      options);
    }
  


export const quotesFromPriceManagementUpdate = (
    id: number,
    fromPriceManagementRequest: FromPriceManagementRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<FromPriceManagement>(
      {url: `/api/v1/quotes/from-price-management/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: fromPriceManagementRequest
    },
      options);
    }
  


export const quotesFromPriceManagementPartialUpdate = (
    id: number,
    patchedFromPriceManagementRequest: PatchedFromPriceManagementRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<FromPriceManagement>(
      {url: `/api/v1/quotes/from-price-management/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedFromPriceManagementRequest
    },
      options);
    }
  


/**
 * Quote に統合されるため廃止予定です.
 * @deprecated
 */
export const quotesMarineTransportCreate = (
    marineTransportQuoteCreateRequest: MarineTransportQuoteCreateRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MarineTransportQuoteCreate>(
      {url: `/api/v1/quotes/marine-transport/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: marineTransportQuoteCreateRequest
    },
      options);
    }
  


export const quotesMarineTransportAvailableSchedulesList = (
    quotePk: number,
    params?: QuotesMarineTransportAvailableSchedulesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MarineTransportFixedSchedules[]>(
      {url: `/api/v1/quotes/marine-transport/${quotePk}/available-schedules/`, method: 'get',
        params
    },
      options);
    }
  

export const getQuotesMarineTransportAvailableSchedulesListKey = (quotePk: number,
    params?: QuotesMarineTransportAvailableSchedulesListParams,) => [`/api/v1/quotes/marine-transport/${quotePk}/available-schedules/`, ...(params ? [params]: [])] as const;

    
export type QuotesMarineTransportAvailableSchedulesListQueryResult = NonNullable<Awaited<ReturnType<typeof quotesMarineTransportAvailableSchedulesList>>>
export type QuotesMarineTransportAvailableSchedulesListQueryError = unknown

export const useQuotesMarineTransportAvailableSchedulesList = <TError = unknown>(
 quotePk: number,
    params?: QuotesMarineTransportAvailableSchedulesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quotesMarineTransportAvailableSchedulesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(quotePk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuotesMarineTransportAvailableSchedulesListKey(quotePk,params) : null);
  const swrFn = () => quotesMarineTransportAvailableSchedulesList(quotePk,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quotesSimplifiedList = (
    params?: QuotesSimplifiedListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SimplifiedQuote[]>(
      {url: `/api/v1/quotes/simplified/`, method: 'get',
        params
    },
      options);
    }
  

export const getQuotesSimplifiedListKey = (params?: QuotesSimplifiedListParams,) => [`/api/v1/quotes/simplified/`, ...(params ? [params]: [])] as const;

    
export type QuotesSimplifiedListQueryResult = NonNullable<Awaited<ReturnType<typeof quotesSimplifiedList>>>
export type QuotesSimplifiedListQueryError = unknown

export const useQuotesSimplifiedList = <TError = unknown>(
 params?: QuotesSimplifiedListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quotesSimplifiedList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuotesSimplifiedListKey(params) : null);
  const swrFn = () => quotesSimplifiedList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const quotesSimplifiedRetrieve = (
    id: number,
    params?: QuotesSimplifiedRetrieveParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SimplifiedQuote>(
      {url: `/api/v1/quotes/simplified/${id}/`, method: 'get',
        params
    },
      options);
    }
  

export const getQuotesSimplifiedRetrieveKey = (id: number,
    params?: QuotesSimplifiedRetrieveParams,) => [`/api/v1/quotes/simplified/${id}/`, ...(params ? [params]: [])] as const;

    
export type QuotesSimplifiedRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof quotesSimplifiedRetrieve>>>
export type QuotesSimplifiedRetrieveQueryError = unknown

export const useQuotesSimplifiedRetrieve = <TError = unknown>(
 id: number,
    params?: QuotesSimplifiedRetrieveParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof quotesSimplifiedRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getQuotesSimplifiedRetrieveKey(id,params) : null);
  const swrFn = () => quotesSimplifiedRetrieve(id,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * OpenApi3 schema for this API. Format can be selected via content negotiation.

- YAML: application/vnd.oai.openapi
- JSON: application/vnd.oai.openapi+json
 */
export const schemaRetrieve = (
    params?: SchemaRetrieveParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SchemaRetrieve200One | SchemaRetrieve200Two | SchemaRetrieve200Three | SchemaRetrieve200Four>(
      {url: `/api/v1/schema/`, method: 'get',
        params
    },
      options);
    }
  

export const getSchemaRetrieveKey = (params?: SchemaRetrieveParams,) => [`/api/v1/schema/`, ...(params ? [params]: [])] as const;

    
export type SchemaRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof schemaRetrieve>>>
export type SchemaRetrieveQueryError = unknown

export const useSchemaRetrieve = <TError = unknown>(
 params?: SchemaRetrieveParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof schemaRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSchemaRetrieveKey(params) : null);
  const swrFn = () => schemaRetrieve(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const seaRatesReferencesUpdate = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/sea-rates/references/${id}/`, method: 'put'
    },
      options);
    }
  


export const sellingManagementsAirTransportList = (
    params?: SellingManagementsAirTransportListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedSellingPriceForAirTransportList>(
      {url: `/api/v1/selling-managements/air-transport/`, method: 'get',
        params
    },
      options);
    }
  

export const getSellingManagementsAirTransportListKey = (params?: SellingManagementsAirTransportListParams,) => [`/api/v1/selling-managements/air-transport/`, ...(params ? [params]: [])] as const;

    
export type SellingManagementsAirTransportListQueryResult = NonNullable<Awaited<ReturnType<typeof sellingManagementsAirTransportList>>>
export type SellingManagementsAirTransportListQueryError = unknown

export const useSellingManagementsAirTransportList = <TError = unknown>(
 params?: SellingManagementsAirTransportListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof sellingManagementsAirTransportList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSellingManagementsAirTransportListKey(params) : null);
  const swrFn = () => sellingManagementsAirTransportList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const sellingManagementsAirTransportCreate = (
    sellingPriceForAirTransportRequest: SellingPriceForAirTransportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPriceForAirTransport>(
      {url: `/api/v1/selling-managements/air-transport/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sellingPriceForAirTransportRequest
    },
      options);
    }
  


export const sellingManagementsAirTransportRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPriceForAirTransport>(
      {url: `/api/v1/selling-managements/air-transport/${id}/`, method: 'get'
    },
      options);
    }
  

export const getSellingManagementsAirTransportRetrieveKey = (id: number,) => [`/api/v1/selling-managements/air-transport/${id}/`] as const;

    
export type SellingManagementsAirTransportRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof sellingManagementsAirTransportRetrieve>>>
export type SellingManagementsAirTransportRetrieveQueryError = unknown

export const useSellingManagementsAirTransportRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof sellingManagementsAirTransportRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSellingManagementsAirTransportRetrieveKey(id) : null);
  const swrFn = () => sellingManagementsAirTransportRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const sellingManagementsAirTransportUpdate = (
    id: number,
    sellingPriceForAirTransportRequest: SellingPriceForAirTransportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPriceForAirTransport>(
      {url: `/api/v1/selling-managements/air-transport/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: sellingPriceForAirTransportRequest
    },
      options);
    }
  


export const sellingManagementsAirTransportPartialUpdate = (
    id: number,
    patchedSellingPriceForAirTransportRequest: PatchedSellingPriceForAirTransportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPriceForAirTransport>(
      {url: `/api/v1/selling-managements/air-transport/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedSellingPriceForAirTransportRequest
    },
      options);
    }
  


export const sellingManagementsAirTransportDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/selling-managements/air-transport/${id}/`, method: 'delete'
    },
      options);
    }
  


export const sellingManagementsCustomsFeesList = (
    params?: SellingManagementsCustomsFeesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedSellingPricesForCustomsFeesList>(
      {url: `/api/v1/selling-managements/customs-fees/`, method: 'get',
        params
    },
      options);
    }
  

export const getSellingManagementsCustomsFeesListKey = (params?: SellingManagementsCustomsFeesListParams,) => [`/api/v1/selling-managements/customs-fees/`, ...(params ? [params]: [])] as const;

    
export type SellingManagementsCustomsFeesListQueryResult = NonNullable<Awaited<ReturnType<typeof sellingManagementsCustomsFeesList>>>
export type SellingManagementsCustomsFeesListQueryError = unknown

export const useSellingManagementsCustomsFeesList = <TError = unknown>(
 params?: SellingManagementsCustomsFeesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof sellingManagementsCustomsFeesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSellingManagementsCustomsFeesListKey(params) : null);
  const swrFn = () => sellingManagementsCustomsFeesList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const sellingManagementsCustomsFeesCreate = (
    sellingPricesForCustomsFeesRequest: SellingPricesForCustomsFeesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForCustomsFees>(
      {url: `/api/v1/selling-managements/customs-fees/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sellingPricesForCustomsFeesRequest
    },
      options);
    }
  


export const sellingManagementsCustomsFeesRetrieve = (
    sellingPricesForCustomsFeesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForCustomsFees>(
      {url: `/api/v1/selling-managements/customs-fees/${sellingPricesForCustomsFeesId}/`, method: 'get'
    },
      options);
    }
  

export const getSellingManagementsCustomsFeesRetrieveKey = (sellingPricesForCustomsFeesId: number,) => [`/api/v1/selling-managements/customs-fees/${sellingPricesForCustomsFeesId}/`] as const;

    
export type SellingManagementsCustomsFeesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof sellingManagementsCustomsFeesRetrieve>>>
export type SellingManagementsCustomsFeesRetrieveQueryError = unknown

export const useSellingManagementsCustomsFeesRetrieve = <TError = unknown>(
 sellingPricesForCustomsFeesId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof sellingManagementsCustomsFeesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(sellingPricesForCustomsFeesId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSellingManagementsCustomsFeesRetrieveKey(sellingPricesForCustomsFeesId) : null);
  const swrFn = () => sellingManagementsCustomsFeesRetrieve(sellingPricesForCustomsFeesId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const sellingManagementsCustomsFeesUpdate = (
    sellingPricesForCustomsFeesId: number,
    sellingPricesForCustomsFeesRequest: SellingPricesForCustomsFeesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForCustomsFees>(
      {url: `/api/v1/selling-managements/customs-fees/${sellingPricesForCustomsFeesId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: sellingPricesForCustomsFeesRequest
    },
      options);
    }
  


export const sellingManagementsCustomsFeesPartialUpdate = (
    sellingPricesForCustomsFeesId: number,
    patchedSellingPricesForCustomsFeesRequest: PatchedSellingPricesForCustomsFeesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForCustomsFees>(
      {url: `/api/v1/selling-managements/customs-fees/${sellingPricesForCustomsFeesId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedSellingPricesForCustomsFeesRequest
    },
      options);
    }
  


export const sellingManagementsCustomsFeesDestroy = (
    sellingPricesForCustomsFeesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/selling-managements/customs-fees/${sellingPricesForCustomsFeesId}/`, method: 'delete'
    },
      options);
    }
  


export const sellingManagementsCustomsFeesWithPurchaseCreate = (
    sellingPricesForCustomsFeesWithPurchaseRequest: SellingPricesForCustomsFeesWithPurchaseRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForCustomsFees>(
      {url: `/api/v1/selling-managements/customs-fees/with-purchase/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sellingPricesForCustomsFeesWithPurchaseRequest
    },
      options);
    }
  


export const sellingManagementsLandFreightsList = (
    params?: SellingManagementsLandFreightsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedSellingPricesForLandFreightsList>(
      {url: `/api/v1/selling-managements/land-freights/`, method: 'get',
        params
    },
      options);
    }
  

export const getSellingManagementsLandFreightsListKey = (params?: SellingManagementsLandFreightsListParams,) => [`/api/v1/selling-managements/land-freights/`, ...(params ? [params]: [])] as const;

    
export type SellingManagementsLandFreightsListQueryResult = NonNullable<Awaited<ReturnType<typeof sellingManagementsLandFreightsList>>>
export type SellingManagementsLandFreightsListQueryError = unknown

export const useSellingManagementsLandFreightsList = <TError = unknown>(
 params?: SellingManagementsLandFreightsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof sellingManagementsLandFreightsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSellingManagementsLandFreightsListKey(params) : null);
  const swrFn = () => sellingManagementsLandFreightsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const sellingManagementsLandFreightsCreate = (
    sellingPricesForLandFreightsRequest: SellingPricesForLandFreightsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForLandFreights>(
      {url: `/api/v1/selling-managements/land-freights/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sellingPricesForLandFreightsRequest
    },
      options);
    }
  


export const sellingManagementsLandFreightsRetrieve = (
    sellingPricesForLandFreightsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForLandFreights>(
      {url: `/api/v1/selling-managements/land-freights/${sellingPricesForLandFreightsId}/`, method: 'get'
    },
      options);
    }
  

export const getSellingManagementsLandFreightsRetrieveKey = (sellingPricesForLandFreightsId: number,) => [`/api/v1/selling-managements/land-freights/${sellingPricesForLandFreightsId}/`] as const;

    
export type SellingManagementsLandFreightsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof sellingManagementsLandFreightsRetrieve>>>
export type SellingManagementsLandFreightsRetrieveQueryError = unknown

export const useSellingManagementsLandFreightsRetrieve = <TError = unknown>(
 sellingPricesForLandFreightsId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof sellingManagementsLandFreightsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(sellingPricesForLandFreightsId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSellingManagementsLandFreightsRetrieveKey(sellingPricesForLandFreightsId) : null);
  const swrFn = () => sellingManagementsLandFreightsRetrieve(sellingPricesForLandFreightsId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const sellingManagementsLandFreightsUpdate = (
    sellingPricesForLandFreightsId: number,
    sellingPricesForLandFreightsRequest: SellingPricesForLandFreightsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForLandFreights>(
      {url: `/api/v1/selling-managements/land-freights/${sellingPricesForLandFreightsId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: sellingPricesForLandFreightsRequest
    },
      options);
    }
  


export const sellingManagementsLandFreightsPartialUpdate = (
    sellingPricesForLandFreightsId: number,
    patchedSellingPricesForLandFreightsRequest: PatchedSellingPricesForLandFreightsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForLandFreights>(
      {url: `/api/v1/selling-managements/land-freights/${sellingPricesForLandFreightsId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedSellingPricesForLandFreightsRequest
    },
      options);
    }
  


export const sellingManagementsLandFreightsDestroy = (
    sellingPricesForLandFreightsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/selling-managements/land-freights/${sellingPricesForLandFreightsId}/`, method: 'delete'
    },
      options);
    }
  


export const sellingManagementsLandFreightsWithPurchaseCreate = (
    sellingPricesForLandFreightsWithPurchaseRequest: SellingPricesForLandFreightsWithPurchaseRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForLandFreightsWithPurchase>(
      {url: `/api/v1/selling-managements/land-freights/with-purchase/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sellingPricesForLandFreightsWithPurchaseRequest
    },
      options);
    }
  


export const sellingManagementsLandTransportAsPriceManagementList = (
    params?: SellingManagementsLandTransportAsPriceManagementListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedLandTransportProfitMarginList>(
      {url: `/api/v1/selling-managements/land-transport/as-price-management/`, method: 'get',
        params
    },
      options);
    }
  

export const getSellingManagementsLandTransportAsPriceManagementListKey = (params?: SellingManagementsLandTransportAsPriceManagementListParams,) => [`/api/v1/selling-managements/land-transport/as-price-management/`, ...(params ? [params]: [])] as const;

    
export type SellingManagementsLandTransportAsPriceManagementListQueryResult = NonNullable<Awaited<ReturnType<typeof sellingManagementsLandTransportAsPriceManagementList>>>
export type SellingManagementsLandTransportAsPriceManagementListQueryError = unknown

export const useSellingManagementsLandTransportAsPriceManagementList = <TError = unknown>(
 params?: SellingManagementsLandTransportAsPriceManagementListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof sellingManagementsLandTransportAsPriceManagementList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSellingManagementsLandTransportAsPriceManagementListKey(params) : null);
  const swrFn = () => sellingManagementsLandTransportAsPriceManagementList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const sellingManagementsLandTransportAsPriceManagementCreate = (
    landTransportProfitMarginRequest: LandTransportProfitMarginRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<LandTransportProfitMargin>(
      {url: `/api/v1/selling-managements/land-transport/as-price-management/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: landTransportProfitMarginRequest
    },
      options);
    }
  


export const sellingManagementsLandTransportAsPriceManagementRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<LandTransportProfitMargin>(
      {url: `/api/v1/selling-managements/land-transport/as-price-management/${id}/`, method: 'get'
    },
      options);
    }
  

export const getSellingManagementsLandTransportAsPriceManagementRetrieveKey = (id: number,) => [`/api/v1/selling-managements/land-transport/as-price-management/${id}/`] as const;

    
export type SellingManagementsLandTransportAsPriceManagementRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof sellingManagementsLandTransportAsPriceManagementRetrieve>>>
export type SellingManagementsLandTransportAsPriceManagementRetrieveQueryError = unknown

export const useSellingManagementsLandTransportAsPriceManagementRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof sellingManagementsLandTransportAsPriceManagementRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSellingManagementsLandTransportAsPriceManagementRetrieveKey(id) : null);
  const swrFn = () => sellingManagementsLandTransportAsPriceManagementRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const sellingManagementsLandTransportAsPriceManagementUpdate = (
    id: number,
    landTransportProfitMarginRequest: LandTransportProfitMarginRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<LandTransportProfitMargin>(
      {url: `/api/v1/selling-managements/land-transport/as-price-management/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: landTransportProfitMarginRequest
    },
      options);
    }
  


export const sellingManagementsLandTransportAsPriceManagementPartialUpdate = (
    id: number,
    patchedLandTransportProfitMarginRequest: PatchedLandTransportProfitMarginRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<LandTransportProfitMargin>(
      {url: `/api/v1/selling-managements/land-transport/as-price-management/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedLandTransportProfitMarginRequest
    },
      options);
    }
  


export const sellingManagementsLandTransportAsPriceManagementDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/selling-managements/land-transport/as-price-management/${id}/`, method: 'delete'
    },
      options);
    }
  


/**
 * @deprecated
 */
export const sellingManagementsSeaFreightsList = (
    params?: SellingManagementsSeaFreightsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedSellingPricesForSeaFreightsList>(
      {url: `/api/v1/selling-managements/sea-freights/`, method: 'get',
        params
    },
      options);
    }
  

export const getSellingManagementsSeaFreightsListKey = (params?: SellingManagementsSeaFreightsListParams,) => [`/api/v1/selling-managements/sea-freights/`, ...(params ? [params]: [])] as const;

    
export type SellingManagementsSeaFreightsListQueryResult = NonNullable<Awaited<ReturnType<typeof sellingManagementsSeaFreightsList>>>
export type SellingManagementsSeaFreightsListQueryError = unknown

/**
 * @deprecated
 */
export const useSellingManagementsSeaFreightsList = <TError = unknown>(
 params?: SellingManagementsSeaFreightsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof sellingManagementsSeaFreightsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSellingManagementsSeaFreightsListKey(params) : null);
  const swrFn = () => sellingManagementsSeaFreightsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * @deprecated
 */
export const sellingManagementsSeaFreightsCreate = (
    sellingPricesForSeaFreightsRequest: SellingPricesForSeaFreightsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForSeaFreights>(
      {url: `/api/v1/selling-managements/sea-freights/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sellingPricesForSeaFreightsRequest
    },
      options);
    }
  


/**
 * @deprecated
 */
export const sellingManagementsSeaFreightsRetrieve = (
    sellingPricesForSeaFreightsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForSeaFreights>(
      {url: `/api/v1/selling-managements/sea-freights/${sellingPricesForSeaFreightsId}/`, method: 'get'
    },
      options);
    }
  

export const getSellingManagementsSeaFreightsRetrieveKey = (sellingPricesForSeaFreightsId: number,) => [`/api/v1/selling-managements/sea-freights/${sellingPricesForSeaFreightsId}/`] as const;

    
export type SellingManagementsSeaFreightsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof sellingManagementsSeaFreightsRetrieve>>>
export type SellingManagementsSeaFreightsRetrieveQueryError = unknown

/**
 * @deprecated
 */
export const useSellingManagementsSeaFreightsRetrieve = <TError = unknown>(
 sellingPricesForSeaFreightsId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof sellingManagementsSeaFreightsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(sellingPricesForSeaFreightsId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getSellingManagementsSeaFreightsRetrieveKey(sellingPricesForSeaFreightsId) : null);
  const swrFn = () => sellingManagementsSeaFreightsRetrieve(sellingPricesForSeaFreightsId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * @deprecated
 */
export const sellingManagementsSeaFreightsUpdate = (
    sellingPricesForSeaFreightsId: number,
    sellingPricesForSeaFreightsRequest: SellingPricesForSeaFreightsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForSeaFreights>(
      {url: `/api/v1/selling-managements/sea-freights/${sellingPricesForSeaFreightsId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: sellingPricesForSeaFreightsRequest
    },
      options);
    }
  


/**
 * @deprecated
 */
export const sellingManagementsSeaFreightsPartialUpdate = (
    sellingPricesForSeaFreightsId: number,
    patchedSellingPricesForSeaFreightsRequest: PatchedSellingPricesForSeaFreightsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForSeaFreights>(
      {url: `/api/v1/selling-managements/sea-freights/${sellingPricesForSeaFreightsId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedSellingPricesForSeaFreightsRequest
    },
      options);
    }
  


/**
 * @deprecated
 */
export const sellingManagementsSeaFreightsDestroy = (
    sellingPricesForSeaFreightsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/selling-managements/sea-freights/${sellingPricesForSeaFreightsId}/`, method: 'delete'
    },
      options);
    }
  


export const shipmentsList = (
    params?: ShipmentsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedShipmentsList>(
      {url: `/api/v1/shipments/`, method: 'get',
        params
    },
      options);
    }
  

export const getShipmentsListKey = (params?: ShipmentsListParams,) => [`/api/v1/shipments/`, ...(params ? [params]: [])] as const;

    
export type ShipmentsListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsList>>>
export type ShipmentsListQueryError = unknown

export const useShipmentsList = <TError = unknown>(
 params?: ShipmentsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsListKey(params) : null);
  const swrFn = () => shipmentsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsCreate = (
    shipmentsRequest: ShipmentsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Shipments>(
      {url: `/api/v1/shipments/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: shipmentsRequest
    },
      options);
    }
  


export const shipmentsRetrieve = (
    shipmentsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Shipments>(
      {url: `/api/v1/shipments/${shipmentsId}/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsRetrieveKey = (shipmentsId: number,) => [`/api/v1/shipments/${shipmentsId}/`] as const;

    
export type ShipmentsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsRetrieve>>>
export type ShipmentsRetrieveQueryError = unknown

export const useShipmentsRetrieve = <TError = unknown>(
 shipmentsId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentsId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsRetrieveKey(shipmentsId) : null);
  const swrFn = () => shipmentsRetrieve(shipmentsId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsUpdate = (
    shipmentsId: number,
    shipmentsRequest: ShipmentsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Shipments>(
      {url: `/api/v1/shipments/${shipmentsId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: shipmentsRequest
    },
      options);
    }
  


export const shipmentsPartialUpdate = (
    shipmentsId: number,
    patchedShipmentsRequest: PatchedShipmentsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Shipments>(
      {url: `/api/v1/shipments/${shipmentsId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedShipmentsRequest
    },
      options);
    }
  


export const shipmentsDestroy = (
    shipmentsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentsId}/`, method: 'delete'
    },
      options);
    }
  


export const shipmentsCurrentScheduleRetrieve = (
    shipmentsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Vessels>(
      {url: `/api/v1/shipments/${shipmentsId}/current-schedule/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsCurrentScheduleRetrieveKey = (shipmentsId: number,) => [`/api/v1/shipments/${shipmentsId}/current-schedule/`] as const;

    
export type ShipmentsCurrentScheduleRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsCurrentScheduleRetrieve>>>
export type ShipmentsCurrentScheduleRetrieveQueryError = unknown

export const useShipmentsCurrentScheduleRetrieve = <TError = unknown>(
 shipmentsId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsCurrentScheduleRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentsId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsCurrentScheduleRetrieveKey(shipmentsId) : null);
  const swrFn = () => shipmentsCurrentScheduleRetrieve(shipmentsId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsQuoteRetrieve = (
    shipmentsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SelectedQuote>(
      {url: `/api/v1/shipments/${shipmentsId}/quote/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsQuoteRetrieveKey = (shipmentsId: number,) => [`/api/v1/shipments/${shipmentsId}/quote/`] as const;

    
export type ShipmentsQuoteRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsQuoteRetrieve>>>
export type ShipmentsQuoteRetrieveQueryError = unknown

export const useShipmentsQuoteRetrieve = <TError = unknown>(
 shipmentsId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsQuoteRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentsId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsQuoteRetrieveKey(shipmentsId) : null);
  const swrFn = () => shipmentsQuoteRetrieve(shipmentsId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsAirTransportCargoCreate = (
    shipmentPk: number,
    airTransportCargoRequest: AirTransportCargoRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportCargo>(
      {url: `/api/v1/shipments/${shipmentPk}/air-transport-cargo/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: airTransportCargoRequest
    },
      options);
    }
  


export const shipmentsAirTransportCargoUpdate = (
    shipmentPk: number,
    id: number,
    airTransportCargoRequest: AirTransportCargoRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportCargo>(
      {url: `/api/v1/shipments/${shipmentPk}/air-transport-cargo/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: airTransportCargoRequest
    },
      options);
    }
  


export const shipmentsAirTransportCargoPartialUpdate = (
    shipmentPk: number,
    id: number,
    patchedAirTransportCargoRequest: PatchedAirTransportCargoRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportCargo>(
      {url: `/api/v1/shipments/${shipmentPk}/air-transport-cargo/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedAirTransportCargoRequest
    },
      options);
    }
  


export const shipmentsArrivalNoticesList = (
    shipmentPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ArrivalNotices[]>(
      {url: `/api/v1/shipments/${shipmentPk}/arrival-notices/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsArrivalNoticesListKey = (shipmentPk: number,) => [`/api/v1/shipments/${shipmentPk}/arrival-notices/`] as const;

    
export type ShipmentsArrivalNoticesListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsArrivalNoticesList>>>
export type ShipmentsArrivalNoticesListQueryError = unknown

export const useShipmentsArrivalNoticesList = <TError = unknown>(
 shipmentPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsArrivalNoticesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsArrivalNoticesListKey(shipmentPk) : null);
  const swrFn = () => shipmentsArrivalNoticesList(shipmentPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsArrivalNoticesCreate = (
    shipmentPk: number,
    arrivalNoticesRequest: ArrivalNoticesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ArrivalNotices>(
      {url: `/api/v1/shipments/${shipmentPk}/arrival-notices/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: arrivalNoticesRequest
    },
      options);
    }
  


export const shipmentsArrivalNoticesRetrieve = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ArrivalNotices>(
      {url: `/api/v1/shipments/${shipmentPk}/arrival-notices/${id}/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsArrivalNoticesRetrieveKey = (shipmentPk: number,
    id: number,) => [`/api/v1/shipments/${shipmentPk}/arrival-notices/${id}/`] as const;

    
export type ShipmentsArrivalNoticesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsArrivalNoticesRetrieve>>>
export type ShipmentsArrivalNoticesRetrieveQueryError = unknown

export const useShipmentsArrivalNoticesRetrieve = <TError = unknown>(
 shipmentPk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsArrivalNoticesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsArrivalNoticesRetrieveKey(shipmentPk,id) : null);
  const swrFn = () => shipmentsArrivalNoticesRetrieve(shipmentPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsArrivalNoticesUpdate = (
    shipmentPk: number,
    id: number,
    arrivalNoticesRequest: ArrivalNoticesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ArrivalNotices>(
      {url: `/api/v1/shipments/${shipmentPk}/arrival-notices/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: arrivalNoticesRequest
    },
      options);
    }
  


export const shipmentsArrivalNoticesPartialUpdate = (
    shipmentPk: number,
    id: number,
    patchedArrivalNoticesRequest: PatchedArrivalNoticesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ArrivalNotices>(
      {url: `/api/v1/shipments/${shipmentPk}/arrival-notices/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedArrivalNoticesRequest
    },
      options);
    }
  


export const shipmentsArrivalNoticesDestroy = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/arrival-notices/${id}/`, method: 'delete'
    },
      options);
    }
  


export const shipmentsBookingNoteList = (
    shipmentPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BookingNote[]>(
      {url: `/api/v1/shipments/${shipmentPk}/booking-note/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsBookingNoteListKey = (shipmentPk: number,) => [`/api/v1/shipments/${shipmentPk}/booking-note/`] as const;

    
export type ShipmentsBookingNoteListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsBookingNoteList>>>
export type ShipmentsBookingNoteListQueryError = unknown

export const useShipmentsBookingNoteList = <TError = unknown>(
 shipmentPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsBookingNoteList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsBookingNoteListKey(shipmentPk) : null);
  const swrFn = () => shipmentsBookingNoteList(shipmentPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsBookingNoteCreate = (
    shipmentPk: number,
    bookingNoteRequest: BookingNoteRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BookingNote>(
      {url: `/api/v1/shipments/${shipmentPk}/booking-note/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: bookingNoteRequest
    },
      options);
    }
  


export const shipmentsBookingNoteRetrieve = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BookingNote>(
      {url: `/api/v1/shipments/${shipmentPk}/booking-note/${id}/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsBookingNoteRetrieveKey = (shipmentPk: number,
    id: number,) => [`/api/v1/shipments/${shipmentPk}/booking-note/${id}/`] as const;

    
export type ShipmentsBookingNoteRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsBookingNoteRetrieve>>>
export type ShipmentsBookingNoteRetrieveQueryError = unknown

export const useShipmentsBookingNoteRetrieve = <TError = unknown>(
 shipmentPk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsBookingNoteRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsBookingNoteRetrieveKey(shipmentPk,id) : null);
  const swrFn = () => shipmentsBookingNoteRetrieve(shipmentPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsBookingNoteUpdate = (
    shipmentPk: number,
    id: number,
    bookingNoteRequest: BookingNoteRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BookingNote>(
      {url: `/api/v1/shipments/${shipmentPk}/booking-note/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: bookingNoteRequest
    },
      options);
    }
  


export const shipmentsBookingNotePartialUpdate = (
    shipmentPk: number,
    id: number,
    patchedBookingNoteRequest: PatchedBookingNoteRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BookingNote>(
      {url: `/api/v1/shipments/${shipmentPk}/booking-note/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedBookingNoteRequest
    },
      options);
    }
  


export const shipmentsBookingNoteDestroy = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/booking-note/${id}/`, method: 'delete'
    },
      options);
    }
  


export const shipmentsBookmarksCreate = (
    shipmentPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentBookmarks>(
      {url: `/api/v1/shipments/${shipmentPk}/bookmarks/`, method: 'post'
    },
      options);
    }
  


export const shipmentsBookmarksDestroy = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/bookmarks/${id}/`, method: 'delete'
    },
      options);
    }
  


export const shipmentsContainersList = (
    shipmentPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Container[]>(
      {url: `/api/v1/shipments/${shipmentPk}/containers/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsContainersListKey = (shipmentPk: number,) => [`/api/v1/shipments/${shipmentPk}/containers/`] as const;

    
export type ShipmentsContainersListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsContainersList>>>
export type ShipmentsContainersListQueryError = unknown

export const useShipmentsContainersList = <TError = unknown>(
 shipmentPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsContainersList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsContainersListKey(shipmentPk) : null);
  const swrFn = () => shipmentsContainersList(shipmentPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsContainersCreate = (
    shipmentPk: number,
    containerRequest: ContainerRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Container>(
      {url: `/api/v1/shipments/${shipmentPk}/containers/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: containerRequest
    },
      options);
    }
  


export const shipmentsContainersUpdate = (
    shipmentPk: number,
    id: number,
    containerRequest: ContainerRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Container>(
      {url: `/api/v1/shipments/${shipmentPk}/containers/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: containerRequest
    },
      options);
    }
  


export const shipmentsContainersPartialUpdate = (
    shipmentPk: number,
    id: number,
    patchedContainerRequest: PatchedContainerRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Container>(
      {url: `/api/v1/shipments/${shipmentPk}/containers/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedContainerRequest
    },
      options);
    }
  


export const shipmentsContainersDestroy = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/containers/${id}/`, method: 'delete'
    },
      options);
    }
  


export const shipmentsContainersRegenerateCreate = (
    shipmentPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/containers/regenerate/`, method: 'post'
    },
      options);
    }
  


export const shipmentsCustomsClearanceRequestList = (
    shipmentPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsClearanceRequest[]>(
      {url: `/api/v1/shipments/${shipmentPk}/customs-clearance-request/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsCustomsClearanceRequestListKey = (shipmentPk: number,) => [`/api/v1/shipments/${shipmentPk}/customs-clearance-request/`] as const;

    
export type ShipmentsCustomsClearanceRequestListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsCustomsClearanceRequestList>>>
export type ShipmentsCustomsClearanceRequestListQueryError = unknown

export const useShipmentsCustomsClearanceRequestList = <TError = unknown>(
 shipmentPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsCustomsClearanceRequestList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsCustomsClearanceRequestListKey(shipmentPk) : null);
  const swrFn = () => shipmentsCustomsClearanceRequestList(shipmentPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsCustomsClearanceRequestCreate = (
    shipmentPk: number,
    customsClearanceRequestRequest: CustomsClearanceRequestRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsClearanceRequest>(
      {url: `/api/v1/shipments/${shipmentPk}/customs-clearance-request/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: customsClearanceRequestRequest
    },
      options);
    }
  


export const shipmentsCustomsClearanceRequestRetrieve = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsClearanceRequest>(
      {url: `/api/v1/shipments/${shipmentPk}/customs-clearance-request/${id}/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsCustomsClearanceRequestRetrieveKey = (shipmentPk: number,
    id: number,) => [`/api/v1/shipments/${shipmentPk}/customs-clearance-request/${id}/`] as const;

    
export type ShipmentsCustomsClearanceRequestRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsCustomsClearanceRequestRetrieve>>>
export type ShipmentsCustomsClearanceRequestRetrieveQueryError = unknown

export const useShipmentsCustomsClearanceRequestRetrieve = <TError = unknown>(
 shipmentPk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsCustomsClearanceRequestRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsCustomsClearanceRequestRetrieveKey(shipmentPk,id) : null);
  const swrFn = () => shipmentsCustomsClearanceRequestRetrieve(shipmentPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsCustomsClearanceRequestUpdate = (
    shipmentPk: number,
    id: number,
    customsClearanceRequestRequest: CustomsClearanceRequestRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsClearanceRequest>(
      {url: `/api/v1/shipments/${shipmentPk}/customs-clearance-request/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: customsClearanceRequestRequest
    },
      options);
    }
  


export const shipmentsCustomsClearanceRequestPartialUpdate = (
    shipmentPk: number,
    id: number,
    patchedCustomsClearanceRequestRequest: PatchedCustomsClearanceRequestRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsClearanceRequest>(
      {url: `/api/v1/shipments/${shipmentPk}/customs-clearance-request/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedCustomsClearanceRequestRequest
    },
      options);
    }
  


export const shipmentsCustomsClearanceRequestDestroy = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/customs-clearance-request/${id}/`, method: 'delete'
    },
      options);
    }
  


export const shipmentsDetailsList = (
    shipmentPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentDetail[]>(
      {url: `/api/v1/shipments/${shipmentPk}/details/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsDetailsListKey = (shipmentPk: number,) => [`/api/v1/shipments/${shipmentPk}/details/`] as const;

    
export type ShipmentsDetailsListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsDetailsList>>>
export type ShipmentsDetailsListQueryError = unknown

export const useShipmentsDetailsList = <TError = unknown>(
 shipmentPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsDetailsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsDetailsListKey(shipmentPk) : null);
  const swrFn = () => shipmentsDetailsList(shipmentPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsDetailsCreate = (
    shipmentPk: number,
    shipmentDetailRequest: ShipmentDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentDetail>(
      {url: `/api/v1/shipments/${shipmentPk}/details/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: shipmentDetailRequest
    },
      options);
    }
  


export const shipmentsDetailsRetrieve = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentDetail>(
      {url: `/api/v1/shipments/${shipmentPk}/details/${id}/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsDetailsRetrieveKey = (shipmentPk: number,
    id: number,) => [`/api/v1/shipments/${shipmentPk}/details/${id}/`] as const;

    
export type ShipmentsDetailsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsDetailsRetrieve>>>
export type ShipmentsDetailsRetrieveQueryError = unknown

export const useShipmentsDetailsRetrieve = <TError = unknown>(
 shipmentPk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsDetailsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsDetailsRetrieveKey(shipmentPk,id) : null);
  const swrFn = () => shipmentsDetailsRetrieve(shipmentPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsDetailsUpdate = (
    shipmentPk: number,
    id: number,
    shipmentDetailRequest: ShipmentDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentDetail>(
      {url: `/api/v1/shipments/${shipmentPk}/details/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: shipmentDetailRequest
    },
      options);
    }
  


export const shipmentsDetailsPartialUpdate = (
    shipmentPk: number,
    id: number,
    patchedShipmentDetailRequest: PatchedShipmentDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentDetail>(
      {url: `/api/v1/shipments/${shipmentPk}/details/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedShipmentDetailRequest
    },
      options);
    }
  


export const shipmentsDetailsDestroy = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/details/${id}/`, method: 'delete'
    },
      options);
    }
  


export const shipmentsExchangeRatesUpdate = (
    shipmentPk: number,
    id: number,
    shipmentExchangeRateRequest: ShipmentExchangeRateRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentExchangeRate>(
      {url: `/api/v1/shipments/${shipmentPk}/exchange-rates/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: shipmentExchangeRateRequest
    },
      options);
    }
  


export const shipmentsExchangeRatesPartialUpdate = (
    shipmentPk: number,
    id: number,
    patchedShipmentExchangeRateRequest: PatchedShipmentExchangeRateRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentExchangeRate>(
      {url: `/api/v1/shipments/${shipmentPk}/exchange-rates/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedShipmentExchangeRateRequest
    },
      options);
    }
  


export const shipmentsExchangeRatesDestroy = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/exchange-rates/${id}/`, method: 'delete'
    },
      options);
    }
  


export const shipmentsGuestGroupsList = (
    shipmentPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<GuestGroup[]>(
      {url: `/api/v1/shipments/${shipmentPk}/guest-groups/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsGuestGroupsListKey = (shipmentPk: number,) => [`/api/v1/shipments/${shipmentPk}/guest-groups/`] as const;

    
export type ShipmentsGuestGroupsListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsGuestGroupsList>>>
export type ShipmentsGuestGroupsListQueryError = unknown

export const useShipmentsGuestGroupsList = <TError = unknown>(
 shipmentPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsGuestGroupsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsGuestGroupsListKey(shipmentPk) : null);
  const swrFn = () => shipmentsGuestGroupsList(shipmentPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsGuestGroupsUpdate = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/guest-groups/${id}/`, method: 'put'
    },
      options);
    }
  


export const shipmentsGuestGroupsPartialUpdate = (
    shipmentPk: number,
    id: number,
    patchedGuestGroupRequest: PatchedGuestGroupRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<GuestGroup>(
      {url: `/api/v1/shipments/${shipmentPk}/guest-groups/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedGuestGroupRequest
    },
      options);
    }
  


export const shipmentsGuestGroupsDestroy = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/guest-groups/${id}/`, method: 'delete'
    },
      options);
    }
  


/**
 * テンプレートをもとにシップメントにタスクを作成します
 */
export const shipmentsInitialTasksCreate = (
    shipmentPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<InitialTasks>(
      {url: `/api/v1/shipments/${shipmentPk}/initial-tasks/`, method: 'post'
    },
      options);
    }
  


export const shipmentsLandTransportsList = (
    shipmentPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentLandTransport[]>(
      {url: `/api/v1/shipments/${shipmentPk}/land-transports/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsLandTransportsListKey = (shipmentPk: number,) => [`/api/v1/shipments/${shipmentPk}/land-transports/`] as const;

    
export type ShipmentsLandTransportsListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsLandTransportsList>>>
export type ShipmentsLandTransportsListQueryError = unknown

export const useShipmentsLandTransportsList = <TError = unknown>(
 shipmentPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsLandTransportsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsLandTransportsListKey(shipmentPk) : null);
  const swrFn = () => shipmentsLandTransportsList(shipmentPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsLandTransportsCreate = (
    shipmentPk: number,
    shipmentLandTransportRequest: ShipmentLandTransportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentLandTransport>(
      {url: `/api/v1/shipments/${shipmentPk}/land-transports/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: shipmentLandTransportRequest
    },
      options);
    }
  


export const shipmentsLandTransportsRetrieve = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentLandTransport>(
      {url: `/api/v1/shipments/${shipmentPk}/land-transports/${id}/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsLandTransportsRetrieveKey = (shipmentPk: number,
    id: number,) => [`/api/v1/shipments/${shipmentPk}/land-transports/${id}/`] as const;

    
export type ShipmentsLandTransportsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsLandTransportsRetrieve>>>
export type ShipmentsLandTransportsRetrieveQueryError = unknown

export const useShipmentsLandTransportsRetrieve = <TError = unknown>(
 shipmentPk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsLandTransportsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsLandTransportsRetrieveKey(shipmentPk,id) : null);
  const swrFn = () => shipmentsLandTransportsRetrieve(shipmentPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsLandTransportsUpdate = (
    shipmentPk: number,
    id: number,
    shipmentLandTransportRequest: ShipmentLandTransportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentLandTransport>(
      {url: `/api/v1/shipments/${shipmentPk}/land-transports/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: shipmentLandTransportRequest
    },
      options);
    }
  


export const shipmentsLandTransportsPartialUpdate = (
    shipmentPk: number,
    id: number,
    patchedShipmentLandTransportRequest: PatchedShipmentLandTransportRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentLandTransport>(
      {url: `/api/v1/shipments/${shipmentPk}/land-transports/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedShipmentLandTransportRequest
    },
      options);
    }
  


export const shipmentsLandTransportsDestroy = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/land-transports/${id}/`, method: 'delete'
    },
      options);
    }
  


export const shipmentsLandTransportsSetCreate = (
    shipmentPk: number,
    shipmentLandTransportCreateRequest: ShipmentLandTransportCreateRequest[],
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/land-transports/set/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: shipmentLandTransportCreateRequest
    },
      options);
    }
  


export const shipmentsMemoList = (
    shipmentPk: number,
    params?: ShipmentsMemoListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentMemo[]>(
      {url: `/api/v1/shipments/${shipmentPk}/memo/`, method: 'get',
        params
    },
      options);
    }
  

export const getShipmentsMemoListKey = (shipmentPk: number,
    params?: ShipmentsMemoListParams,) => [`/api/v1/shipments/${shipmentPk}/memo/`, ...(params ? [params]: [])] as const;

    
export type ShipmentsMemoListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsMemoList>>>
export type ShipmentsMemoListQueryError = unknown

export const useShipmentsMemoList = <TError = unknown>(
 shipmentPk: number,
    params?: ShipmentsMemoListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsMemoList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsMemoListKey(shipmentPk,params) : null);
  const swrFn = () => shipmentsMemoList(shipmentPk,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsMemoCreate = (
    shipmentPk: number,
    shipmentMemoRequest: ShipmentMemoRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentMemo>(
      {url: `/api/v1/shipments/${shipmentPk}/memo/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: shipmentMemoRequest
    },
      options);
    }
  


export const shipmentsMemoUpdate = (
    shipmentPk: number,
    id: number,
    shipmentMemoRequest: ShipmentMemoRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentMemo>(
      {url: `/api/v1/shipments/${shipmentPk}/memo/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: shipmentMemoRequest
    },
      options);
    }
  


export const shipmentsMemoPartialUpdate = (
    shipmentPk: number,
    id: number,
    patchedShipmentMemoRequest: PatchedShipmentMemoRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentMemo>(
      {url: `/api/v1/shipments/${shipmentPk}/memo/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedShipmentMemoRequest
    },
      options);
    }
  


export const shipmentsPaymentInvoicesList = (
    shipmentPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaymentInvoices[]>(
      {url: `/api/v1/shipments/${shipmentPk}/payment-invoices/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsPaymentInvoicesListKey = (shipmentPk: number,) => [`/api/v1/shipments/${shipmentPk}/payment-invoices/`] as const;

    
export type ShipmentsPaymentInvoicesListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsPaymentInvoicesList>>>
export type ShipmentsPaymentInvoicesListQueryError = unknown

export const useShipmentsPaymentInvoicesList = <TError = unknown>(
 shipmentPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsPaymentInvoicesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsPaymentInvoicesListKey(shipmentPk) : null);
  const swrFn = () => shipmentsPaymentInvoicesList(shipmentPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsPortrichBillOfLadingList = (
    shipmentPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PortrichBillOfLadings[]>(
      {url: `/api/v1/shipments/${shipmentPk}/portrich-bill-of-lading/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsPortrichBillOfLadingListKey = (shipmentPk: number,) => [`/api/v1/shipments/${shipmentPk}/portrich-bill-of-lading/`] as const;

    
export type ShipmentsPortrichBillOfLadingListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsPortrichBillOfLadingList>>>
export type ShipmentsPortrichBillOfLadingListQueryError = unknown

export const useShipmentsPortrichBillOfLadingList = <TError = unknown>(
 shipmentPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsPortrichBillOfLadingList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsPortrichBillOfLadingListKey(shipmentPk) : null);
  const swrFn = () => shipmentsPortrichBillOfLadingList(shipmentPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsPortrichBillOfLadingCreate = (
    shipmentPk: number,
    portrichBillOfLadingsRequest: PortrichBillOfLadingsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PortrichBillOfLadings>(
      {url: `/api/v1/shipments/${shipmentPk}/portrich-bill-of-lading/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: portrichBillOfLadingsRequest
    },
      options);
    }
  


export const shipmentsPortrichBillOfLadingRetrieve = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PortrichBillOfLadings>(
      {url: `/api/v1/shipments/${shipmentPk}/portrich-bill-of-lading/${id}/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsPortrichBillOfLadingRetrieveKey = (shipmentPk: number,
    id: number,) => [`/api/v1/shipments/${shipmentPk}/portrich-bill-of-lading/${id}/`] as const;

    
export type ShipmentsPortrichBillOfLadingRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsPortrichBillOfLadingRetrieve>>>
export type ShipmentsPortrichBillOfLadingRetrieveQueryError = unknown

export const useShipmentsPortrichBillOfLadingRetrieve = <TError = unknown>(
 shipmentPk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsPortrichBillOfLadingRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsPortrichBillOfLadingRetrieveKey(shipmentPk,id) : null);
  const swrFn = () => shipmentsPortrichBillOfLadingRetrieve(shipmentPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsPortrichBillOfLadingUpdate = (
    shipmentPk: number,
    id: number,
    portrichBillOfLadingsRequest: PortrichBillOfLadingsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PortrichBillOfLadings>(
      {url: `/api/v1/shipments/${shipmentPk}/portrich-bill-of-lading/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: portrichBillOfLadingsRequest
    },
      options);
    }
  


export const shipmentsPortrichBillOfLadingPartialUpdate = (
    shipmentPk: number,
    id: number,
    patchedPortrichBillOfLadingsRequest: PatchedPortrichBillOfLadingsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PortrichBillOfLadings>(
      {url: `/api/v1/shipments/${shipmentPk}/portrich-bill-of-lading/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedPortrichBillOfLadingsRequest
    },
      options);
    }
  


export const shipmentsPortrichBillOfLadingDestroy = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/portrich-bill-of-lading/${id}/`, method: 'delete'
    },
      options);
    }
  


export const shipmentsSelectedQuotesList = (
    shipmentPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SelectedQuote[]>(
      {url: `/api/v1/shipments/${shipmentPk}/selected-quotes/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsSelectedQuotesListKey = (shipmentPk: number,) => [`/api/v1/shipments/${shipmentPk}/selected-quotes/`] as const;

    
export type ShipmentsSelectedQuotesListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsSelectedQuotesList>>>
export type ShipmentsSelectedQuotesListQueryError = unknown

export const useShipmentsSelectedQuotesList = <TError = unknown>(
 shipmentPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsSelectedQuotesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsSelectedQuotesListKey(shipmentPk) : null);
  const swrFn = () => shipmentsSelectedQuotesList(shipmentPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsSelectedQuotesCreate = (
    shipmentPk: number,
    selectedQuoteCreateRequest: SelectedQuoteCreateRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SelectedQuote>(
      {url: `/api/v1/shipments/${shipmentPk}/selected-quotes/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: selectedQuoteCreateRequest
    },
      options);
    }
  


export const shipmentsSelectedQuotesDestroy = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/selected-quotes/${id}/`, method: 'delete'
    },
      options);
    }
  


export const shipmentsShippingInstructionsList = (
    shipmentPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShippingInstructions[]>(
      {url: `/api/v1/shipments/${shipmentPk}/shipping-instructions/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsShippingInstructionsListKey = (shipmentPk: number,) => [`/api/v1/shipments/${shipmentPk}/shipping-instructions/`] as const;

    
export type ShipmentsShippingInstructionsListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsShippingInstructionsList>>>
export type ShipmentsShippingInstructionsListQueryError = unknown

export const useShipmentsShippingInstructionsList = <TError = unknown>(
 shipmentPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsShippingInstructionsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsShippingInstructionsListKey(shipmentPk) : null);
  const swrFn = () => shipmentsShippingInstructionsList(shipmentPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsShippingInstructionsCreate = (
    shipmentPk: number,
    shippingInstructionsRequest: ShippingInstructionsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShippingInstructions>(
      {url: `/api/v1/shipments/${shipmentPk}/shipping-instructions/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: shippingInstructionsRequest
    },
      options);
    }
  


export const shipmentsShippingInstructionsRetrieve = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShippingInstructions>(
      {url: `/api/v1/shipments/${shipmentPk}/shipping-instructions/${id}/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsShippingInstructionsRetrieveKey = (shipmentPk: number,
    id: number,) => [`/api/v1/shipments/${shipmentPk}/shipping-instructions/${id}/`] as const;

    
export type ShipmentsShippingInstructionsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsShippingInstructionsRetrieve>>>
export type ShipmentsShippingInstructionsRetrieveQueryError = unknown

export const useShipmentsShippingInstructionsRetrieve = <TError = unknown>(
 shipmentPk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsShippingInstructionsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsShippingInstructionsRetrieveKey(shipmentPk,id) : null);
  const swrFn = () => shipmentsShippingInstructionsRetrieve(shipmentPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsShippingInstructionsUpdate = (
    shipmentPk: number,
    id: number,
    shippingInstructionsRequest: ShippingInstructionsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShippingInstructions>(
      {url: `/api/v1/shipments/${shipmentPk}/shipping-instructions/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: shippingInstructionsRequest
    },
      options);
    }
  


export const shipmentsShippingInstructionsPartialUpdate = (
    shipmentPk: number,
    id: number,
    patchedShippingInstructionsRequest: PatchedShippingInstructionsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShippingInstructions>(
      {url: `/api/v1/shipments/${shipmentPk}/shipping-instructions/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedShippingInstructionsRequest
    },
      options);
    }
  


export const shipmentsShippingInstructionsDestroy = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/shipping-instructions/${id}/`, method: 'delete'
    },
      options);
    }
  


export const shipmentsTasksList = (
    shipmentPk: number,
    params?: ShipmentsTasksListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentTasks[]>(
      {url: `/api/v1/shipments/${shipmentPk}/tasks/`, method: 'get',
        params
    },
      options);
    }
  

export const getShipmentsTasksListKey = (shipmentPk: number,
    params?: ShipmentsTasksListParams,) => [`/api/v1/shipments/${shipmentPk}/tasks/`, ...(params ? [params]: [])] as const;

    
export type ShipmentsTasksListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsTasksList>>>
export type ShipmentsTasksListQueryError = unknown

export const useShipmentsTasksList = <TError = unknown>(
 shipmentPk: number,
    params?: ShipmentsTasksListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsTasksList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsTasksListKey(shipmentPk,params) : null);
  const swrFn = () => shipmentsTasksList(shipmentPk,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsTasksCreate = (
    shipmentPk: number,
    shipmentTasksRequest: ShipmentTasksRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentTasks>(
      {url: `/api/v1/shipments/${shipmentPk}/tasks/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: shipmentTasksRequest
    },
      options);
    }
  


export const shipmentsTasksRetrieve = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentTasks>(
      {url: `/api/v1/shipments/${shipmentPk}/tasks/${id}/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsTasksRetrieveKey = (shipmentPk: number,
    id: number,) => [`/api/v1/shipments/${shipmentPk}/tasks/${id}/`] as const;

    
export type ShipmentsTasksRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsTasksRetrieve>>>
export type ShipmentsTasksRetrieveQueryError = unknown

export const useShipmentsTasksRetrieve = <TError = unknown>(
 shipmentPk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsTasksRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsTasksRetrieveKey(shipmentPk,id) : null);
  const swrFn = () => shipmentsTasksRetrieve(shipmentPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsTasksUpdate = (
    shipmentPk: number,
    id: number,
    shipmentTasksRequest: ShipmentTasksRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentTasks>(
      {url: `/api/v1/shipments/${shipmentPk}/tasks/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: shipmentTasksRequest
    },
      options);
    }
  


export const shipmentsTasksPartialUpdate = (
    shipmentPk: number,
    id: number,
    patchedShipmentTasksRequest: PatchedShipmentTasksRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentTasks>(
      {url: `/api/v1/shipments/${shipmentPk}/tasks/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedShipmentTasksRequest
    },
      options);
    }
  


export const shipmentsTasksDestroy = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/tasks/${id}/`, method: 'delete'
    },
      options);
    }
  


export const shipmentsTasksSubTasksCreate = (
    shipmentPk: number,
    id: number,
    shipmentSubTaskRequest: ShipmentSubTaskRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentSubTask>(
      {url: `/api/v1/shipments/${shipmentPk}/tasks/${id}/sub-tasks/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: shipmentSubTaskRequest
    },
      options);
    }
  


export const shipmentsTasksByCategoriesList = (
    shipmentPk: number,
    params?: ShipmentsTasksByCategoriesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TasksByCategories[]>(
      {url: `/api/v1/shipments/${shipmentPk}/tasks/by-categories/`, method: 'get',
        params
    },
      options);
    }
  

export const getShipmentsTasksByCategoriesListKey = (shipmentPk: number,
    params?: ShipmentsTasksByCategoriesListParams,) => [`/api/v1/shipments/${shipmentPk}/tasks/by-categories/`, ...(params ? [params]: [])] as const;

    
export type ShipmentsTasksByCategoriesListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsTasksByCategoriesList>>>
export type ShipmentsTasksByCategoriesListQueryError = unknown

export const useShipmentsTasksByCategoriesList = <TError = unknown>(
 shipmentPk: number,
    params?: ShipmentsTasksByCategoriesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsTasksByCategoriesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsTasksByCategoriesListKey(shipmentPk,params) : null);
  const swrFn = () => shipmentsTasksByCategoriesList(shipmentPk,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsTasksSetCreate = (
    shipmentPk: number,
    shipmentTasksRequest: ShipmentTasksRequest,
    params?: ShipmentsTasksSetCreateParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentTasks[]>(
      {url: `/api/v1/shipments/${shipmentPk}/tasks/set/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: shipmentTasksRequest,
        params
    },
      options);
    }
  


export const shipmentsTransportRequestsList = (
    shipmentPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TransportRequest[]>(
      {url: `/api/v1/shipments/${shipmentPk}/transport-requests/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsTransportRequestsListKey = (shipmentPk: number,) => [`/api/v1/shipments/${shipmentPk}/transport-requests/`] as const;

    
export type ShipmentsTransportRequestsListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsTransportRequestsList>>>
export type ShipmentsTransportRequestsListQueryError = unknown

export const useShipmentsTransportRequestsList = <TError = unknown>(
 shipmentPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsTransportRequestsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsTransportRequestsListKey(shipmentPk) : null);
  const swrFn = () => shipmentsTransportRequestsList(shipmentPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsTransportRequestsCreate = (
    shipmentPk: number,
    transportRequestRequest: TransportRequestRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TransportRequest>(
      {url: `/api/v1/shipments/${shipmentPk}/transport-requests/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: transportRequestRequest
    },
      options);
    }
  


export const shipmentsTransportRequestsRetrieve = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TransportRequest>(
      {url: `/api/v1/shipments/${shipmentPk}/transport-requests/${id}/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsTransportRequestsRetrieveKey = (shipmentPk: number,
    id: number,) => [`/api/v1/shipments/${shipmentPk}/transport-requests/${id}/`] as const;

    
export type ShipmentsTransportRequestsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsTransportRequestsRetrieve>>>
export type ShipmentsTransportRequestsRetrieveQueryError = unknown

export const useShipmentsTransportRequestsRetrieve = <TError = unknown>(
 shipmentPk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsTransportRequestsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(shipmentPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsTransportRequestsRetrieveKey(shipmentPk,id) : null);
  const swrFn = () => shipmentsTransportRequestsRetrieve(shipmentPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsTransportRequestsUpdate = (
    shipmentPk: number,
    id: number,
    transportRequestRequest: TransportRequestRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TransportRequest>(
      {url: `/api/v1/shipments/${shipmentPk}/transport-requests/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: transportRequestRequest
    },
      options);
    }
  


export const shipmentsTransportRequestsPartialUpdate = (
    shipmentPk: number,
    id: number,
    patchedTransportRequestRequest: PatchedTransportRequestRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TransportRequest>(
      {url: `/api/v1/shipments/${shipmentPk}/transport-requests/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedTransportRequestRequest
    },
      options);
    }
  


export const shipmentsTransportRequestsDestroy = (
    shipmentPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipments/${shipmentPk}/transport-requests/${id}/`, method: 'delete'
    },
      options);
    }
  


export const shipmentsQuoteRelatedList = (
    params?: ShipmentsQuoteRelatedListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Shipments[]>(
      {url: `/api/v1/shipments/quote-related/`, method: 'get',
        params
    },
      options);
    }
  

export const getShipmentsQuoteRelatedListKey = (params?: ShipmentsQuoteRelatedListParams,) => [`/api/v1/shipments/quote-related/`, ...(params ? [params]: [])] as const;

    
export type ShipmentsQuoteRelatedListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsQuoteRelatedList>>>
export type ShipmentsQuoteRelatedListQueryError = unknown

export const useShipmentsQuoteRelatedList = <TError = unknown>(
 params?: ShipmentsQuoteRelatedListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsQuoteRelatedList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsQuoteRelatedListKey(params) : null);
  const swrFn = () => shipmentsQuoteRelatedList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shipmentsTransportSchedulesList = (
    params?: ShipmentsTransportSchedulesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ShipmentTransportSchedules[]>(
      {url: `/api/v1/shipments/transport/schedules/`, method: 'get',
        params
    },
      options);
    }
  

export const getShipmentsTransportSchedulesListKey = (params?: ShipmentsTransportSchedulesListParams,) => [`/api/v1/shipments/transport/schedules/`, ...(params ? [params]: [])] as const;

    
export type ShipmentsTransportSchedulesListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsTransportSchedulesList>>>
export type ShipmentsTransportSchedulesListQueryError = unknown

export const useShipmentsTransportSchedulesList = <TError = unknown>(
 params?: ShipmentsTransportSchedulesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsTransportSchedulesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsTransportSchedulesListKey(params) : null);
  const swrFn = () => shipmentsTransportSchedulesList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * 見積依頼とシップメントの配送先をマージしたものを返す
 */
export const shipmentsWorkplacesList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Workplace[]>(
      {url: `/api/v1/shipments/workplaces/`, method: 'get'
    },
      options);
    }
  

export const getShipmentsWorkplacesListKey = () => [`/api/v1/shipments/workplaces/`] as const;

    
export type ShipmentsWorkplacesListQueryResult = NonNullable<Awaited<ReturnType<typeof shipmentsWorkplacesList>>>
export type ShipmentsWorkplacesListQueryError = unknown

export const useShipmentsWorkplacesList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shipmentsWorkplacesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShipmentsWorkplacesListKey() : null);
  const swrFn = () => shipmentsWorkplacesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shippingSchedulesAirTransportList = (
    params?: ShippingSchedulesAirTransportListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedAirTransportScheduleList>(
      {url: `/api/v1/shipping-schedules/air-transport/`, method: 'get',
        params
    },
      options);
    }
  

export const getShippingSchedulesAirTransportListKey = (params?: ShippingSchedulesAirTransportListParams,) => [`/api/v1/shipping-schedules/air-transport/`, ...(params ? [params]: [])] as const;

    
export type ShippingSchedulesAirTransportListQueryResult = NonNullable<Awaited<ReturnType<typeof shippingSchedulesAirTransportList>>>
export type ShippingSchedulesAirTransportListQueryError = unknown

export const useShippingSchedulesAirTransportList = <TError = unknown>(
 params?: ShippingSchedulesAirTransportListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shippingSchedulesAirTransportList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShippingSchedulesAirTransportListKey(params) : null);
  const swrFn = () => shippingSchedulesAirTransportList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shippingSchedulesAirTransportCreate = (
    airTransportScheduleRequest: AirTransportScheduleRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportSchedule>(
      {url: `/api/v1/shipping-schedules/air-transport/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: airTransportScheduleRequest
    },
      options);
    }
  


export const shippingSchedulesAirTransportRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportSchedule>(
      {url: `/api/v1/shipping-schedules/air-transport/${id}/`, method: 'get'
    },
      options);
    }
  

export const getShippingSchedulesAirTransportRetrieveKey = (id: number,) => [`/api/v1/shipping-schedules/air-transport/${id}/`] as const;

    
export type ShippingSchedulesAirTransportRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shippingSchedulesAirTransportRetrieve>>>
export type ShippingSchedulesAirTransportRetrieveQueryError = unknown

export const useShippingSchedulesAirTransportRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shippingSchedulesAirTransportRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShippingSchedulesAirTransportRetrieveKey(id) : null);
  const swrFn = () => shippingSchedulesAirTransportRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shippingSchedulesAirTransportUpdate = (
    id: number,
    airTransportScheduleRequest: AirTransportScheduleRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportSchedule>(
      {url: `/api/v1/shipping-schedules/air-transport/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: airTransportScheduleRequest
    },
      options);
    }
  


export const shippingSchedulesAirTransportPartialUpdate = (
    id: number,
    patchedAirTransportScheduleRequest: PatchedAirTransportScheduleRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<AirTransportSchedule>(
      {url: `/api/v1/shipping-schedules/air-transport/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedAirTransportScheduleRequest
    },
      options);
    }
  


export const shippingSchedulesAirTransportDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipping-schedules/air-transport/${id}/`, method: 'delete'
    },
      options);
    }
  


export const shippingSchedulesMarineTransportList = (
    params?: ShippingSchedulesMarineTransportListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedMarineTransportFixedSchedulesList>(
      {url: `/api/v1/shipping-schedules/marine-transport/`, method: 'get',
        params
    },
      options);
    }
  

export const getShippingSchedulesMarineTransportListKey = (params?: ShippingSchedulesMarineTransportListParams,) => [`/api/v1/shipping-schedules/marine-transport/`, ...(params ? [params]: [])] as const;

    
export type ShippingSchedulesMarineTransportListQueryResult = NonNullable<Awaited<ReturnType<typeof shippingSchedulesMarineTransportList>>>
export type ShippingSchedulesMarineTransportListQueryError = unknown

export const useShippingSchedulesMarineTransportList = <TError = unknown>(
 params?: ShippingSchedulesMarineTransportListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shippingSchedulesMarineTransportList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShippingSchedulesMarineTransportListKey(params) : null);
  const swrFn = () => shippingSchedulesMarineTransportList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shippingSchedulesMarineTransportCreate = (
    marineTransportFixedSchedulesRequest: MarineTransportFixedSchedulesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MarineTransportFixedSchedules>(
      {url: `/api/v1/shipping-schedules/marine-transport/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: marineTransportFixedSchedulesRequest
    },
      options);
    }
  


export const shippingSchedulesMarineTransportRetrieve = (
    marineTransportFixedSchedulesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MarineTransportFixedSchedules>(
      {url: `/api/v1/shipping-schedules/marine-transport/${marineTransportFixedSchedulesId}/`, method: 'get'
    },
      options);
    }
  

export const getShippingSchedulesMarineTransportRetrieveKey = (marineTransportFixedSchedulesId: number,) => [`/api/v1/shipping-schedules/marine-transport/${marineTransportFixedSchedulesId}/`] as const;

    
export type ShippingSchedulesMarineTransportRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shippingSchedulesMarineTransportRetrieve>>>
export type ShippingSchedulesMarineTransportRetrieveQueryError = unknown

export const useShippingSchedulesMarineTransportRetrieve = <TError = unknown>(
 marineTransportFixedSchedulesId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shippingSchedulesMarineTransportRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(marineTransportFixedSchedulesId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShippingSchedulesMarineTransportRetrieveKey(marineTransportFixedSchedulesId) : null);
  const swrFn = () => shippingSchedulesMarineTransportRetrieve(marineTransportFixedSchedulesId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shippingSchedulesMarineTransportUpdate = (
    marineTransportFixedSchedulesId: number,
    marineTransportFixedSchedulesRequest: MarineTransportFixedSchedulesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MarineTransportFixedSchedules>(
      {url: `/api/v1/shipping-schedules/marine-transport/${marineTransportFixedSchedulesId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: marineTransportFixedSchedulesRequest
    },
      options);
    }
  


export const shippingSchedulesMarineTransportPartialUpdate = (
    marineTransportFixedSchedulesId: number,
    patchedMarineTransportFixedSchedulesRequest: PatchedMarineTransportFixedSchedulesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MarineTransportFixedSchedules>(
      {url: `/api/v1/shipping-schedules/marine-transport/${marineTransportFixedSchedulesId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedMarineTransportFixedSchedulesRequest
    },
      options);
    }
  


export const shippingSchedulesMarineTransportDestroy = (
    marineTransportFixedSchedulesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/shipping-schedules/marine-transport/${marineTransportFixedSchedulesId}/`, method: 'delete'
    },
      options);
    }
  


export const shippingSchedulesMarineTransportSetCreate = (
    marineTransportFixedSchedulesRequest: MarineTransportFixedSchedulesRequest[],
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MarineTransportFixedSchedules[]>(
      {url: `/api/v1/shipping-schedules/marine-transport/set/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: marineTransportFixedSchedulesRequest
    },
      options);
    }
  


export const shippingSchedulesSearchRetrieve = (
    params: ShippingSchedulesSearchRetrieveParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ScheduleSearch>(
      {url: `/api/v1/shipping-schedules/search/`, method: 'get',
        params
    },
      options);
    }
  

export const getShippingSchedulesSearchRetrieveKey = (params: ShippingSchedulesSearchRetrieveParams,) => [`/api/v1/shipping-schedules/search/`, ...(params ? [params]: [])] as const;

    
export type ShippingSchedulesSearchRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof shippingSchedulesSearchRetrieve>>>
export type ShippingSchedulesSearchRetrieveQueryError = unknown

export const useShippingSchedulesSearchRetrieve = <TError = unknown>(
 params: ShippingSchedulesSearchRetrieveParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shippingSchedulesSearchRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShippingSchedulesSearchRetrieveKey(params) : null);
  const swrFn = () => shippingSchedulesSearchRetrieve(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const shippingSchedulesVesselVoyageNumbersList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<string[]>(
      {url: `/api/v1/shipping-schedules/vessel-voyage-numbers/`, method: 'get'
    },
      options);
    }
  

export const getShippingSchedulesVesselVoyageNumbersListKey = () => [`/api/v1/shipping-schedules/vessel-voyage-numbers/`] as const;

    
export type ShippingSchedulesVesselVoyageNumbersListQueryResult = NonNullable<Awaited<ReturnType<typeof shippingSchedulesVesselVoyageNumbersList>>>
export type ShippingSchedulesVesselVoyageNumbersListQueryError = unknown

export const useShippingSchedulesVesselVoyageNumbersList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof shippingSchedulesVesselVoyageNumbersList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getShippingSchedulesVesselVoyageNumbersListKey() : null);
  const swrFn = () => shippingSchedulesVesselVoyageNumbersList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const tasksForwardersList = (
    params?: TasksForwardersListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ForwarderTasks[]>(
      {url: `/api/v1/tasks/forwarders/`, method: 'get',
        params
    },
      options);
    }
  

export const getTasksForwardersListKey = (params?: TasksForwardersListParams,) => [`/api/v1/tasks/forwarders/`, ...(params ? [params]: [])] as const;

    
export type TasksForwardersListQueryResult = NonNullable<Awaited<ReturnType<typeof tasksForwardersList>>>
export type TasksForwardersListQueryError = unknown

export const useTasksForwardersList = <TError = unknown>(
 params?: TasksForwardersListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof tasksForwardersList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getTasksForwardersListKey(params) : null);
  const swrFn = () => tasksForwardersList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const tasksForwardersCreate = (
    forwarderTasksRequest: ForwarderTasksRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ForwarderTasks>(
      {url: `/api/v1/tasks/forwarders/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: forwarderTasksRequest
    },
      options);
    }
  


export const tasksForwardersRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ForwarderTasks>(
      {url: `/api/v1/tasks/forwarders/${id}/`, method: 'get'
    },
      options);
    }
  

export const getTasksForwardersRetrieveKey = (id: number,) => [`/api/v1/tasks/forwarders/${id}/`] as const;

    
export type TasksForwardersRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof tasksForwardersRetrieve>>>
export type TasksForwardersRetrieveQueryError = unknown

export const useTasksForwardersRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof tasksForwardersRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getTasksForwardersRetrieveKey(id) : null);
  const swrFn = () => tasksForwardersRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const tasksForwardersUpdate = (
    id: number,
    forwarderTasksRequest: ForwarderTasksRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ForwarderTasks>(
      {url: `/api/v1/tasks/forwarders/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: forwarderTasksRequest
    },
      options);
    }
  


export const tasksForwardersPartialUpdate = (
    id: number,
    patchedForwarderTasksRequest: PatchedForwarderTasksRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ForwarderTasks>(
      {url: `/api/v1/tasks/forwarders/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedForwarderTasksRequest
    },
      options);
    }
  


export const tasksForwardersDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/tasks/forwarders/${id}/`, method: 'delete'
    },
      options);
    }
  


export const tasksTemplatesList = (
    params?: TasksTemplatesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TemplateTasks[]>(
      {url: `/api/v1/tasks/templates/`, method: 'get',
        params
    },
      options);
    }
  

export const getTasksTemplatesListKey = (params?: TasksTemplatesListParams,) => [`/api/v1/tasks/templates/`, ...(params ? [params]: [])] as const;

    
export type TasksTemplatesListQueryResult = NonNullable<Awaited<ReturnType<typeof tasksTemplatesList>>>
export type TasksTemplatesListQueryError = unknown

export const useTasksTemplatesList = <TError = unknown>(
 params?: TasksTemplatesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof tasksTemplatesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getTasksTemplatesListKey(params) : null);
  const swrFn = () => tasksTemplatesList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const tasksTemplatesRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<TemplateTasks>(
      {url: `/api/v1/tasks/templates/${id}/`, method: 'get'
    },
      options);
    }
  

export const getTasksTemplatesRetrieveKey = (id: number,) => [`/api/v1/tasks/templates/${id}/`] as const;

    
export type TasksTemplatesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof tasksTemplatesRetrieve>>>
export type TasksTemplatesRetrieveQueryError = unknown

export const useTasksTemplatesRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof tasksTemplatesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getTasksTemplatesRetrieveKey(id) : null);
  const swrFn = () => tasksTemplatesRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const tradePriceManagementsCustomsClearanceList = (
    params?: TradePriceManagementsCustomsClearanceListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedCustomsClearancePriceManagementList>(
      {url: `/api/v1/trade-price-managements/customs-clearance/`, method: 'get',
        params
    },
      options);
    }
  

export const getTradePriceManagementsCustomsClearanceListKey = (params?: TradePriceManagementsCustomsClearanceListParams,) => [`/api/v1/trade-price-managements/customs-clearance/`, ...(params ? [params]: [])] as const;

    
export type TradePriceManagementsCustomsClearanceListQueryResult = NonNullable<Awaited<ReturnType<typeof tradePriceManagementsCustomsClearanceList>>>
export type TradePriceManagementsCustomsClearanceListQueryError = unknown

export const useTradePriceManagementsCustomsClearanceList = <TError = unknown>(
 params?: TradePriceManagementsCustomsClearanceListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof tradePriceManagementsCustomsClearanceList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getTradePriceManagementsCustomsClearanceListKey(params) : null);
  const swrFn = () => tradePriceManagementsCustomsClearanceList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const tradePriceManagementsCustomsClearanceCreate = (
    customsClearancePriceManagementRequest: CustomsClearancePriceManagementRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsClearancePriceManagement>(
      {url: `/api/v1/trade-price-managements/customs-clearance/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: customsClearancePriceManagementRequest
    },
      options);
    }
  


export const tradePriceManagementsCustomsClearanceRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsClearancePriceManagement>(
      {url: `/api/v1/trade-price-managements/customs-clearance/${id}/`, method: 'get'
    },
      options);
    }
  

export const getTradePriceManagementsCustomsClearanceRetrieveKey = (id: number,) => [`/api/v1/trade-price-managements/customs-clearance/${id}/`] as const;

    
export type TradePriceManagementsCustomsClearanceRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof tradePriceManagementsCustomsClearanceRetrieve>>>
export type TradePriceManagementsCustomsClearanceRetrieveQueryError = unknown

export const useTradePriceManagementsCustomsClearanceRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof tradePriceManagementsCustomsClearanceRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getTradePriceManagementsCustomsClearanceRetrieveKey(id) : null);
  const swrFn = () => tradePriceManagementsCustomsClearanceRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const tradePriceManagementsCustomsClearanceUpdate = (
    id: number,
    customsClearancePriceManagementRequest: CustomsClearancePriceManagementRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsClearancePriceManagement>(
      {url: `/api/v1/trade-price-managements/customs-clearance/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: customsClearancePriceManagementRequest
    },
      options);
    }
  


export const tradePriceManagementsCustomsClearancePartialUpdate = (
    id: number,
    patchedCustomsClearancePriceManagementRequest: PatchedCustomsClearancePriceManagementRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CustomsClearancePriceManagement>(
      {url: `/api/v1/trade-price-managements/customs-clearance/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedCustomsClearancePriceManagementRequest
    },
      options);
    }
  


export const tradePriceManagementsCustomsClearanceDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/trade-price-managements/customs-clearance/${id}/`, method: 'delete'
    },
      options);
    }
  


export const tradePriceManagementsMarineList = (
    params?: TradePriceManagementsMarineListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PaginatedMarineTradePriceManagementList>(
      {url: `/api/v1/trade-price-managements/marine/`, method: 'get',
        params
    },
      options);
    }
  

export const getTradePriceManagementsMarineListKey = (params?: TradePriceManagementsMarineListParams,) => [`/api/v1/trade-price-managements/marine/`, ...(params ? [params]: [])] as const;

    
export type TradePriceManagementsMarineListQueryResult = NonNullable<Awaited<ReturnType<typeof tradePriceManagementsMarineList>>>
export type TradePriceManagementsMarineListQueryError = unknown

export const useTradePriceManagementsMarineList = <TError = unknown>(
 params?: TradePriceManagementsMarineListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof tradePriceManagementsMarineList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getTradePriceManagementsMarineListKey(params) : null);
  const swrFn = () => tradePriceManagementsMarineList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const tradePriceManagementsMarineCreate = (
    marineTradePriceManagementRequest: MarineTradePriceManagementRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MarineTradePriceManagement>(
      {url: `/api/v1/trade-price-managements/marine/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: marineTradePriceManagementRequest
    },
      options);
    }
  


export const tradePriceManagementsMarineRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MarineTradePriceManagement>(
      {url: `/api/v1/trade-price-managements/marine/${id}/`, method: 'get'
    },
      options);
    }
  

export const getTradePriceManagementsMarineRetrieveKey = (id: number,) => [`/api/v1/trade-price-managements/marine/${id}/`] as const;

    
export type TradePriceManagementsMarineRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof tradePriceManagementsMarineRetrieve>>>
export type TradePriceManagementsMarineRetrieveQueryError = unknown

export const useTradePriceManagementsMarineRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof tradePriceManagementsMarineRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getTradePriceManagementsMarineRetrieveKey(id) : null);
  const swrFn = () => tradePriceManagementsMarineRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const tradePriceManagementsMarineUpdate = (
    id: number,
    marineTradePriceManagementRequest: MarineTradePriceManagementRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MarineTradePriceManagement>(
      {url: `/api/v1/trade-price-managements/marine/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: marineTradePriceManagementRequest
    },
      options);
    }
  


export const tradePriceManagementsMarinePartialUpdate = (
    id: number,
    patchedMarineTradePriceManagementRequest: PatchedMarineTradePriceManagementRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<MarineTradePriceManagement>(
      {url: `/api/v1/trade-price-managements/marine/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedMarineTradePriceManagementRequest
    },
      options);
    }
  


export const tradePriceManagementsMarineDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/trade-price-managements/marine/${id}/`, method: 'delete'
    },
      options);
    }
  


/**
 * ブッキング依頼. ユーザーだけでなくフォワーダーがシップメントを作成する際にも使用している
 */
export const transactionRequestsBookingsRequestCreate = (
    bookingRequestPostOnlyRequest: BookingRequestPostOnlyRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Shipments>(
      {url: `/api/v1/transaction-requests/bookings/request/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: bookingRequestPostOnlyRequest
    },
      options);
    }
  


/**
 * 見積無しブッキング依頼. ユーザーのみが使う前提
 */
export const transactionRequestsBookingsRequestWithoutQuoteCreate = (
    bookingRequestWithoutQuoteRequest: BookingRequestWithoutQuoteRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Shipments>(
      {url: `/api/v1/transaction-requests/bookings/request-without-quote/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: bookingRequestWithoutQuoteRequest
    },
      options);
    }
  


export const transactionRequestsSelectableQuotesLandFreightsList = (
    params?: TransactionRequestsSelectableQuotesLandFreightsListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForLandFreights[]>(
      {url: `/api/v1/transaction-requests/selectable-quotes/land-freights/`, method: 'get',
        params
    },
      options);
    }
  

export const getTransactionRequestsSelectableQuotesLandFreightsListKey = (params?: TransactionRequestsSelectableQuotesLandFreightsListParams,) => [`/api/v1/transaction-requests/selectable-quotes/land-freights/`, ...(params ? [params]: [])] as const;

    
export type TransactionRequestsSelectableQuotesLandFreightsListQueryResult = NonNullable<Awaited<ReturnType<typeof transactionRequestsSelectableQuotesLandFreightsList>>>
export type TransactionRequestsSelectableQuotesLandFreightsListQueryError = unknown

export const useTransactionRequestsSelectableQuotesLandFreightsList = <TError = unknown>(
 params?: TransactionRequestsSelectableQuotesLandFreightsListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof transactionRequestsSelectableQuotesLandFreightsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getTransactionRequestsSelectableQuotesLandFreightsListKey(params) : null);
  const swrFn = () => transactionRequestsSelectableQuotesLandFreightsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const transactionRequestsSelectableQuotesLandFreightsRetrieve = (
    sellingPricesForLandFreightsId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<SellingPricesForLandFreights>(
      {url: `/api/v1/transaction-requests/selectable-quotes/land-freights/${sellingPricesForLandFreightsId}/`, method: 'get'
    },
      options);
    }
  

export const getTransactionRequestsSelectableQuotesLandFreightsRetrieveKey = (sellingPricesForLandFreightsId: number,) => [`/api/v1/transaction-requests/selectable-quotes/land-freights/${sellingPricesForLandFreightsId}/`] as const;

    
export type TransactionRequestsSelectableQuotesLandFreightsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof transactionRequestsSelectableQuotesLandFreightsRetrieve>>>
export type TransactionRequestsSelectableQuotesLandFreightsRetrieveQueryError = unknown

export const useTransactionRequestsSelectableQuotesLandFreightsRetrieve = <TError = unknown>(
 sellingPricesForLandFreightsId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof transactionRequestsSelectableQuotesLandFreightsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(sellingPricesForLandFreightsId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getTransactionRequestsSelectableQuotesLandFreightsRetrieveKey(sellingPricesForLandFreightsId) : null);
  const swrFn = () => transactionRequestsSelectableQuotesLandFreightsRetrieve(sellingPricesForLandFreightsId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersList = (
    params?: UsersListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Users[]>(
      {url: `/api/v1/users/`, method: 'get',
        params
    },
      options);
    }
  

export const getUsersListKey = (params?: UsersListParams,) => [`/api/v1/users/`, ...(params ? [params]: [])] as const;

    
export type UsersListQueryResult = NonNullable<Awaited<ReturnType<typeof usersList>>>
export type UsersListQueryError = unknown

export const useUsersList = <TError = unknown>(
 params?: UsersListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersListKey(params) : null);
  const swrFn = () => usersList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersUpdate = (
    usersId: string,
    usersRequest: UsersRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Users>(
      {url: `/api/v1/users/${usersId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: usersRequest
    },
      options);
    }
  


export const usersPartialUpdate = (
    usersId: string,
    patchedUsersRequest: PatchedUsersRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Users>(
      {url: `/api/v1/users/${usersId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedUsersRequest
    },
      options);
    }
  


export const usersCompaniesList = (
    params?: UsersCompaniesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserCompanies[]>(
      {url: `/api/v1/users/companies/`, method: 'get',
        params
    },
      options);
    }
  

export const getUsersCompaniesListKey = (params?: UsersCompaniesListParams,) => [`/api/v1/users/companies/`, ...(params ? [params]: [])] as const;

    
export type UsersCompaniesListQueryResult = NonNullable<Awaited<ReturnType<typeof usersCompaniesList>>>
export type UsersCompaniesListQueryError = unknown

export const useUsersCompaniesList = <TError = unknown>(
 params?: UsersCompaniesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersCompaniesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersCompaniesListKey(params) : null);
  const swrFn = () => usersCompaniesList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersCompaniesCreate = (
    userCompaniesRequest: UserCompaniesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserCompanies>(
      {url: `/api/v1/users/companies/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: userCompaniesRequest
    },
      options);
    }
  


export const usersCompaniesBillingsList = (
    companyPk: string,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Billing[]>(
      {url: `/api/v1/users/companies/${companyPk}/billings/`, method: 'get'
    },
      options);
    }
  

export const getUsersCompaniesBillingsListKey = (companyPk: string,) => [`/api/v1/users/companies/${companyPk}/billings/`] as const;

    
export type UsersCompaniesBillingsListQueryResult = NonNullable<Awaited<ReturnType<typeof usersCompaniesBillingsList>>>
export type UsersCompaniesBillingsListQueryError = unknown

export const useUsersCompaniesBillingsList = <TError = unknown>(
 companyPk: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersCompaniesBillingsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(companyPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersCompaniesBillingsListKey(companyPk) : null);
  const swrFn = () => usersCompaniesBillingsList(companyPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersCompaniesBillingsCreate = (
    companyPk: string,
    billingRequest: BillingRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Billing>(
      {url: `/api/v1/users/companies/${companyPk}/billings/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: billingRequest
    },
      options);
    }
  


export const usersCompaniesBillingsDetailsList = (
    companyPk: string,
    billingPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BillingDetail[]>(
      {url: `/api/v1/users/companies/${companyPk}/billings/${billingPk}/details/`, method: 'get'
    },
      options);
    }
  

export const getUsersCompaniesBillingsDetailsListKey = (companyPk: string,
    billingPk: number,) => [`/api/v1/users/companies/${companyPk}/billings/${billingPk}/details/`] as const;

    
export type UsersCompaniesBillingsDetailsListQueryResult = NonNullable<Awaited<ReturnType<typeof usersCompaniesBillingsDetailsList>>>
export type UsersCompaniesBillingsDetailsListQueryError = unknown

export const useUsersCompaniesBillingsDetailsList = <TError = unknown>(
 companyPk: string,
    billingPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersCompaniesBillingsDetailsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(companyPk && billingPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersCompaniesBillingsDetailsListKey(companyPk,billingPk) : null);
  const swrFn = () => usersCompaniesBillingsDetailsList(companyPk,billingPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersCompaniesBillingsDetailsCreate = (
    companyPk: string,
    billingPk: number,
    billingDetailRequest: BillingDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BillingDetail>(
      {url: `/api/v1/users/companies/${companyPk}/billings/${billingPk}/details/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: billingDetailRequest
    },
      options);
    }
  


export const usersCompaniesBillingsDetailsRetrieve = (
    companyPk: string,
    billingPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BillingDetail>(
      {url: `/api/v1/users/companies/${companyPk}/billings/${billingPk}/details/${id}/`, method: 'get'
    },
      options);
    }
  

export const getUsersCompaniesBillingsDetailsRetrieveKey = (companyPk: string,
    billingPk: number,
    id: number,) => [`/api/v1/users/companies/${companyPk}/billings/${billingPk}/details/${id}/`] as const;

    
export type UsersCompaniesBillingsDetailsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersCompaniesBillingsDetailsRetrieve>>>
export type UsersCompaniesBillingsDetailsRetrieveQueryError = unknown

export const useUsersCompaniesBillingsDetailsRetrieve = <TError = unknown>(
 companyPk: string,
    billingPk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersCompaniesBillingsDetailsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(companyPk && billingPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersCompaniesBillingsDetailsRetrieveKey(companyPk,billingPk,id) : null);
  const swrFn = () => usersCompaniesBillingsDetailsRetrieve(companyPk,billingPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersCompaniesBillingsDetailsUpdate = (
    companyPk: string,
    billingPk: number,
    id: number,
    billingDetailRequest: BillingDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BillingDetail>(
      {url: `/api/v1/users/companies/${companyPk}/billings/${billingPk}/details/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: billingDetailRequest
    },
      options);
    }
  


export const usersCompaniesBillingsDetailsPartialUpdate = (
    companyPk: string,
    billingPk: number,
    id: number,
    patchedBillingDetailRequest: PatchedBillingDetailRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<BillingDetail>(
      {url: `/api/v1/users/companies/${companyPk}/billings/${billingPk}/details/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedBillingDetailRequest
    },
      options);
    }
  


export const usersCompaniesBillingsDetailsDestroy = (
    companyPk: string,
    billingPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/users/companies/${companyPk}/billings/${billingPk}/details/${id}/`, method: 'delete'
    },
      options);
    }
  


export const usersCompaniesBillingsRetrieve = (
    companyPk: string,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Billing>(
      {url: `/api/v1/users/companies/${companyPk}/billings/${id}/`, method: 'get'
    },
      options);
    }
  

export const getUsersCompaniesBillingsRetrieveKey = (companyPk: string,
    id: number,) => [`/api/v1/users/companies/${companyPk}/billings/${id}/`] as const;

    
export type UsersCompaniesBillingsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersCompaniesBillingsRetrieve>>>
export type UsersCompaniesBillingsRetrieveQueryError = unknown

export const useUsersCompaniesBillingsRetrieve = <TError = unknown>(
 companyPk: string,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersCompaniesBillingsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(companyPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersCompaniesBillingsRetrieveKey(companyPk,id) : null);
  const swrFn = () => usersCompaniesBillingsRetrieve(companyPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersCompaniesBillingsUpdate = (
    companyPk: string,
    id: number,
    billingRequest: BillingRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Billing>(
      {url: `/api/v1/users/companies/${companyPk}/billings/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: billingRequest
    },
      options);
    }
  


export const usersCompaniesBillingsPartialUpdate = (
    companyPk: string,
    id: number,
    patchedBillingRequest: PatchedBillingRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Billing>(
      {url: `/api/v1/users/companies/${companyPk}/billings/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedBillingRequest
    },
      options);
    }
  


export const usersCompaniesBillingsDestroy = (
    companyPk: string,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/users/companies/${companyPk}/billings/${id}/`, method: 'delete'
    },
      options);
    }
  


export const usersCompaniesContactsList = (
    companyPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CompanyContacts[]>(
      {url: `/api/v1/users/companies/${companyPk}/contacts/`, method: 'get'
    },
      options);
    }
  

export const getUsersCompaniesContactsListKey = (companyPk: number,) => [`/api/v1/users/companies/${companyPk}/contacts/`] as const;

    
export type UsersCompaniesContactsListQueryResult = NonNullable<Awaited<ReturnType<typeof usersCompaniesContactsList>>>
export type UsersCompaniesContactsListQueryError = unknown

export const useUsersCompaniesContactsList = <TError = unknown>(
 companyPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersCompaniesContactsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(companyPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersCompaniesContactsListKey(companyPk) : null);
  const swrFn = () => usersCompaniesContactsList(companyPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersCompaniesContactsCreate = (
    companyPk: number,
    companyContactsRequest: CompanyContactsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CompanyContacts>(
      {url: `/api/v1/users/companies/${companyPk}/contacts/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: companyContactsRequest
    },
      options);
    }
  


export const usersCompaniesContactsRetrieve = (
    companyPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CompanyContacts>(
      {url: `/api/v1/users/companies/${companyPk}/contacts/${id}/`, method: 'get'
    },
      options);
    }
  

export const getUsersCompaniesContactsRetrieveKey = (companyPk: number,
    id: number,) => [`/api/v1/users/companies/${companyPk}/contacts/${id}/`] as const;

    
export type UsersCompaniesContactsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersCompaniesContactsRetrieve>>>
export type UsersCompaniesContactsRetrieveQueryError = unknown

export const useUsersCompaniesContactsRetrieve = <TError = unknown>(
 companyPk: number,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersCompaniesContactsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(companyPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersCompaniesContactsRetrieveKey(companyPk,id) : null);
  const swrFn = () => usersCompaniesContactsRetrieve(companyPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersCompaniesContactsUpdate = (
    companyPk: number,
    id: number,
    companyContactsRequest: CompanyContactsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CompanyContacts>(
      {url: `/api/v1/users/companies/${companyPk}/contacts/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: companyContactsRequest
    },
      options);
    }
  


export const usersCompaniesContactsPartialUpdate = (
    companyPk: number,
    id: number,
    patchedCompanyContactsRequest: PatchedCompanyContactsRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<CompanyContacts>(
      {url: `/api/v1/users/companies/${companyPk}/contacts/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedCompanyContactsRequest
    },
      options);
    }
  


export const usersCompaniesContactsDestroy = (
    companyPk: number,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/users/companies/${companyPk}/contacts/${id}/`, method: 'delete'
    },
      options);
    }
  


export const usersCompaniesForwardingOperationsList = (
    companyPk: string,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ForwardingOperation[]>(
      {url: `/api/v1/users/companies/${companyPk}/forwarding-operations/`, method: 'get'
    },
      options);
    }
  

export const getUsersCompaniesForwardingOperationsListKey = (companyPk: string,) => [`/api/v1/users/companies/${companyPk}/forwarding-operations/`] as const;

    
export type UsersCompaniesForwardingOperationsListQueryResult = NonNullable<Awaited<ReturnType<typeof usersCompaniesForwardingOperationsList>>>
export type UsersCompaniesForwardingOperationsListQueryError = unknown

export const useUsersCompaniesForwardingOperationsList = <TError = unknown>(
 companyPk: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersCompaniesForwardingOperationsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(companyPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersCompaniesForwardingOperationsListKey(companyPk) : null);
  const swrFn = () => usersCompaniesForwardingOperationsList(companyPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersCompaniesForwardingOperationsCreate = (
    companyPk: string,
    forwardingOperationRequest: ForwardingOperationRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ForwardingOperation>(
      {url: `/api/v1/users/companies/${companyPk}/forwarding-operations/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: forwardingOperationRequest
    },
      options);
    }
  


export const usersCompaniesForwardingOperationsRetrieve = (
    companyPk: string,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ForwardingOperation>(
      {url: `/api/v1/users/companies/${companyPk}/forwarding-operations/${id}/`, method: 'get'
    },
      options);
    }
  

export const getUsersCompaniesForwardingOperationsRetrieveKey = (companyPk: string,
    id: number,) => [`/api/v1/users/companies/${companyPk}/forwarding-operations/${id}/`] as const;

    
export type UsersCompaniesForwardingOperationsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersCompaniesForwardingOperationsRetrieve>>>
export type UsersCompaniesForwardingOperationsRetrieveQueryError = unknown

export const useUsersCompaniesForwardingOperationsRetrieve = <TError = unknown>(
 companyPk: string,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersCompaniesForwardingOperationsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(companyPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersCompaniesForwardingOperationsRetrieveKey(companyPk,id) : null);
  const swrFn = () => usersCompaniesForwardingOperationsRetrieve(companyPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersCompaniesForwardingOperationsUpdate = (
    companyPk: string,
    id: number,
    forwardingOperationRequest: ForwardingOperationRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ForwardingOperation>(
      {url: `/api/v1/users/companies/${companyPk}/forwarding-operations/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: forwardingOperationRequest
    },
      options);
    }
  


export const usersCompaniesForwardingOperationsPartialUpdate = (
    companyPk: string,
    id: number,
    patchedForwardingOperationRequest: PatchedForwardingOperationRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ForwardingOperation>(
      {url: `/api/v1/users/companies/${companyPk}/forwarding-operations/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedForwardingOperationRequest
    },
      options);
    }
  


export const usersCompaniesForwardingOperationsDestroy = (
    companyPk: string,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/users/companies/${companyPk}/forwarding-operations/${id}/`, method: 'delete'
    },
      options);
    }
  


export const usersCompaniesTeamsList = (
    companyPk: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Team[]>(
      {url: `/api/v1/users/companies/${companyPk}/teams/`, method: 'get'
    },
      options);
    }
  

export const getUsersCompaniesTeamsListKey = (companyPk: number,) => [`/api/v1/users/companies/${companyPk}/teams/`] as const;

    
export type UsersCompaniesTeamsListQueryResult = NonNullable<Awaited<ReturnType<typeof usersCompaniesTeamsList>>>
export type UsersCompaniesTeamsListQueryError = unknown

export const useUsersCompaniesTeamsList = <TError = unknown>(
 companyPk: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersCompaniesTeamsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(companyPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersCompaniesTeamsListKey(companyPk) : null);
  const swrFn = () => usersCompaniesTeamsList(companyPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersCompaniesWorkplacesList = (
    companyPk: string,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserCompaniesWorkplace[]>(
      {url: `/api/v1/users/companies/${companyPk}/workplaces/`, method: 'get'
    },
      options);
    }
  

export const getUsersCompaniesWorkplacesListKey = (companyPk: string,) => [`/api/v1/users/companies/${companyPk}/workplaces/`] as const;

    
export type UsersCompaniesWorkplacesListQueryResult = NonNullable<Awaited<ReturnType<typeof usersCompaniesWorkplacesList>>>
export type UsersCompaniesWorkplacesListQueryError = unknown

export const useUsersCompaniesWorkplacesList = <TError = unknown>(
 companyPk: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersCompaniesWorkplacesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(companyPk)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersCompaniesWorkplacesListKey(companyPk) : null);
  const swrFn = () => usersCompaniesWorkplacesList(companyPk, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersCompaniesWorkplacesCreate = (
    companyPk: string,
    userCompaniesWorkplaceRequest: UserCompaniesWorkplaceRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserCompaniesWorkplace>(
      {url: `/api/v1/users/companies/${companyPk}/workplaces/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: userCompaniesWorkplaceRequest
    },
      options);
    }
  


export const usersCompaniesWorkplacesRetrieve = (
    companyPk: string,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserCompaniesWorkplace>(
      {url: `/api/v1/users/companies/${companyPk}/workplaces/${id}/`, method: 'get'
    },
      options);
    }
  

export const getUsersCompaniesWorkplacesRetrieveKey = (companyPk: string,
    id: number,) => [`/api/v1/users/companies/${companyPk}/workplaces/${id}/`] as const;

    
export type UsersCompaniesWorkplacesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersCompaniesWorkplacesRetrieve>>>
export type UsersCompaniesWorkplacesRetrieveQueryError = unknown

export const useUsersCompaniesWorkplacesRetrieve = <TError = unknown>(
 companyPk: string,
    id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersCompaniesWorkplacesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(companyPk && id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersCompaniesWorkplacesRetrieveKey(companyPk,id) : null);
  const swrFn = () => usersCompaniesWorkplacesRetrieve(companyPk,id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersCompaniesWorkplacesUpdate = (
    companyPk: string,
    id: number,
    userCompaniesWorkplaceRequest: UserCompaniesWorkplaceRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserCompaniesWorkplace>(
      {url: `/api/v1/users/companies/${companyPk}/workplaces/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: userCompaniesWorkplaceRequest
    },
      options);
    }
  


export const usersCompaniesWorkplacesPartialUpdate = (
    companyPk: string,
    id: number,
    patchedUserCompaniesWorkplaceRequest: PatchedUserCompaniesWorkplaceRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserCompaniesWorkplace>(
      {url: `/api/v1/users/companies/${companyPk}/workplaces/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedUserCompaniesWorkplaceRequest
    },
      options);
    }
  


export const usersCompaniesWorkplacesDestroy = (
    companyPk: string,
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/users/companies/${companyPk}/workplaces/${id}/`, method: 'delete'
    },
      options);
    }
  


export const usersCompaniesRetrieve = (
    userCompaniesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserCompanies>(
      {url: `/api/v1/users/companies/${userCompaniesId}/`, method: 'get'
    },
      options);
    }
  

export const getUsersCompaniesRetrieveKey = (userCompaniesId: number,) => [`/api/v1/users/companies/${userCompaniesId}/`] as const;

    
export type UsersCompaniesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersCompaniesRetrieve>>>
export type UsersCompaniesRetrieveQueryError = unknown

export const useUsersCompaniesRetrieve = <TError = unknown>(
 userCompaniesId: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersCompaniesRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(userCompaniesId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersCompaniesRetrieveKey(userCompaniesId) : null);
  const swrFn = () => usersCompaniesRetrieve(userCompaniesId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersCompaniesUpdate = (
    userCompaniesId: number,
    userCompaniesRequest: UserCompaniesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserCompanies>(
      {url: `/api/v1/users/companies/${userCompaniesId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: userCompaniesRequest
    },
      options);
    }
  


export const usersCompaniesPartialUpdate = (
    userCompaniesId: number,
    patchedUserCompaniesRequest: PatchedUserCompaniesRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserCompanies>(
      {url: `/api/v1/users/companies/${userCompaniesId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedUserCompaniesRequest
    },
      options);
    }
  


export const usersCompaniesDestroy = (
    userCompaniesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/users/companies/${userCompaniesId}/`, method: 'delete'
    },
      options);
    }
  


export const usersCompaniesContactsInfoList = (
    userCompaniesId: number,
    params?: UsersCompaniesContactsInfoListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Contact[]>(
      {url: `/api/v1/users/companies/${userCompaniesId}/contacts-info/`, method: 'get',
        params
    },
      options);
    }
  

export const getUsersCompaniesContactsInfoListKey = (userCompaniesId: number,
    params?: UsersCompaniesContactsInfoListParams,) => [`/api/v1/users/companies/${userCompaniesId}/contacts-info/`, ...(params ? [params]: [])] as const;

    
export type UsersCompaniesContactsInfoListQueryResult = NonNullable<Awaited<ReturnType<typeof usersCompaniesContactsInfoList>>>
export type UsersCompaniesContactsInfoListQueryError = unknown

export const useUsersCompaniesContactsInfoList = <TError = unknown>(
 userCompaniesId: number,
    params?: UsersCompaniesContactsInfoListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersCompaniesContactsInfoList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(userCompaniesId)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersCompaniesContactsInfoListKey(userCompaniesId,params) : null);
  const swrFn = () => usersCompaniesContactsInfoList(userCompaniesId,params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersForwarderInvitationsList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ForwarderInvitation[]>(
      {url: `/api/v1/users/forwarder-invitations/`, method: 'get'
    },
      options);
    }
  

export const getUsersForwarderInvitationsListKey = () => [`/api/v1/users/forwarder-invitations/`] as const;

    
export type UsersForwarderInvitationsListQueryResult = NonNullable<Awaited<ReturnType<typeof usersForwarderInvitationsList>>>
export type UsersForwarderInvitationsListQueryError = unknown

export const useUsersForwarderInvitationsList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersForwarderInvitationsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersForwarderInvitationsListKey() : null);
  const swrFn = () => usersForwarderInvitationsList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * POSTだが冪等になっており、同じFreightForwardersとemailに対しForwarderInvitationは1つしか作らない。招待メールを再送する場合も使用できる。
 */
export const usersForwarderInvitationsCreate = (
    forwarderInvitationRequest: ForwarderInvitationRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ForwarderInvitation>(
      {url: `/api/v1/users/forwarder-invitations/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: forwarderInvitationRequest
    },
      options);
    }
  


export const usersForwarderInvitationsRetrieve = (
    token: string,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<ForwarderInvitation>(
      {url: `/api/v1/users/forwarder-invitations/${token}/`, method: 'get'
    },
      options);
    }
  

export const getUsersForwarderInvitationsRetrieveKey = (token: string,) => [`/api/v1/users/forwarder-invitations/${token}/`] as const;

    
export type UsersForwarderInvitationsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersForwarderInvitationsRetrieve>>>
export type UsersForwarderInvitationsRetrieveQueryError = unknown

export const useUsersForwarderInvitationsRetrieve = <TError = unknown>(
 token: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersForwarderInvitationsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(token)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersForwarderInvitationsRetrieveKey(token) : null);
  const swrFn = () => usersForwarderInvitationsRetrieve(token, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersForwarderInvitationsRegisterCreate = (
    token: string,
    userRegistrationRequest: UserRegistrationRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserRegistration>(
      {url: `/api/v1/users/forwarder-invitations/${token}/register/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: userRegistrationRequest
    },
      options);
    }
  


export const usersGuestGroupsList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<GuestGroup[]>(
      {url: `/api/v1/users/guest-groups/`, method: 'get'
    },
      options);
    }
  

export const getUsersGuestGroupsListKey = () => [`/api/v1/users/guest-groups/`] as const;

    
export type UsersGuestGroupsListQueryResult = NonNullable<Awaited<ReturnType<typeof usersGuestGroupsList>>>
export type UsersGuestGroupsListQueryError = unknown

export const useUsersGuestGroupsList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersGuestGroupsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersGuestGroupsListKey() : null);
  const swrFn = () => usersGuestGroupsList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersGuestGroupsCreate = (
    guestGroupRequest: GuestGroupRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<GuestGroup>(
      {url: `/api/v1/users/guest-groups/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: guestGroupRequest
    },
      options);
    }
  


export const usersGuestGroupsRetrieve = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<GuestGroup>(
      {url: `/api/v1/users/guest-groups/${id}/`, method: 'get'
    },
      options);
    }
  

export const getUsersGuestGroupsRetrieveKey = (id: number,) => [`/api/v1/users/guest-groups/${id}/`] as const;

    
export type UsersGuestGroupsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersGuestGroupsRetrieve>>>
export type UsersGuestGroupsRetrieveQueryError = unknown

export const useUsersGuestGroupsRetrieve = <TError = unknown>(
 id: number, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersGuestGroupsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersGuestGroupsRetrieveKey(id) : null);
  const swrFn = () => usersGuestGroupsRetrieve(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersGuestGroupsUpdate = (
    id: number,
    guestGroupRequest: GuestGroupRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<GuestGroup>(
      {url: `/api/v1/users/guest-groups/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: guestGroupRequest
    },
      options);
    }
  


export const usersGuestGroupsPartialUpdate = (
    id: number,
    patchedGuestGroupRequest: PatchedGuestGroupRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<GuestGroup>(
      {url: `/api/v1/users/guest-groups/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedGuestGroupRequest
    },
      options);
    }
  


export const usersGuestGroupsDestroy = (
    id: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/users/guest-groups/${id}/`, method: 'delete'
    },
      options);
    }
  


export const usersGuestInvitationsList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<GuestInvitation[]>(
      {url: `/api/v1/users/guest-invitations/`, method: 'get'
    },
      options);
    }
  

export const getUsersGuestInvitationsListKey = () => [`/api/v1/users/guest-invitations/`] as const;

    
export type UsersGuestInvitationsListQueryResult = NonNullable<Awaited<ReturnType<typeof usersGuestInvitationsList>>>
export type UsersGuestInvitationsListQueryError = unknown

export const useUsersGuestInvitationsList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersGuestInvitationsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersGuestInvitationsListKey() : null);
  const swrFn = () => usersGuestInvitationsList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * POSTだが冪等になっており、同じGuestGroupとemailに対しGuestInvitationは1つしか作らない。招待メールを再送する場合も使用できる。
 */
export const usersGuestInvitationsCreate = (
    guestInvitationRequest: GuestInvitationRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<GuestInvitation>(
      {url: `/api/v1/users/guest-invitations/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: guestInvitationRequest
    },
      options);
    }
  


export const usersGuestInvitationsRetrieve = (
    token: string,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<GuestInvitation>(
      {url: `/api/v1/users/guest-invitations/${token}/`, method: 'get'
    },
      options);
    }
  

export const getUsersGuestInvitationsRetrieveKey = (token: string,) => [`/api/v1/users/guest-invitations/${token}/`] as const;

    
export type UsersGuestInvitationsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersGuestInvitationsRetrieve>>>
export type UsersGuestInvitationsRetrieveQueryError = unknown

export const useUsersGuestInvitationsRetrieve = <TError = unknown>(
 token: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersGuestInvitationsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(token)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersGuestInvitationsRetrieveKey(token) : null);
  const swrFn = () => usersGuestInvitationsRetrieve(token, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersGuestInvitationsRegisterCreate = (
    token: string,
    userRegistrationRequest: UserRegistrationRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserRegistration>(
      {url: `/api/v1/users/guest-invitations/${token}/register/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: userRegistrationRequest
    },
      options);
    }
  


export const usersInvitationsList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserInvitation[]>(
      {url: `/api/v1/users/invitations/`, method: 'get'
    },
      options);
    }
  

export const getUsersInvitationsListKey = () => [`/api/v1/users/invitations/`] as const;

    
export type UsersInvitationsListQueryResult = NonNullable<Awaited<ReturnType<typeof usersInvitationsList>>>
export type UsersInvitationsListQueryError = unknown

export const useUsersInvitationsList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersInvitationsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersInvitationsListKey() : null);
  const swrFn = () => usersInvitationsList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * POSTだが冪等になっており、同じUserCompaniesとemailに対しUserInvitationは1つしか作らない。招待メールを再送する場合も使用できる。
 */
export const usersInvitationsCreate = (
    userInvitationRequest: UserInvitationRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserInvitation>(
      {url: `/api/v1/users/invitations/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: userInvitationRequest
    },
      options);
    }
  


export const usersInvitationsRetrieve = (
    token: string,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserInvitation>(
      {url: `/api/v1/users/invitations/${token}/`, method: 'get'
    },
      options);
    }
  

export const getUsersInvitationsRetrieveKey = (token: string,) => [`/api/v1/users/invitations/${token}/`] as const;

    
export type UsersInvitationsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersInvitationsRetrieve>>>
export type UsersInvitationsRetrieveQueryError = unknown

export const useUsersInvitationsRetrieve = <TError = unknown>(
 token: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersInvitationsRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(token)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersInvitationsRetrieveKey(token) : null);
  const swrFn = () => usersInvitationsRetrieve(token, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersInvitationsRegisterCreate = (
    token: string,
    userRegistrationRequest: UserRegistrationRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<UserRegistration>(
      {url: `/api/v1/users/invitations/${token}/register/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: userRegistrationRequest
    },
      options);
    }
  


export const usersMeRetrieve = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Users>(
      {url: `/api/v1/users/me/`, method: 'get'
    },
      options);
    }
  

export const getUsersMeRetrieveKey = () => [`/api/v1/users/me/`] as const;

    
export type UsersMeRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersMeRetrieve>>>
export type UsersMeRetrieveQueryError = unknown

export const useUsersMeRetrieve = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersMeRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersMeRetrieveKey() : null);
  const swrFn = () => usersMeRetrieve(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersMeUpdate = (
    usersRequest: UsersRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Users>(
      {url: `/api/v1/users/me/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: usersRequest
    },
      options);
    }
  


export const usersMePartialUpdate = (
    patchedUsersRequest: PatchedUsersRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Users>(
      {url: `/api/v1/users/me/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedUsersRequest
    },
      options);
    }
  


export const usersPartnerCompaniesList = (
    params?: UsersPartnerCompaniesListParams,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PartnerCompany[]>(
      {url: `/api/v1/users/partner-companies/`, method: 'get',
        params
    },
      options);
    }
  

export const getUsersPartnerCompaniesListKey = (params?: UsersPartnerCompaniesListParams,) => [`/api/v1/users/partner-companies/`, ...(params ? [params]: [])] as const;

    
export type UsersPartnerCompaniesListQueryResult = NonNullable<Awaited<ReturnType<typeof usersPartnerCompaniesList>>>
export type UsersPartnerCompaniesListQueryError = unknown

export const useUsersPartnerCompaniesList = <TError = unknown>(
 params?: UsersPartnerCompaniesListParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersPartnerCompaniesList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersPartnerCompaniesListKey(params) : null);
  const swrFn = () => usersPartnerCompaniesList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const usersPartnerCompaniesCreate = (
    partnerCompanyRequest: PartnerCompanyRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PartnerCompany>(
      {url: `/api/v1/users/partner-companies/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: partnerCompanyRequest
    },
      options);
    }
  


export const usersPartnerCompaniesUpdate = (
    userCompaniesId: number,
    partnerCompanyRequest: PartnerCompanyRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PartnerCompany>(
      {url: `/api/v1/users/partner-companies/${userCompaniesId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: partnerCompanyRequest
    },
      options);
    }
  


export const usersPartnerCompaniesPartialUpdate = (
    userCompaniesId: number,
    patchedPartnerCompanyRequest: PatchedPartnerCompanyRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<PartnerCompany>(
      {url: `/api/v1/users/partner-companies/${userCompaniesId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedPartnerCompanyRequest
    },
      options);
    }
  


export const usersPartnerCompaniesDestroy = (
    userCompaniesId: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/users/partner-companies/${userCompaniesId}/`, method: 'delete'
    },
      options);
    }
  


/**
 * 既存のUserCompanies同士を取引先として紐付ける
 */
export const usersPartnershipCreate = (
    userCompanyId1: number,
    userCompanyId2: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/users/partnership/${userCompanyId1}/${userCompanyId2}/`, method: 'post'
    },
      options);
    }
  


/**
 * 既存のUserCompanies同士を取引先として紐付ける
 */
export const usersPartnershipDestroy = (
    userCompanyId1: number,
    userCompanyId2: number,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/users/partnership/${userCompanyId1}/${userCompanyId2}/`, method: 'delete'
    },
      options);
    }
  


export const usersPasswordCreate = (
    passwordResetRequest: PasswordResetRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/users/password/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: passwordResetRequest
    },
      options);
    }
  


export const usersPasswordUpdate = (
    token: string,
    passwordUpdateRequest: PasswordUpdateRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/users/password/${token}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: passwordUpdateRequest
    },
      options);
    }
  


export const usersPasswordPartialUpdate = (
    token: string,
    patchedPasswordUpdateRequest: PatchedPasswordUpdateRequest,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/users/password/${token}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: patchedPasswordUpdateRequest
    },
      options);
    }
  


export const usersTeamsList = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Team[]>(
      {url: `/api/v1/users/teams/`, method: 'get'
    },
      options);
    }
  

export const getUsersTeamsListKey = () => [`/api/v1/users/teams/`] as const;

    
export type UsersTeamsListQueryResult = NonNullable<Awaited<ReturnType<typeof usersTeamsList>>>
export type UsersTeamsListQueryError = unknown

export const useUsersTeamsList = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersTeamsList>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersTeamsListKey() : null);
  const swrFn = () => usersTeamsList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


/**
 * @deprecated
 */
export const usersWhoamiRetrieve = (
    
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<Users>(
      {url: `/api/v1/users/whoami/`, method: 'get'
    },
      options);
    }
  

export const getUsersWhoamiRetrieveKey = () => [`/api/v1/users/whoami/`] as const;

    
export type UsersWhoamiRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof usersWhoamiRetrieve>>>
export type UsersWhoamiRetrieveQueryError = unknown

/**
 * @deprecated
 */
export const useUsersWhoamiRetrieve = <TError = unknown>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof usersWhoamiRetrieve>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof swrFetcher> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getUsersWhoamiRetrieveKey() : null);
  const swrFn = () => usersWhoamiRetrieve(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}


export const vizionReferencesUpdate = (
    id: string,
 options?: SecondParameter<typeof swrFetcher>) => {
      return swrFetcher<void>(
      {url: `/api/v1/vizion/references/${id}/`, method: 'put'
    },
      options);
    }
  


