import { createTheme } from '@mui/material'
import { lightBlue } from '@mui/material/colors'

declare module '@mui/material/styles' {
  interface Palette {
    border: Palette['primary']
    softOrange: Palette['primary']
    lightGrayishBlue: Palette['primary']
    btnPrimary: Palette['primary']
    bgPrimary: Palette['primary']
    labelPrimary?: PaletteOptions['primary']
    borderPrimary?: PaletteOptions['primary']
  }
  interface PaletteOptions {
    border?: PaletteOptions['primary']
    softOrange?: PaletteOptions['primary']
    lightGrayishBlue?: PaletteOptions['primary']
    btnPrimary?: PaletteOptions['primary']
    bgPrimary?: PaletteOptions['primary']
    labelPrimary?: PaletteOptions['primary']
    borderPrimary?: PaletteOptions['primary']
  }
}

export default createTheme({
  palette: {
    // NOTE: 既存 (デザイン刷新前のカラーなので今後使わなくなります)
    primary: { main: '#42526e' },
    secondary: { main: '#2E3E52', light: '#485E7A' },
    border: { main: '#D6D3D0' },
    alert: { main: '#E06242', light: '#fdeded' },
    softOrange: { main: '#E6BF64' },
    lightGrayishBlue: { main: '#E9F4FD', light: '#F2F1F0' },
    // 新デザインカラー (新デザインで使うカラーはここから)
    error: { main: '#FF8080' },
    base: { main: '#F6F7FC', dark: '#F2F3F5', light: '#F2F2F7' },
    text: {
      primary: '#384143',
      secondary: '#706D65',
      disabled: '#C1BDB7',
    },
    btnPrimary: { main: lightBlue[500] }, // TODO:移行してから削除する
    bgPrimary: { main: '#F9F9FB' },
    labelPrimary: { main: '#71718E' },
    borderPrimary: { main: '#D6D3D0' },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        color: 'text.primary',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          ':hover': {
            boxShadow: 'none',
          },
        },
      },
    },
  },
})
